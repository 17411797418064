import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Button } from 'components';
import { ResetPasswordForm } from '../../forms';
import css from './resetpassword.module.scss';

interface Props {
  handleResetPassword: any
  resetPassword: any;
}

const ResetPassword: React.FC<Props> = (props: Props) => {
  const { handleResetPassword, resetPassword } = props;
  const { search } = useLocation();
  const resetToken = queryString.parse(search);

  const handleFormSubmit = (data: any) => {
    handleResetPassword(data, resetToken);
  };

  const history = useHistory();

  return (
    <section>
      <div className={css.column}>
        <div className={css.container}>
          {resetPassword ? (
            <>
              <h2 className={css.sign_text}>Password Reset</h2>
              <p className={css.details_textSuccess}>
                <span>
                  Your Password has been set successfully!
                </span>
              </p>
              <Button onClick={() => history.push('/')}>
                Back to Login
              </Button>
            </>
          ) : (
            <ResetPasswordForm handleFormSubmit={handleFormSubmit} />
          )}
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
