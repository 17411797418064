import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ChannelReportDetails from 'components/ChannelReportDetails';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import {
  Button,
  CircularProgressLoader,
  Modal,
  PostCreated,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ChannelManagementBlocked,
  ChannelManagementDelete,
  Overlay,
  Accordion,
  ChannelManagementSendNotification,
  ChannelNotification,
} from '..';
import { getChannelInfo } from '../../actions';
import { ChannelInfoService } from '../../services';
import profileImg from '../../assets/svg/profilepic.svg';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import exportImg from '../../assets/svg/export.svg';
import { dateFormatting } from '../../utils/dateMethods';
import unblockImg from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  channelInfo: any;
  id: any;
  fetchChannelInfo: any;
  intl: any;
}

const ChannelInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    channelInfo,
    id,
    fetchChannelInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchChannelInfo(id);
  }, [fetchChannelInfo, id]);
  const history = useHistory();

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);

  const handleModalClose = () => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
    setSendNotification(false);
  };

  const deleteChannelService = async () => {
    setShowOverlay(true);
    const data = await ChannelInfoService.deleteChannelApi(id);
    setShowOverlay(false);
    if (data.data.statusCode === 202) {
      history.push('/channelmanagement');
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className={commonStyles.modal_container}>
          {
            showBlock && (
              <ChannelManagementBlocked
                handleModalClose={handleModalClose}
                fetchChannelList={() => { fetchChannelInfo(id); }}
                id={id}
                status={channelInfo.data.status === 'active' ? 'blocked' : 'unblocked'}
                setOverlay={setShowOverlay}
              />
            )
          }
          {showDelete && (
            <ChannelManagementDelete
              handleModalClose={handleModalClose}
              deleteChannelService={deleteChannelService}
            />
          )}
        </div>
        {
          sendNotification
          && (
            <ChannelManagementSendNotification
              handleModalClose={handleModalClose}
              id={id}
              channelInfo={channelInfo}
              setShowOverlay={setShowOverlay}
            />
          )
        }
      </Modal>
      {
        channelInfo.data === null || channelInfo.loadingChannelInfoProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.headerActions}>
                  <div>
                    <Button
                      className={commonStyles.button}
                      onClick={() => {
                        setShowModal(true);
                        setSendNotification(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'ChannelInfo.label.SendNotification' })}
                    </Button>
                  </div>
                  <div className={commonStyles.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          src={deleteImg}
                          alt="deleteImg"
                          role="presentation"
                          title="Delete"
                          onClick={() => {
                            setShowModal(true);
                            setShowDelete(true);
                          }}
                        />
                      </span>
                    </AccessControl>

                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      {channelInfo.data.status === 'active' ? (
                        <span className={commonStyles.iconWrapper}>
                          <img
                            src={blockImg}
                            alt="blockImg"
                            title="Block"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      ) : (
                        <span className={commonStyles.iconWrapper}>
                          <img
                            src={unblockImg}
                            alt="unblockImg"
                            title="unblock"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      )}
                    </AccessControl>
                  </div>
                </div>
                <div className={commonStyles.userInformatinc}>
                  <div className={commonStyles.profileImgWrapper}>
                    {channelInfo.data.channelIconImage !== ''
                      && channelInfo.data.channelIconImage !== undefined
                      ? (
                        <img
                          className={commonStyles.profileImg}
                          src={channelInfo.data.channelIconImage}
                          alt="profileImg"
                        />
                      )
                      : <img src={profileImg} alt="profile" />}
                  </div>
                  <div className={commonStyles.userInfoinner}>
                    <div>
                      <h2>{channelInfo.data.channelName}</h2>
                      <h3>{intl.formatMessage({ id: 'ChannelInfo.label.description' })}</h3>
                      <p>
                        {channelInfo.data.description}
                      </p>
                    </div>
                    <div className={commonStyles.right_container}>
                      <h3>
                        {intl.formatMessage({ id: 'ChannelInfo.label.createdOn' })}
                        :
                        <span>
                          {dateFormatting(channelInfo.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className={commonStyles.postDetails}>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.user' })}
                      </p>
                      <p className={commonStyles.count_backround}>
                        {channelInfo.data.userId?.username}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.link' })}
                      </p>
                      <p className={commonStyles.count_backround}>
                        {channelInfo.data.link}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.dome' })}
                      </p>
                      <p className={commonStyles.count_backround}>
                        {channelInfo.data.categoryId?.name}
                      </p>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.pricingplan' })}
                      </p>
                      <p className={commonStyles.count_backround}>
                        {channelInfo.data.subscriptionType}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.subscriptionAmount' })}
                      </p>
                      <p className={commonStyles.count_backround}>
                        0
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.noofsubscribers' })}
                      </p>
                      <p className={commonStyles.count_backround}>
                        {channelInfo.data.subscriberCount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Notification Detail">
                <ChannelNotification id={id} />
              </Accordion>
              <Accordion heading="Posts Created">
                <PostCreated id={id} />
              </Accordion>
              <Accordion heading={intl.formatMessage({ id: 'ChannelReport.label.title' })}>
                <ChannelReportDetails id={id} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { channelinfo } = state;
  return {
    channelInfo: channelinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchChannelInfo: (payload: any) => dispatch(getChannelInfo(payload)),
});

const ChannelInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ChannelInfoComponent);

export default ChannelInfo;
