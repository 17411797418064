import React from 'react';
import classNames from 'classnames';
import {
  FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import css from './FieldRadioButton.module.scss';

interface Props {
  rootClassName?: string,
  className?: string,
  children?: any,
  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  name: string,
  formProps: any,
  options?: any,
  defaultValue?: boolean,
}

const FieldRadioButton = (props: Props) => {
  const {
    rootClassName,
    className,
    children,
    name,
    formProps: { control },
    options,
    defaultValue,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup>
            <div className={css.flex}>
              {options.map((ele: any) => (
                <FormControlLabel
                  {...field}
                  key={ele.index}
                  value={ele.key}
                  control={<Radio />}
                  label={ele.value}
                />
              ))}
            </div>
          </RadioGroup>
        )}
      />
      {children}
    </div>
  );
};

FieldRadioButton.defaultProps = {
  rootClassName: null,
  className: null,
  children: null,
  options: [],
  defaultValue: false,
};

export default FieldRadioButton;
