import React from 'react';
import {
  EditSubAdmin,
} from 'forms';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { AddNotification } from 'components';

interface ManageSubDomesInterface {
  id: string;
}

const ManageNotification: React.FC = () => {
  // const [loader, setLoader] = React.useState(false);
  const { id } = useParams<ManageSubDomesInterface>();
  const history = useHistory();
  const editDome: boolean = history.location.pathname.includes('editSubAdmin');
  // const [error, setError] = useState('');

  // const handleSubmitForm = async (data: any) => {
  //   if (data.android === false && data.web === false && data.ios === false) {
  //     setError('Please select at least one device');
  //   } else {
  //     setLoader(true);
  //     setError('');
  //     let platform: any = [];
  //     if (data.android) {
  //       platform = [...platform, '1'];
  //     }
  //     if (data.ios) {
  //       platform = [...platform, '2'];
  //     }
  //     if (data.web) {
  //       platform = [...platform, '3'];
  //     }
  //     const form = new FormData();
  //     form.append('file', data.selectedIcon[0]);
  //     Notification.uploadpicture(form).then((res: any) => {
  //       Notification.addNotification({
  //         title: data.title,
  //         message: data.description,
  //         status: 'sent',
  //         image: res.data.data.url,
  //         platforms: platform,
  //       }).then(() => {
  //         setLoader(false);
  //         history.push('/notification');
  //       }).catch((err) => {
  //         setLoader(false);
  //         setError(err.response.data.message);
  //       });
  //     }).catch((err) => {
  //       setLoader(false);
  //       setError(err.response.data.message);
  //     });
  //   }
  // };

  // const handleSaveAsDraft = async (e: any, data: any) => {
  //   e.preventDefault();
  //   if (data.android === false && data.web === false && data.ios === false) {
  //     setError('Please select at least one device');
  //   } else {
  //     setLoader(true);
  //     setError('');
  //     let platform: any = [];
  //     if (data.android) {
  //       platform = [...platform, '1'];
  //     }
  //     if (data.ios) {
  //       platform = [...platform, '2'];
  //     }
  //     if (data.web) {
  //       platform = [...platform, '3'];
  //     }
  //     const form = new FormData();
  //     form.append('file', data.selectedIcon[0]);
  //     Notification.uploadpicture(form).then((res: any) => {
  //       Notification.addNotification({
  //         title: data.title,
  //         message: data.description,
  //         status: 'draft',
  //         image: res.data.data.url,
  //         platforms: platform,
  //       }).then(() => {
  //         setLoader(false);
  //         history.push('/notification');
  //       }).catch((err) => {
  //         setLoader(false);
  //         setError(err.response.data.message);
  //       });
  //     }).catch((err) => {
  //       setLoader(false);
  //       setError(err.response.data.message);
  //     });
  //   }
  // };

  return (
    <>
      {editDome ? (
        <EditSubAdmin
          handleSubmit={() => null}
          id={id}
          error={null}
        />
      )
        : (
          <AddNotification />
        )}
    </>
  );
};

export default ManageNotification;
