import api from 'api';

export class LiveStreamingManagementInfo {
  static async getLiveStreamIfo(payload: any) {
    return api.getApiCall(`/api/v1/admins/events/${payload}`);
  }

  static async getDomes() {
    return api.getApiCall('/api/v1/admins/categories/names');
  }
}
