import React from 'react';
import { Helmet } from 'react-helmet-async';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl } from '../../utils/reactIntl';
import routeConfiguration from '../../routes/routeConfiguration.js';
import config from '../../config.js';
import { metaTagProps } from '../../utils/seo';
import { canonicalRoutePath } from '../../utils/routes';

import scss from './Page.module.scss';

const preventDefault = (e: any) => {
  e.preventDefault();
};

interface Props {
    className?: string,
    rootClassName?: string,
    children: React.ReactNode,
    location: any,
    intl: any,
    referrer?: any,
    schema?: any,
    title: string,
    scrollingDisabled?: boolean,
    description?: string,
    author?: string,
    contentType?: string,
    published?: string,
    tags?: string,
    updated?: string,
    history: any,
    match: any
}

class PageComponent extends React.PureComponent<Props & RouteComponentProps, unknown> {
    private contentDiv: HTMLDivElement | null;

    private scrollPosition;

    private scrollingDisabled: any;

    constructor(props: Props) {
      super(props);
      // Keeping scrollPosition out of state reduces rendering cycles (and no bad states rendered)
      this.scrollPosition = 0;
      this.contentDiv = null;
      this.scrollingDisabledChanged = this.scrollingDisabledChanged.bind(this);
    }

    componentDidMount() {
    // By default a dropped file is loaded in the browser window as a
    // file URL. We want to prevent this since it might loose a lot of
    // data the user has typed but not yet saved. Preventing requires
    // handling both dragover and drop events.
      document.addEventListener('dragover', preventDefault);
      document.addEventListener('drop', preventDefault);

      // Remove duplicated server-side rendered page schema.
      // It's in <body> to improve initial rendering performance,
      // but after web app is initialized, react-helmet-async operates with <head>
      const pageSchema = document.getElementById('page-schema');
      if (pageSchema) {
        pageSchema.remove();
      }
    }

    componentWillUnmount() {
      document.removeEventListener('dragover', preventDefault);
      document.removeEventListener('drop', preventDefault);
    }

    scrollingDisabledChanged(currentScrollingDisabled: any) {
      if (currentScrollingDisabled && currentScrollingDisabled !== this.scrollingDisabled) {
      // Update current scroll position, if scrolling is disabled (e.g. modal is open)
        this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        this.scrollingDisabled = currentScrollingDisabled;
      } else if (currentScrollingDisabled !== this.scrollingDisabled) {
        this.scrollingDisabled = currentScrollingDisabled;
      }
    }

    render() {
      const {
        className,
        rootClassName,
        children,
        location,
        intl,
        referrer,
        author,
        contentType,
        schema,
        title,
        scrollingDisabled,
        description,
        published,
        tags,
        updated,
      } = this.props;

      const classes = classNames(rootClassName || scss.root, className);
      this.scrollingDisabledChanged(scrollingDisabled);
      const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

      const { canonicalRootURL } = config;
      const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
      const canonicalPath = canonicalRoutePath(routeConfiguration(), location, shouldReturnPathOnly);
      const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

      const { siteTitle } = config;
      const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
      const schemaDescription = intl.formatMessage({ id: 'Page.schemaDescription' });
      const metaTitle = title || schemaTitle;
      const metaDescription = description || schemaDescription;

      const metaToHead = metaTagProps({
        author,
        contentType,
        description: metaDescription,
        published,
        tags,
        title: metaTitle,
        updated,
        url: canonicalUrl,
        locale: intl.locale,
      });

      // eslint-disable-next-line react/no-array-index-key
      const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

      const schemaFromProps = Array.isArray(schema) ? schema : [schema];
      const schemaArrayJSONString = JSON.stringify([
        ...schemaFromProps,
        {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          '@id': `${canonicalRootURL}#organization`,
          url: canonicalRootURL,
          name: siteTitle,
          logo: `${canonicalRootURL}/static/webapp-icon-192x192.png`,
        },
        {
          '@context': 'http://schema.org',
          '@type': 'WebSite',
          url: canonicalRootURL,
          description: schemaDescription,
          name: schemaTitle,
          publisher: {
            '@id': `${canonicalRootURL}#organization`,
          },
        },
      ]);

      const scrollPositionStyles = {};

      return (
        <div className={classes}>
          <Helmet
            htmlAttributes={{
              lang: intl.locale,
            }}
          >
            <title>{title}</title>
            {referrerMeta}
            <link rel="canonical" href={canonicalUrl} />
            <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta httpEquiv="Content-Language" content={intl.locale} />
            {metaTags}
            <script id="page-schema" type="application/ld+json">
              {schemaArrayJSONString.replace(/</g, '\\u003c')}
            </script>
          </Helmet>
          {/* to add Cookie Consent Component */}
          <div
            className={scss.content}
            style={scrollPositionStyles}
            ref={(c) => {
              this.contentDiv = c;
            }}
          >
            {children}
          </div>
        </div>
      );
    }
}

const Page = injectIntl(withRouter(PageComponent));
Page.displayName = 'Page';

export default Page;
