import React from 'react';
import { useHistory } from 'react-router-dom';
import { dateFormatting } from 'utils/dateMethods';
import {
  DataTable,
  Modal,
  ToastBar,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  hasAccess,
  DeleteUserInfo,
  BlockUserInfo,
  Overlay,
} from 'components';
import { ReportedUserManagementService } from 'services';
import { ReportedUserManagementFilter } from 'forms';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import { connect } from 'react-redux';
import blockIcon from 'assets/svg/block.svg';
import unblock from 'assets/svg/unblock.svg';
import deleteIcon from 'assets/svg/delete.svg';
import { fetchReportedUserList } from 'actions';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  userList: any;
  fetchReportedUsers: any;
}

const ReportedUserManagement: React.FC<Props> = (props: Props) => {
  const {
    userList,
    fetchReportedUsers,
  } = props;
  const history = useHistory();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    startDate: '',
    endDate: '',
    search: '',
    isSortByUsername: '',
    isSortByCreated: '',
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  const hideActions = () => hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.DELETE);

  React.useEffect(() => {
    fetchReportedUsers(filter);
  }, [fetchReportedUsers, filter]);
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case 'edit':
        history.push(`/rolemanagement/editRoles/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const actionButtons = (action: any, idS: any) => (
    <div className={commonStyles.actionBtnsWrapper}>
      <AccessControl
        permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          <img
            src={deleteIcon}
            role="presentation"
            alt="delete"
            width="35"
            title="Delete"
            height="35"
            onClick={() => handleBlockUnblock('delete', idS)}
          />
        </div>
      </AccessControl>
      <AccessControl
        permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          {action === 'unblocked' ? (
            <img
              src={blockIcon}
              role="presentation"
              alt="block"
              width="35"
              title="Block"
              height="35"
              onClick={() => handleBlockUnblock('blocked', idS)}
            />
          ) : (
            <img
              src={unblock}
              role="presentation"
              alt="unblock"
              width="35"
              title="Unblock"
              height="35"
              onClick={() => handleBlockUnblock('unblocked', idS)}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = userList.data ? userList.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitRoleManagement = (data: any) => {
    if (data.endDate !== null
      || data.search !== ''
      || data.startDate !== null
    ) {
      setFilter({
        ...filter,
        startDate: data.startDate ? data.startDate.toISOString() : '',
        endDate: data.endDate ? data.endDate.toISOString() : '',
        search: data.search.label,
        page: 0,
      });
    }
    setError(() => null);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      username: data.username,
      reportcountuser: data.userReporteCount,
      reportcountpost: data.postReportCount,
      reportcountchannel: data.channelReportCount,
      reportcountcampaign: data.campaignReportCount,
      reportcountevent: data.eventReportCount,
      lastreportdate: dateFormatting(data.lastReportedDate),
      ...(hideActions() && {
        action: actionButtons(data.status, idS),
      }),
    };
  }

  const rows: any = [];
  if (userList.data !== null && userList.data) {
    userList.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = hideActions() ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'reportcountuser', label: 'Reports count for User', sort: false,
    },
    {
      id: 'reportcountpost', label: 'Reports count for Posts', sort: false,
    },
    {
      id: 'reportcountchannel', label: 'Report count for Channel', sort: false,
    },
    {
      id: 'reportcountcampaign', label: 'Reports count for Campaigns', sort: false,
    },
    {
      id: 'reportcountevent', label: 'Reports count for Events', sort: false,
    },
    {
      id: 'lastreportdate', label: 'Last Reported date', sort: true,
    },
    {
      id: 'action', label: 'Action',
    },
  ] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: true,
    },
    {
      id: 'reportcountuser', label: 'Reports count for User', sort: false,
    },
    {
      id: 'reportcountpost', label: 'Reports count for Posts', sort: false,
    },
    {
      id: 'reportcountchannel', label: 'Report count for Channel', sort: false,
    },
    {
      id: 'reportcountcampaign', label: 'Reports count for Campaigns', sort: false,
    },
    {
      id: 'reportcountevent', label: 'Reports count for Events', sort: false,
    },
    {
      id: 'lastreportdate', label: 'Last Reported date', sort: true,
    },
  ];
  const handleClick = (event: React.MouseEvent<unknown>, name: any) => {
    // eslint-disable-next-line dot-notation
    if (event.target['localName'] === 'input') {
      handleSelectedClick(event, name);
      // eslint-disable-next-line dot-notation
    } else if (event.target['localName'] !== 'img') {
      history.push(`/reportusermanagement/reportuserinfo/${name.id}`);
    }
  };
  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'username') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUsername: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'lastreportdate') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleResetSorts = () => {
    setFilter({
      ...filter,
      startDate: '',
      endDate: '',
      search: '',
      isSortByUsername: '',
      isSortByCreated: '',
    });
  };
  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Username: ele.username,
            Reportcountuser: ele.userReporteCount,
            Reportcountpost: ele.postReportCount,
            Reportcountchannel: ele.channelReportCount,
            Reportcountcampaign: ele.campaignReportCount,
            Reportcountevent: ele.eventReportCount,
            Lastreportdate: dateFormatting(ele.lastReportedDate),
          });
        });
        exportToExcel(selectedRowDataModified, 'ReportedUserManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        ReportedUserManagementService.exportListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Username: ele.username,
            Reportcountuser: ele.userReporteCount,
            Reportcountpost: ele.postReportCount,
            Reportcountchannel: ele.channelReportCount,
            Reportcountcampaign: ele.campaignReportCount,
            Reportcountevent: ele.eventReportCount,
            Lastreportdate: dateFormatting(ele.lastReportedDate),
          });
        });
        exportToCSV(selectedRowDataModified, 'ReportedUserManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        ReportedUserManagementService.exportListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <DeleteUserInfo // history.push('/usermanagement');
              setShowOverlay={setShowOverlay}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              id={deleted.id}
            />
          )
        }
        {
          blocked.show && (
            <BlockUserInfo
              id={blocked.id}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              userInfoStatus="unblocked"
              setShowOverlay={setShowOverlay}
            />
          )
        }
        {
          unblocked.show && (
            <BlockUserInfo
              id={unblocked.id}
              fetchUserInfo={() => setFilter({ ...filter, page: 0 })}
              handleModalClose={handleModalClose}
              userInfoStatus="blocked"
              setShowOverlay={setShowOverlay}
            />
          )
        }
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <ReportedUserManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitRoleManagement}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={userList.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { reportedusermanagement } = state;
  return {
    userList: reportedusermanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchReportedUsers: (payload: any) => dispatch(
    fetchReportedUserList(payload),
  ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportedUserManagement);
