import api from '../api';

export class PostUserReportService {
  static async getPostUserReportService(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&userId=${id}`;
    return api.getApiCall(`api/v1/admins/reported-users/posts?${apiEndPoint}`);
  }
}
