import React from 'react';
import ReactDOM from 'react-dom';

import { PersistGate } from 'redux-persist/integration/react';
import { authInfo, showAlert } from './actions';

import { store, persistor } from './store/index';
import { AdminApp } from './App';
import reportWebVitals from './reportWebVitals';

import { Reload } from './components';

import { register } from './serviceWorker.js';

import './assets/styles/_avatusDefault.scss';

const render = (appStore: any) => {
  const { authInfoLoaded } = appStore.getState().auth;
  if (!authInfoLoaded) {
    authInfo(appStore.dispatch);
  }
  ReactDOM.hydrate(
    <PersistGate persistor={persistor}>
      <AdminApp appStore={appStore} />
    </PersistGate>, document.getElementById('root'),
  );
};

declare global {
  interface Window {
    __PRELOADED_STATE__: any;
  }
}

if (typeof window !== 'undefined') {
  render(store);
}
reportWebVitals();

register({
  onUpdate: () => {
    store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }));
  },
});
