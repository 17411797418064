import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldTextInput,
  ValidationError,
  FieldRadioButton,
  FieldTimePicker,
  FieldDatePicker,
  FieldSelect,
} from 'components';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import scss from './addnewversion.module.scss';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  error: any;
}

const AddNewVersionForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    error,
    intl,
  } = props;
  const history = useHistory();
  const addSubDomesSchema = yup.object().shape({
    versionNumber: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.versionNumber' })),
    platform: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.platform' })),
    launch: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.launch' })),
    date: yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.date' }))
      .nullable(),
    time: yup.string()
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.time' }))
      .nullable(),
    description: yup.string()
      .max(250, intl.formatMessage({ id: 'AddVersionManagement.error.description.max' }))
      .required(intl.formatMessage({ id: 'AddVersionManagement.error.description.required' })),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addSubDomesSchema),
  });

  return (
    <>
      <div className={classNames(commonStyles.cardBg, commonStyles.addDetailsPage)}>
        <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
          <h2 className={scss.sign_text}>
            {intl.formatMessage({ id: 'AddVersionManagement.label.details' })}
          </h2>
          <div className="flex_row">
            <div className="flex_col_sm_6">
              <FieldTextInput
                id="versionNumber"
                name="versionNumber"
                formName="subAdmin"
                className="textInput"
                type="number"
                formProps={formProps}
                rootClassName={scss.passwordInput}
                label={intl.formatMessage({ id: 'AddVersionManagement.label.versionNumber' })}
              />
            </div>
            <div className="flex_col_sm_6">
              <div className={scss.userType}>
                <FieldSelect
                  id="platform"
                  name="platform"
                  label={intl.formatMessage({ id: 'AddVersionManagement.label.platform' })}
                  variant={false}
                  formProps={formProps}
                  options={[
                    { key: '1', label: 'Android' },
                    { key: '2', label: 'IOS' },
                    { key: '3', label: 'Web' },
                  ]}
                />
              </div>
            </div>
            <div className="flex_col_sm_12">
              <FieldRadioButton
                name="launch"
                formProps={formProps}
                className={scss.radioBtnWrapper}
                options={[
                  { key: 'hard', value: intl.formatMessage({ id: 'AddVersionManagement.label.hardLaunch' }) },
                  { key: 'soft', value: intl.formatMessage({ id: 'AddVersionManagement.label.softLaunch' }) },
                ]}
              />
            </div>
            <div className="flex_col_sm_6">
              <div className={scss.datepickerField}>
                <FieldDatePicker
                  id="date"
                  name="date"
                  className="dateOfBirthInput dateOfBirthInputLg"
                  label={intl.formatMessage({ id: 'AddVersionManagement.label.date' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null
                    ? formProps.watch('endDate')
                    : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
            </div>
            <div className="flex_col_sm_6">
              <div className={scss.datepickerField}>
                <FieldTimePicker
                  id="time"
                  name="time"
                  className="dateOfBirthInput dateOfBirthInputLg"
                  label={intl.formatMessage({ id: 'AddVersionManagement.label.time' })}
                  formProps={formProps}
                />
              </div>
            </div>
            <div className="flex_col_sm_12">
              <FieldTextInput
                id="description"
                name="description"
                formName="addDomes"
                className="textInput"
                type="textarea"
                formProps={formProps}
                rootClassName={commonStyles.passwordInput}
                label={intl.formatMessage({ id: 'AddVersionManagement.label.Description' })}
              />
            </div>
          </div>
          {Object.keys(formProps.formState.errors).length > 0
            && (
              <ValidationError
                className={scss.validation_error}
                error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
              />
            )}
          {Object.keys(formProps.formState.errors).length === 0
            && <ValidationError error={error} />}
          <div className={commonStyles.buttonWrapper}>
            <Button onClick={() => history.push('/versionmanagement')}>
              {intl.formatMessage({ id: 'AddVersionManagement.label.cancel' })}
            </Button>
            <Button buttonType={ButtonTypes.submit}>
              {intl.formatMessage({ id: 'AddVersionManagement.label.publish' })}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default injectIntl(AddNewVersionForm);
