import React from 'react';
import { useForm } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import {
  Button,
  FieldSelect,
  ValidationError,
  FieldTextInput,
  CircularProgressLoader,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  FieldDatePicker,
} from '../../components';
import { FormattedMessage, injectIntl } from '../../utils/reactIntl';
import carretDown from '../../assets/svg/caretdown.svg';
import status from './status.json';
import exp from '../../assets/svg/export.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleResetSorts: any;
  intl: any,
  error: any,
  handleExport: any,
  loaderExport: boolean,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const SubDomesManagementFilterForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
    handleResetSorts,
    handleExport,
    loaderExport,
  } = props;
  const [showFilter, setShowFilter] = React.useState(true);
  const [toggle, setToggle] = React.useState(false);
  const formProps = useForm();
  const handleReset = () => {
    formProps.reset({
      search: '',
      userType: '',
      startDate: null,
      endDate: null,
    });
    handleResetSorts();
  };
  return (
    <form className={commonStyles.filterForm} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div>
            <div className={commonStyles.filter}>
              {intl.formatMessage({ id: 'LiveStreaming.label.filter' })}
              <span
                className={commonStyles.closeBtn}
                role="presentation"
                onClick={() => setTimeout(() => {
                  setShowFilter((prevState) => !prevState);
                }, 200)}
              >
                {showFilter
                  ? (
                    <ClearIcon />
                  ) : <img src={carretDown} alt="down" />}
              </span>
            </div>
            <div className={commonStyles.fieldSet}>
              <FieldTextInput
                id="search"
                type="text"
                name="search"
                formProps={formProps}
                placeholder="Search by keyword, username or ticket ID"
                formName="Version"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className={commonStyles.userRightExport}>
              {!loaderExport ? (
                <>
                  <span>
                    <img
                      src={exp}
                      alt="export"
                      role="presentation"
                      onClick={() => setToggle((prevState: any) => !prevState)}
                    />
                  </span>
                  {toggle && (
                    <ul className={commonStyles.userrigthToggle}>
                      <li
                        role="presentation"
                        onClick={() => handleExport('XLSX')}
                      >
                        {intl.formatMessage({ id: 'SubDomesManagementFilter.export.xlsx' })}
                      </li>
                      <li
                        role="presentation"
                        onClick={() => handleExport('CSV')}
                      >
                        {intl.formatMessage({ id: 'SubDomesManagementFilter.export.csv' })}
                      </li>
                    </ul>
                  )}
                </>
              ) : <div className={commonStyles.exportProgressBar}><CircularProgressLoader /></div>}
            </div>
          </AccessControl>
        </div>
        {showFilter && (
          <div>
            <p>
              Raised on
            </p>
            <div className={commonStyles.filterOptions}>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  className="dateOfBirthInput"
                  label="From Date"
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null
                    ? formProps.watch('endDate')
                    : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  className="dateOfBirthInput"
                  label="To Date"
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null
                    ? formProps.watch('startDate')
                    : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.userType}>
                <FieldSelect
                  id="userType"
                  name="userType"
                  variant={false}
                  label={intl.formatMessage({ id: 'RoleManagement.status.label' })}
                  formProps={formProps}
                  options={status}
                />
              </div>
              <div className={commonStyles.buttonWrapper}>
                <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
                  <FormattedMessage id="RoleManagement.apply.label" />
                </Button>
                <Button onClick={handleReset} className={commonStyles.button}>
                  <FormattedMessage id="RoleManagement.reset.label" />
                </Button>
              </div>
            </div>
            <ValidationError error={error} />
          </div>
        )}
        {error && (
          <ValidationError
            className={commonStyles.validation_error}
            error={error}
          />
        )}
      </div>
    </form>
  );
};

export default injectIntl(SubDomesManagementFilterForm);
