import { Button } from 'components';
import React from 'react';
import { LiveStreamingManagement } from 'services';
import warning from '../../assets/svg/warning.svg';
import modalStyles from '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchLiveStreaming: any;
  status: any;
}
const LiveStreamingManagementBlocked: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchLiveStreaming,
    status,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const blockService = () => {
    LiveStreamingManagement.blockDomes({ eventId: id, status }).then(() => {
      setBlocked(true);
    });
  };

  return (
    <>
      {!blocked ? (
        <>
          <img src={warning} alt="warning" />
          <h2>
            {status === 'blocked' ? 'Block Live Stream' : 'Unblock Live Stream'}
            {' '}
          </h2>
          <p>
            Are you sure you want to
            {' '}
            {status === 'blocked' ? 'block' : 'unblock'}
            {' '}
            this Live stream ?
          </p>
          <div className={modalStyles.modalButtonWrapper}>
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={blockService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            Entry
            {' '}
            {status === 'blocked' ? 'blocked' : 'unblocked'}
            {' '}
            successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchLiveStreaming({
              page: 0,
              rowsPerPage: 10,
            });
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default LiveStreamingManagementBlocked;
