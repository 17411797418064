import {
  AccessControl,
  Button,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ValidationError,
  Modal,
  PriceManagementSave,
  PriceManagementEdit,
  CircularProgressLoader,
  PriceManagementDelete,
} from 'components';
import saveIcon from 'assets/svg/save.svg';
import blockIcon from 'assets/svg/block.svg';
import unblockIcon from 'assets/svg/unblock.svg';
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchPrices } from 'actions';
import PriceManagementBlocked from 'components/PriceManagementBlocked';
import classNames from 'classnames';
import avatcoin from 'assets/svg/avatcoin.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import editIcon from '../../assets/svg/edit.svg';
import scss from './pricemanagement.module.scss';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  pricemanagement: any
  fetchPriceList: any;
  intl: any;
}

const PriceManagement: React.FC<Props> = (props: Props) => {
  const { intl, fetchPriceList, pricemanagement } = props;
  const [rows, setRows] = React.useState<any>([]);
  const [editableRow, setEditableRow] = React.useState<any>();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
  });
  React.useEffect(() => {
    fetchPriceList(filter);
  }, [fetchPriceList, filter]);
  const [validation, setValidation] = React.useState<any>([{}]);
  const [validationexiting, setValidationExisting] = React.useState<any>([{}]);
  const [save, setSave] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edit, setEdit] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deletePrice, setDeletePrice] = React.useState<any>({
    show: false,
    id: null,
  });
  const [block, setBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblock, setUnBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const [showModal, setShowModal] = React.useState(false);

  const handleAddRow = () => {
    const item = {
      valuefrom: 0,
      valueto: 0,
      percent: 0,
    };
    setRows((prevState: any) => [...prevState, item]);
  };

  const handleRemoveSpecificRow = (idx: any) => () => {
    setEdit({
      show: false,
      id: null,
    });
    const row = [...rows];
    row.splice(idx, 1);
    setRows(row);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEdit({ ...edit, show: false, id: null });
    setSave({ ...save, show: false, id: null });
    setBlock({ ...block, show: false, id: null });
    setDeletePrice({ ...deletePrice, show: false, id: null });
    setUnBlock({ ...unblock, show: false, id: null });
  };

  const count = pricemanagement.data === null ? 0 : pricemanagement.data['Pagination-Count'];

  return (
    <>
      <Modal
        id="pricemanagement"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          save.show && (
            <PriceManagementSave
              handleModalClose={handleModalClose}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              volumeFrom={rows[save.id].valuefrom}
              volumeTo={rows[save.id].valueto}
              percentage={rows[save.id].percent}
            />
          )
        }
        {
          edit.show && (
            <PriceManagementEdit
              handleModalClose={handleModalClose}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              id={editableRow.id}
              volumeFrom={editableRow.volumeFrom}
              volumeTo={editableRow.volumeTo}
              percentage={editableRow.percentage}
            />
          )
        }
        {
          deletePrice.show && (
            <PriceManagementDelete
              handleModalClose={handleModalClose}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              id={deletePrice.id}
            />
          )
        }
        {
          block.show && (
            <PriceManagementBlocked
              handleModalClose={handleModalClose}
              id={block.id}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              status="blocked"
            />
          )
        }
        {
          unblock.show && (
            <PriceManagementBlocked
              handleModalClose={handleModalClose}
              id={unblock.id}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              status="unblocked"
            />
          )
        }
      </Modal>
      <div className={scss.container}>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.ADD}
        >
          <div className={scss.page_header}>
            <div className={scss.heading_wrapper}>
              <h2>
                {intl.formatMessage({ id: 'CommisionManagement.title' })}
              </h2>
            </div>
            <div className={classNames(commonStyles.buttonWrapper, scss.buttonWrapper)}>
              <Button
                onClick={handleAddRow}
              >
                {intl.formatMessage({ id: 'PriceManagement.add.newRule' })}
              </Button>
            </div>
          </div>
        </AccessControl>
        <hr />
        <div className={commonStyles.dataTableCover}>
          <Table
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  {intl.formatMessage({ id: 'PriceManagement.Table.header.Sno' })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({ id: 'PriceManagement.Table.header.volumefrom' })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({ id: 'PriceManagement.Table.header.volumeto' })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({ id: 'PriceManagement.Table.header.percent' })}
                </TableCell>
                <TableCell>
                  {intl.formatMessage({ id: 'PriceManagement.Table.header.action' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricemanagement.loadingInProgress || pricemanagement.data === null
                ? (
                  <TableRow>
                    <TableCell>
                      <CircularProgressLoader />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {pricemanagement.data.data.map((item: any, idx: any) => (
                      <TableRow key={idx}>
                        <TableCell>
                          {idx + 1}
                        </TableCell>
                        <TableCell>
                          {idx === edit.id ? (
                            <>
                              <TextField
                                name="valuefrom"
                                type="number"
                                value={editableRow.volumeFrom}
                                onChange={(e: any) => {
                                  setEditableRow({ ...editableRow, volumeFrom: e.target.value });
                                }}
                              />
                              {validationexiting[idx] !== undefined
                                && validationexiting[idx].valuefrom !== undefined
                                && validationexiting[idx].valuefrom.length > 0
                                && <ValidationError error={validationexiting[idx].valuefrom} />}
                            </>
                          ) : (
                            <div className={commonStyles.imagewrapper}>
                              <img
                                src={avatcoin}
                                alt="avatcoin"
                              />
                              <p>{item.volumeFrom}</p>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {idx === edit.id ? (
                            <>
                              <TextField
                                name="valueto"
                                type="number"
                                value={editableRow.volumeTo}
                                onChange={(e: any) => {
                                  setEditableRow({ ...editableRow, volumeTo: e.target.value });
                                }}
                              />
                              {validationexiting[idx] !== undefined
                                && validationexiting[idx].valueto !== undefined
                                && validationexiting[idx].valueto.length > 0
                                && <ValidationError error={validationexiting[idx].valueto} />}
                            </>
                          ) : (
                            <div className={commonStyles.imagewrapper}>
                              <img
                                src={avatcoin}
                                alt="avatcoin"
                              />
                              <p>{item.volumeTo}</p>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {idx === edit.id ? (
                            <>
                              <TextField
                                name="percentage"
                                type="number"
                                value={editableRow.percentage}
                                onChange={(e: any) => {
                                  setEditableRow({ ...editableRow, percentage: e.target.value });
                                }}
                              />
                              {validationexiting[idx] !== undefined
                                && validationexiting[idx].percent !== undefined
                                && validationexiting[idx].percent.length > 0
                                && <ValidationError error={validationexiting[idx].percent} />}
                            </>
                          ) : <p>{`${item.percentage}%`}</p>}
                        </TableCell>
                        <TableCell>
                          <div className={commonStyles.actionBtnsWrapper}>
                            {edit.show && edit.id === idx ? (
                              <div>
                                <img
                                  src={saveIcon}
                                  role="presentation"
                                  alt="save"
                                  width="35"
                                  title="Save"
                                  height="35"
                                  onClick={() => {
                                    let flag = true;
                                    const valida: any = {};
                                    if (editableRow.volumeFrom === undefined
                                      || editableRow.volumeFrom === '') {
                                      flag = false;
                                      valida.valuefrom = 'Please enter the required field';
                                    } else if (editableRow.volumeFrom === 0) {
                                      flag = false;
                                      valida.valuefrom = 'Please enter the value greater than 0';
                                    }
                                    if (editableRow.volumeTo === undefined
                                      || editableRow.volumeTo === '') {
                                      flag = false;
                                      valida.valueto = 'Please enter the required field';
                                    } else if (editableRow.volumeTo === 0) {
                                      flag = false;
                                      valida.valueto = 'Please enter the value greater than 0';
                                    }
                                    if (editableRow.percentage === undefined
                                      || editableRow.percentage === '') {
                                      flag = false;
                                      valida.percent = 'Please enter the required field';
                                    } else if (editableRow.percentage === 0) {
                                      flag = false;
                                      valida.percent = 'Please enter the value greater than 0';
                                    }

                                    if (flag) {
                                      setShowModal(true);
                                      setEdit({
                                        show: true,
                                        id: idx,
                                      });
                                    } else {
                                      const valid = [...validationexiting];
                                      valid[idx] = valida;
                                      setValidationExisting(valid);
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={editIcon}
                                  role="presentation"
                                  alt="edit"
                                  width="35"
                                  title="Edit"
                                  height="35"
                                  onClick={() => {
                                    setEdit({
                                      ...edit,
                                      id: idx,
                                      show: true,
                                    });
                                    setEditableRow({
                                      id: item._id,
                                      volumeFrom: item.volumeFrom,
                                      volumeTo: item.volumeTo,
                                      percentage: item.percentage,
                                    });
                                  }}
                                />
                              </div>
                            )}
                            <div>
                              <img
                                src={deleteIcon}
                                role="presentation"
                                alt="delete"
                                width="35"
                                title="Delete"
                                height="35"
                                onClick={() => {
                                  setShowModal(true);
                                  setEdit({
                                    show: false,
                                    id: null,
                                  });
                                  setDeletePrice({
                                    ...deletePrice,
                                    show: true,
                                    id: item._id,
                                  });
                                }}
                              />
                            </div>
                            <div>
                              {item.status === 'unblocked' ? (
                                <img
                                  src={blockIcon}
                                  role="presentation"
                                  alt="block"
                                  width="35"
                                  title="block"
                                  height="35"
                                  onClick={() => {
                                    setShowModal(true);
                                    setEdit({
                                      show: false,
                                      id: null,
                                    });
                                    setBlock({
                                      ...block,
                                      show: true,
                                      id: item._id,
                                    });
                                  }}
                                />
                              ) : (
                                <img
                                  src={unblockIcon}
                                  role="presentation"
                                  alt="unblock"
                                  width="35"
                                  title="Unblock"
                                  height="35"
                                  onClick={() => {
                                    setShowModal(true);
                                    setEdit({
                                      show: false,
                                      id: null,
                                    });
                                    setUnBlock({
                                      ...block,
                                      show: true,
                                      id: item._id,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {rows.map((item: any, idx: any) => (
                      <TableRow key={idx}>
                        <TableCell>
                          {count + idx + 1}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="valuefrom"
                            type="number"
                            value={item.valuefrom}
                            onChange={(e: any) => {
                              const row = [...rows];
                              row[idx] = { ...row[idx], valuefrom: e.target.value };
                              setRows(row);
                            }}
                          />
                          {validation[idx] !== undefined
                            && validation[idx].valuefrom !== undefined
                            && validation[idx].valuefrom.length > 0
                            && <ValidationError className={scss.errorMsg} error={validation[idx].valuefrom} />}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="valueto"
                            type="number"
                            value={item.valueto}
                            onChange={(e: any) => {
                              const row = [...rows];
                              row[idx] = { ...row[idx], valueto: e.target.value };
                              setRows(row);
                            }}
                          />
                          {validation[idx] !== undefined
                            && validation[idx].valueto !== undefined
                            && validation[idx].valueto.length > 0
                            && <ValidationError className={scss.errorMsg} error={validation[idx].valueto} />}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="percent"
                            type="number"
                            value={item.percent}
                            onChange={(e: any) => {
                              const row = [...rows];
                              row[idx] = { ...row[idx], percent: e.target.value };
                              setRows(row);
                            }}
                          />
                          {validation[idx] !== undefined
                            && validation[idx].percent !== undefined
                            && validation[idx].percent.length > 0
                            && <ValidationError className={scss.errorMsg} error={validation[idx].percent} />}
                        </TableCell>
                        <TableCell>
                          <>
                            <div className={commonStyles.actionBtnsWrapper}>
                              <div>
                                <img
                                  src={saveIcon}
                                  role="presentation"
                                  alt="edit"
                                  width="35"
                                  title="Edit"
                                  height="35"
                                  onClick={() => {
                                    let flag = true;
                                    const valida: any = {};

                                    if (rows[idx].valuefrom === undefined || rows[idx].valuefrom === '') {
                                      flag = false;
                                      valida.valuefrom = 'Please enter the required field';
                                    } else if (rows[idx].valuefrom === 0) {
                                      flag = false;
                                      valida.valuefrom = 'Please enter the value greater than 0';
                                    }
                                    if (rows[idx].valueto === undefined || rows[idx].valueto === '') {
                                      flag = false;
                                      valida.valueto = 'Please enter the required field';
                                    } else if (rows[idx].valueto === 0) {
                                      flag = false;
                                      valida.valueto = 'Please enter the value greater than 0';
                                    }
                                    if (rows[idx].percent === undefined || rows[idx].percent === '') {
                                      flag = false;
                                      valida.percent = 'Please enter the required field';
                                    } else if (rows[idx].percent === 0) {
                                      flag = false;
                                      valida.percent = 'Please enter the value greater than 0';
                                    }

                                    if (flag) {
                                      setShowModal(true);
                                      setSave({
                                        show: true,
                                        id: idx,
                                      });
                                    } else {
                                      const valid = [...validation];
                                      valid[idx] = valida;
                                      setValidation(valid);
                                    }
                                  }}
                                />
                              </div>
                              <div>
                                <img
                                  src={deleteIcon}
                                  role="presentation"
                                  alt="delete"
                                  width="35"
                                  title="Delete"
                                  height="35"
                                  onClick={handleRemoveSpecificRow(idx)}
                                />
                              </div>
                              <div>
                                &nbsp;
                              </div>
                            </div>
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  pricemanagement: state.pricemanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchPriceList: (payload: any) => dispatch(fetchPrices(payload)),
});

const PriceManagementPage = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PriceManagement);

export default PriceManagementPage;
