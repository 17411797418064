import api from '../api';

export class ChannelManagementService {
  static async getChannelList(payload: any) {
    const {
      page,
      rowsPerPage,
      startDate,
      endDate,
      userType,
      search,
      domes,
      isSortByUserName,
      isSortByChannelName,
      isSortByCategoryName,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}${startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== '' ? `&endDate=${endDate}` : ''}${userType !== 'Select' && userType !== '' && userType !== undefined ? `&status=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${domes !== 'Select' && domes !== '' && domes !== undefined ? `&categoryId=${domes}` : ''}${isSortByUserName !== undefined && isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${isSortByCategoryName}` : ''}${isSortByChannelName !== undefined && isSortByChannelName !== '' ? `&isSortByChannelName=${isSortByChannelName}` : ''}`;
    return api.getApiCall(`/api/v1/admins/channels?${apiEndPoint}`);
  }

  static async getChannelInfo(payload: any) {
    return api.getApiCall(`/api/v1/admins/channels/${payload}`);
  }

  static async getDomes() {
    return api.getApiCall('/api/v1/admins/categories/names');
  }

  static async exportUserListToMail(payload: any) {
    const {
      search,
      startDate,
      endDate,
      userType,
      isSortByUserName,
      isSortByRegister,
      type,
    } = payload;
    const apiEndPoint = `${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${userType !== 'Select' && userType !== '' && userType !== undefined ? `&userType=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByUserName !== undefined && isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}${isSortByRegister !== undefined && isSortByRegister !== '' ? `&isSortByRegister=${isSortByRegister}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/channels/exports?${apiEndPoint}`);
  }

  static async reportedChannel(payload: any) {
    const {
      page,
      rowsPerPage,
      id,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&userId=${id}`;
    return api.getApiCall(`/api/v1/admins/reported-users/channels?${apiEndPoint}`);
  }
}
