import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  ManageSubDomesComponent,
  LayoutWrapperMain,
  Page,
} from 'components';
import config from '../../config.js';
import css from './AddSubDomes.module.scss';

interface Props {
    scrollingDisabled: boolean,
    intl: any,
  }

const ManageSubDomesPageComponent: React.FC<Props & RouteComponentProps<any>> = (props: Props) => {
  const {
    intl,
    scrollingDisabled,
  } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'SubDomesmanagement.page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain className={css.layoutWrapperMain}>
        <div className={css.content}>
          <ManageSubDomesComponent />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
  // submitSignup: (dispatch: any, data: any) => dispatch(Signup(data)),
});

const ManageSubDomes = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageSubDomesPageComponent);

export default ManageSubDomes;
