import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';

const AES_ENCRYPTION_KEY = CryptoJS.enc.Utf8.parse('984556f98f2c8bcca11add3b77dfe56b');
const iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');

export const aesEncrypted = (text: string) => {
  try {
    const encrypted = CryptoJS.AES.encrypt(text, AES_ENCRYPTION_KEY, { iv, mode: CryptoJS.mode.CBC }).ciphertext.toString(CryptoJS.enc.Base64);
    return Base64.encode(JSON.stringify({
      encryptedData: encrypted,
      iv: 'abcdefghijklmnop',
    }));
  } catch (err: any) {
    // console.log(err);
  }
  return null;
};

export const aesDecrypt = (text: any) => {
  try {
    return CryptoJS.AES.decrypt(text, AES_ENCRYPTION_KEY, { iv }).toString(CryptoJS.enc.Utf8);
  } catch (e: any) {
    // console.log(err);
  }
  return null;
};
