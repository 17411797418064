import {
  AccessControl,
  Button,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ValidationError,
  Modal,
  CircularProgressLoader,
  MasterReasonManagementSave,
  MasterReasonManagementEdit,
  MasterReasonManagementDelete,
  MasterReasonMangementBlock,
} from 'components';
import saveIcon from 'assets/svg/save.svg';
import React, { useState } from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchMasterReason } from 'actions';
import classNames from 'classnames';
import editIcon from '../../assets/svg/edit.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import blockIcon from '../../assets/svg/block.svg';
import unblockIcon from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import scss from './masterreasoncomponent.module.scss';

interface Props {
  masterreasonmanagement: any
  fetchMasterReasonList: any;
  intl: any;
}

const ManageMasterReason: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { intl, fetchMasterReasonList, masterreasonmanagement } = props;
  const [rows, setRows] = React.useState<any>([]);
  const [editableRow, setEditableRow] = React.useState<any>();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 30,
  });
  React.useEffect(() => {
    fetchMasterReasonList(filter);
  }, [fetchMasterReasonList, filter]);
  const [validation, setValidation] = React.useState<any>([{}]);
  const [validationexiting, setValidationExisting] = React.useState<any>([{}]);
  const [save, setSave] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edit, setEdit] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deletePrice, setDeletePrice] = React.useState<any>({
    show: false,
    id: null,
  });
  const [block, setBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblock, setUnBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const [showModal, setShowModal] = React.useState(false);

  const handleAddRow = () => {
    const item = {
      title: '',
      description: '',
      reasons: [],
    };
    setRows((prevState: any) => [...prevState, item]);
  };

  const handleRemoveSpecificRow = (idx: any) => () => {
    setEdit({
      show: false,
      id: null,
    });
    const row = [...rows];
    row.splice(idx, 1);
    setRows(row);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setEdit({ ...edit, show: false, id: null });
    setSave({ ...save, show: false, id: null });
    setBlock({ ...block, show: false, id: null });
    setDeletePrice({ ...deletePrice, show: false, id: null });
    setUnBlock({ ...unblock, show: false, id: null });
  };

  const count = masterreasonmanagement.data === null ? 0 : masterreasonmanagement.data['Pagination-Count'];
  const [selected, setSelected] = useState<any>([]);

  const options: any = [
    'Report Service',
    'Report User',
    'Report Channel',
    'Report Event',
    'Report Campaign',
    'Report Message',
    'Deactivate Account',
    'Report Problem',
    'Contact Us',
  ];

  const optionsKeys: any = {
    SERVICE: 'Report Service',
    USER: 'Report User',
    CHANNEL: 'Report Channel',
    EVENT: 'Report Event',
    COMPAIGN: 'Report Campaign',
    MESSAGE: 'Report Message',
    DE_ACTIVATE_ACCOUNT: 'Deactivate Account',
    REPORT_PROBLEM: 'Report Problem',
    AVATUS_CONTACTING: 'Contact Us',
  };
  const handleReasonsSubmit = (reasons: any) => {
    const keys: any = [];
    reasons.forEach((reason: any) => {
      const key = Object.keys(optionsKeys).filter((ele: any) => optionsKeys[ele] === reason)[0];
      keys.push(key);
    });
    return keys;
  };

  const handlegetValues = (keys: any) => {
    const values: any = [];
    keys.forEach((ele: any) => {
      values.push(optionsKeys[ele]);
    });
    return values;
  };

  const isAllSelected = options.length > 0 && selected.length === options.length;

  const handleChange = (event: any, idx: any) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      const row = [...rows];
      row[idx] = { ...row[idx], reasons: selected.length === options.length ? [] : options };
      setRows(row);
      return;
    }
    const row = [...rows];
    row[idx] = { ...row[idx], reasons: value };
    setRows(row);
    setSelected(value);
  };
  return (
    <>
      <Modal
        id="masterreasonmanagement"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          save.show && (
            <MasterReasonManagementSave
              handleModalClose={handleModalClose}
              fetchReasonList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              title={rows[save.id].title}
              description={rows[save.id].description}
              reasons={handleReasonsSubmit(rows[save.id].reasons)}
            />
          )
        }
        {
          edit.show && (
            <MasterReasonManagementEdit
              handleModalClose={handleModalClose}
              fetchReasonList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              masterReasonId={editableRow.id}
              title={editableRow.title}
              description={editableRow.description}
              reasons={handleReasonsSubmit(editableRow.reasons)}
            />
          )
        }
        {
          deletePrice.show && (
            <MasterReasonManagementDelete
              handleModalClose={handleModalClose}
              fetchReasonList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              masterReasonId={deletePrice.id}
            />
          )
        }
        {
          block.show && (
            <MasterReasonMangementBlock
              handleModalClose={handleModalClose}
              id={block.id}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              status="blocked"
            />
          )
        }
        {
          unblock.show && (
            <MasterReasonMangementBlock
              handleModalClose={handleModalClose}
              id={unblock.id}
              fetchPriceList={() => {
                setFilter({ ...filter, page: 0 });
                setRows([]);
              }}
              status="unblocked"
            />
          )
        }
      </Modal>
      <div className={scss.container}>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.ADD}
        >
          <div className={scss.page_header}>
            <div className={classNames(commonStyles.buttonWrapper, scss.buttonWrapper)}>
              <Button
                onClick={handleAddRow}
              >
                Add Reason
              </Button>
            </div>
          </div>
        </AccessControl>
        {/* <hr /> */}
        <div className={commonStyles.dataTableCover}>
          <Table
            size="medium"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  S.No.
                </TableCell>
                <TableCell>
                  Title
                </TableCell>
                <TableCell>
                  Description
                </TableCell>
                <TableCell>
                  Report module
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {masterreasonmanagement.loadingInProgress || masterreasonmanagement.data === null
                ? (
                  <TableRow>
                    <TableCell>
                      <CircularProgressLoader />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {masterreasonmanagement.data.data.map((item: any, idx: any) => (
                      <TableRow key={idx}>
                        <TableCell>
                          {idx + 1}
                        </TableCell>
                        <TableCell>
                          {idx === edit.id ? (
                            <>
                              <TextField
                                name="title"
                                value={editableRow.title}
                                onChange={(e: any) => {
                                  setEditableRow({ ...editableRow, title: e.target.value });
                                }}
                              />
                              {validationexiting[idx] !== undefined
                                && validationexiting[idx].title !== undefined
                                && validationexiting[idx].title.length > 0
                                && <ValidationError error={validationexiting[idx].title} />}
                            </>
                          ) : (
                            <div className={commonStyles.imagewrapper}>
                              <p>{item.reason}</p>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {idx === edit.id ? (
                            <>
                              <TextField
                                name="description"
                                value={editableRow.description}
                                onChange={(e: any) => {
                                  setEditableRow({ ...editableRow, description: e.target.value });
                                }}
                              />
                              {validationexiting[idx] !== undefined
                                && validationexiting[idx].description !== undefined
                                && validationexiting[idx].description.length > 0
                                && <ValidationError error={validationexiting[idx].description} />}
                            </>
                          ) : (
                            <div className={commonStyles.imagewrapper}>
                              <p>{item.description}</p>
                            </div>
                          )}
                        </TableCell>
                        <TableCell className={idx === edit.id ? '' : scss.reportModuleChips}>
                          {idx === edit.id ? (
                            <>
                              <Select
                                labelId="mutiple-select-label"
                                multiple
                                value={editableRow.reasons}
                                onChange={(event: any) => {
                                  const { value } = event.target;
                                  let row: any;
                                  if (value[value.length - 1] === 'all') {
                                    row = [...rows];
                                    row[idx] = { ...row[idx], reasons: selected.length === options.length ? [] : options };
                                  }
                                  row = [...rows];
                                  row[idx] = { ...row[idx], reasons: value };
                                  setEditableRow({
                                    ...editableRow,
                                    reasons: row[idx].reasons,
                                  });
                                }}
                                renderValue={(select: any) => (
                                  <div>
                                    {(select as string[]).map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </div>
                                )}
                              >
                                {options.map((option: string) => (
                                  <MenuItem key={option} value={option}>
                                    <ListItemIcon>
                                      <Checkbox checked={editableRow.reasons.indexOf(option) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={option} />
                                  </MenuItem>
                                ))}
                              </Select>
                              {validationexiting[idx] !== undefined
                                && validationexiting[idx].reasons !== undefined
                                && validationexiting[idx].reasons.length > 0
                                && <ValidationError error={validationexiting[idx].reasons} />}
                            </>
                          ) : (
                            <>
                              <div>
                                {item.reportModuleCodes.map((ele: any) => (
                                  <Chip
                                    key={optionsKeys[ele]}
                                    label={optionsKeys[ele]}
                                  />
                                ))}
                              </div>
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <div className={commonStyles.actionBtnsWrapper}>
                            {edit.show && edit.id === idx ? (
                              <div>
                                <img
                                  src={saveIcon}
                                  role="presentation"
                                  alt="save"
                                  width="35"
                                  title="Save"
                                  height="35"
                                  onClick={() => {
                                    let flag = true;
                                    const valida: any = {};
                                    if (editableRow.title === undefined
                                      || editableRow.title === '') {
                                      flag = false;
                                      valida.valuefrom = 'Please enter the required field';
                                    }
                                    if (editableRow.description === undefined
                                      || editableRow.description === '') {
                                      flag = false;
                                      valida.valueto = 'Please enter the required field';
                                    }
                                    if (editableRow.reasons === undefined
                                      || editableRow.reasons.length === 0) {
                                      flag = false;
                                      valida.percent = 'Please enter the required field';
                                    }

                                    if (flag) {
                                      setShowModal(true);
                                      setEdit({
                                        show: true,
                                        id: idx,
                                      });
                                    } else {
                                      const valid = [...validationexiting];
                                      valid[idx] = valida;
                                      setValidationExisting(valid);
                                    }
                                  }}
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={editIcon}
                                  role="presentation"
                                  alt="edit"
                                  width="35"
                                  title="Edit"
                                  height="35"
                                  onClick={() => {
                                    setEdit({
                                      ...edit,
                                      id: idx,
                                      show: true,
                                    });
                                    setEditableRow({
                                      id: item._id,
                                      title: item.reason,
                                      description: item.description,
                                      reasons: handlegetValues(item.reportModuleCodes),
                                    });
                                  }}
                                />
                              </div>
                            )}
                            <div>
                              <img
                                src={deleteIcon}
                                role="presentation"
                                alt="delete"
                                width="35"
                                title="Delete"
                                height="35"
                                onClick={() => {
                                  setShowModal(true);
                                  setEdit({
                                    show: false,
                                    id: null,
                                  });
                                  setDeletePrice({
                                    ...deletePrice,
                                    show: true,
                                    id: item._id,
                                  });
                                }}
                              />
                            </div>
                            <div>
                              {item.status === 'unblocked' ? (
                                <img
                                  src={blockIcon}
                                  role="presentation"
                                  alt="block"
                                  width="35"
                                  title="block"
                                  height="35"
                                  onClick={() => {
                                    setShowModal(true);
                                    setEdit({
                                      show: false,
                                      id: null,
                                    });
                                    setBlock({
                                      ...block,
                                      show: true,
                                      id: item._id,
                                    });
                                  }}
                                />
                              ) : (
                                <img
                                  src={unblockIcon}
                                  role="presentation"
                                  alt="unblock"
                                  width="35"
                                  title="Unblock"
                                  height="35"
                                  onClick={() => {
                                    setShowModal(true);
                                    setEdit({
                                      show: false,
                                      id: null,
                                    });
                                    setUnBlock({
                                      ...block,
                                      show: true,
                                      id: item._id,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {rows.map((item: any, idx: any) => (
                      <TableRow key={idx}>
                        <TableCell>
                          {count + idx + 1}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="title"
                            value={item.title}
                            placeholder="Enter title"
                            onChange={(e: any) => {
                              const row = [...rows];
                              row[idx] = { ...row[idx], title: e.target.value };
                              setRows(row);
                            }}
                          />
                          {validation[idx] !== undefined
                            && validation[idx].title !== undefined
                            && validation[idx].title.length > 0
                            && <ValidationError className={scss.errorMsg} error={validation[idx].title} />}
                        </TableCell>
                        <TableCell>
                          <TextField
                            name="description"
                            placeholder="Enter description"
                            value={item.description}
                            onChange={(e: any) => {
                              const row = [...rows];
                              row[idx] = { ...row[idx], description: e.target.value };
                              setRows(row);
                            }}
                          />
                          {validation[idx] !== undefined
                            && validation[idx].description !== undefined
                            && validation[idx].description.length > 0
                            && <ValidationError className={scss.errorMsg} error={validation[idx].description} />}
                        </TableCell>
                        <TableCell>
                          <FormControl className={scss.selectBoxWrapper}>
                            <InputLabel id="mutiple-select-label">Select Reasons</InputLabel>
                            <Select
                              labelId="mutiple-select-label"
                              multiple
                              value={rows[idx].reasons}
                              onChange={(e: any) => handleChange(e, idx)}
                              renderValue={(select: any) => (
                                <div>
                                  {(select as string[]).map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </div>
                              )}
                            >
                              <MenuItem
                                value="all"
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={isAllSelected}
                                    indeterminate={
                                      selected.length > 0 && selected.length < options.length
                                    }
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Select All"
                                />
                              </MenuItem>
                              {options.map((option: string) => (
                                <MenuItem key={option} value={option}>
                                  <ListItemIcon>
                                    <Checkbox checked={rows[idx].reasons.indexOf(option) > -1} />
                                  </ListItemIcon>
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {validation[idx] !== undefined
                            && validation[idx].reasons !== undefined
                            && validation[idx].reasons.length > 0
                            && <ValidationError className={scss.errorMsg} error={validation[idx].reasons} />}
                        </TableCell>
                        <TableCell>
                          <>
                            <div className={commonStyles.actionBtnsWrapper}>
                              <div>
                                <img
                                  src={saveIcon}
                                  role="presentation"
                                  alt="edit"
                                  width="35"
                                  title="Edit"
                                  height="35"
                                  onClick={() => {
                                    let flag = true;
                                    const valida: any = {};

                                    if (rows[idx].title === undefined || rows[idx].title === '') {
                                      flag = false;
                                      valida.title = 'Please enter the required field';
                                    }
                                    if (rows[idx].description === undefined || rows[idx].description === '') {
                                      flag = false;
                                      valida.description = 'Please enter the required field';
                                    }
                                    if (rows[idx].reasons === undefined || rows[idx].reasons === '') {
                                      flag = false;
                                      valida.reasons = 'Please enter the required field';
                                    } else if (rows[idx].reasons === 0) {
                                      flag = false;
                                      valida.reasons = 'Please enter the value greater than 0';
                                    }

                                    if (flag) {
                                      setShowModal(true);
                                      setSave({
                                        show: true,
                                        id: idx,
                                      });
                                    } else {
                                      const valid = [...validation];
                                      valid[idx] = valida;
                                      setValidation(valid);
                                    }
                                  }}
                                />
                              </div>
                              <div>
                                <img
                                  src={deleteIcon}
                                  role="presentation"
                                  alt="delete"
                                  width="35"
                                  title="Delete"
                                  height="35"
                                  onClick={handleRemoveSpecificRow(idx)}
                                />
                              </div>
                              <div>
                                &nbsp;
                              </div>
                            </div>
                          </>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  masterreasonmanagement: state.masterreasonmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchMasterReasonList: (payload: any) => dispatch(fetchMasterReason(payload)),
});

const ManageMasterReasonPage = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageMasterReason);

export default ManageMasterReasonPage;
