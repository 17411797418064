import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { UserInfo } from '../modal';

export default function reducer(state: UserInfo = new UserInfo(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.USERINFO_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.USERINFO_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        loadingSuccess: true,
        data: payload,
      };
    case ActionTypes.USERINFO_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
