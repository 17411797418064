const month = [
  'January',
  'Febuary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dateFormatting = (date: any) => {
  if (date !== undefined && date !== null) {
    const mnth = month[new Date(date).getMonth()];
    const dt = new Date(date).getDate();
    const year = new Date(date).getFullYear();
    return `${dt} ${mnth} ${year}`;
  }
  return null;
};

export const getDateAndTime = (date: any) => {
  const mnth = month[new Date(date).getMonth()];
  const dt = new Date(date).getDate();
  const year = new Date(date).getFullYear();
  const hours = new Date(date).getHours();
  const Minutes = new Date(date).getMinutes();
  return `${dt} ${mnth} ${year}, ${hours > 12 ? hours - 12 : hours}:${Minutes < 10 ? `0${Minutes}` : Minutes} ${hours > 11 ? 'PM' : 'AM'}`;
};
