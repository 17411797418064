import React from 'react';
import css from './dashboard.module.scss';

const Dashboard = () => (
  <div className={css.container}>
    <div className={css.under}>
      <h1>Under Development</h1>
    </div>
  </div>
);

export default Dashboard;
