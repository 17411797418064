import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import css from './FieldSelect.module.scss';

interface Props {
  rootClassName?: string,
  className?: string,
  inputRootClass?: string,
  children?: any,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  options: any,
  name: string,
  valid?: boolean,
  formProps: any,
  label?: string,
  defaultValue?: boolean,
}

interface OptionInterface {
  key: any;
  label: any;
}

const FieldSelectMultiple = (props: Props) => {
  const {
    rootClassName,
    id,
    className,
    inputRootClass,
    children,
    name,
    options,
    formProps: { control, setValue },
    valid,
    defaultValue,
    label,
  } = props;
  const [selectedValues, setSelectedValues] = React.useState<OptionInterface[]>([]);

  const inputClasses = classNames(inputRootClass, css.checkbox, {
    [css.inputSuccess]: valid,
  });
  const [isOpen, setOpen] = useState(false);

  const handleChecked = (option: any) => {
    const bool = selectedValues.filter((ele: any) => (ele.key === option.key));
    if (bool.length === 0) return false;
    return true;
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={() => (
          <FormControl>
            <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id={id}
              multiple
              className={inputClasses}
              value={selectedValues}
              onChange={(event: any) => {
                if (selectedValues.indexOf(event.target.value) > 0) {
                  const values = selectedValues;
                  values.splice(values.indexOf(event.target.value), 1);
                  setSelectedValues(values);
                } else {
                  setSelectedValues(event.target.value);
                  setValue(name, event.target.value);
                }
              }}
              input={<OutlinedInput label={name} />}
              onOpen={() => setOpen(true)}
              open={isOpen}
              onClose={() => {
                setOpen(false);
              }}
              renderValue={() => (
                selectedValues?.map((option: any) => option.label).join(', ')
                || 'Select some options'
              )}
            >
              {options.map((option: any) => (
                <MenuItem key={option.key} value={option}>
                  <Checkbox
                    checked={handleChecked(option)}
                  />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
      {children}
    </div>
  );
};

FieldSelectMultiple.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  children: null,
  valid: null,
  label: '',
  defaultValue: [],
};

export default FieldSelectMultiple;
