import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { ChannelInfo } from '../modal';

export default function reducer(state: ChannelInfo = new ChannelInfo(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CHL_INFO_REQUEST:
      return {
        ...state,
        loadingChannelInfoProgress: true,
      };
    case ActionTypes.CHL_INFO_SUCCESS:
      return {
        ...state,
        loadingChannelInfoProgress: false,
        data: payload,
      };
    case ActionTypes.CHL_MGMT_LIST_ERROR:
      return {
        ...state,
        loadingChannelInfoProgress: false,
        loadingChannelInfoError: payload,
      };
    default:
      return state;
  }
}
