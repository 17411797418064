import {
  put,
  call,
  takeEvery,
} from 'redux-saga/effects';
import { ContentManagementService } from '../services';
import { ActionTypes } from '../constants';

async function fetchAsync(func: any, arg: any) {
  try {
    const response = arg !== null ? await func(arg) : await func();
    return response;
  } catch (error: any) {
    return {
      error: true,
      message: error.response.data.message,
      status: error.response.data.statusCode,
    };
  }
}

function* contentManagement(): Generator {
  try {
    const data:any = yield call(fetchAsync, ContentManagementService.getContent, null);
    if (data.error) {
      if (data.status === 403) {
        yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
      } else {
        yield put({ type: ActionTypes.CONTENT_MGMT_LIST_ERROR, payload: data.message });
      }
    } else {
      yield put({ type: ActionTypes.CONTENT_MGMT_LIST_SUCCESS, payload: data.data.data });
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.CONTENT_MGMT_LIST_ERROR, error: e.data });
  }
}

export function* watchContentManagementSaga() {
  yield takeEvery(ActionTypes.CONTENT_MGMT_LIST_REQUEST, contentManagement);
}
export default watchContentManagementSaga;
