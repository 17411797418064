import { ActionTypes } from '../constants/index';

export const getChannelReport = (payload: any) => ({
  type: ActionTypes.CHL_REPORT_DETAIL_REQUEST,
  payload: {
    id: payload.id,
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
  },
});
