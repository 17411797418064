import { ActionTypes } from '../constants/index';

export const getNotificationDetails = (payload: any) => ({
  type: ActionTypes.NOTIFICATION_DETAILS_LIST_REQUEST,
  payload: {
    id: payload.id,
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
  },
});
