import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { Chip, TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
  ValidationError, CircularProgressLoader, Button, Overlay, ToastBar,
} from 'components';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { UserInfoService, SupportManagementService } from 'services';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { getDateAndTime } from '../../utils/dateMethods';
import { getSupportInfo } from '../../actions';
import modalStyles from '../Modal/Modal.module.scss';
import scss from './supportinfo.module.scss';

interface Props {
  supportInfo: any;
  id: any;
  fetchSupportInfo: any;
  intl: any;
}

const SupportInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    supportInfo,
    id,
    fetchSupportInfo,
    intl,
  } = props;
  // const [status, setStatus] = React.useState('');
  const [emailNotificationError, setEmailNotificationError] = React.useState({
    title: false,
    message: '',
  });
  React.useEffect(() => {
    fetchSupportInfo(id);
  }, [fetchSupportInfo, id]);
  const [emailTitle, setEmailTitle] = React.useState('');
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  return (
    <>
      {showOverlay && <Overlay />}
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => {
              setNotification({
                ...notification,
                show: false,
                message: '',
              });
            }}
          />
        )
      }
      {
        supportInfo.data === null || supportInfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.userInformatinc}>
                  <div className={commonStyles.userInfoinner}>
                    <div>
                      <h2>
                        Ticket ID:
                        {' '}
                        {supportInfo.data.ticketId}
                      </h2>
                      <div className={scss.label}>
                        Title:
                        <span className={scss.value}>
                          {supportInfo.data.title}
                        </span>
                      </div>
                      <div className={scss.label}>
                        {intl.formatMessage({ id: 'ChannelInfo.label.description' })}
                      </div>
                      <p className={scss.value}>
                        {supportInfo.data.description}
                      </p>
                    </div>
                    <div className={commonStyles.right_container}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className={scss.dropdown}
                        value={supportInfo.data.status}
                        label="Age"
                        onChange={(val: any) => {
                          setShowOverlay(true);
                          SupportManagementService.changeStatus({
                            ticketId: id,
                            status: val.target.value,
                          }).then(() => {
                            setShowOverlay(false);
                            setNotification({
                              show: true,
                              message: 'Status changed successfully',
                            });
                            fetchSupportInfo(id);
                          }).catch((err: any) => {
                            setShowOverlay(false);
                            setNotification({
                              show: true,
                              message: err.response.data.message,
                            });
                          });
                        }}
                      >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="under review">Under Review</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>

                <div className={commonStyles.postDetails}>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Username
                      </p>
                      <div className="badge">
                        <Chip label={supportInfo.data.userId.username} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Email ID
                      </p>
                      <div className="badge">
                        <Chip label={supportInfo.data.userId.emailFlag ? 'Yes' : 'No'} />
                      </div>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Raised At
                      </p>
                      <div className="badge">
                        <Chip label={getDateAndTime(supportInfo.data.createdAt)} />
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Status
                      </p>
                      <div className="badge">
                        <Chip label={supportInfo.data.status} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3>Email Notification</h3>
                  <div className="text_field">
                    <TextField
                      onChange={(e) => {
                        setEmailTitle(e.target.value);
                      }}
                      value={emailTitle}
                      id="emailTitle"
                      label="*Subject of the Email"
                      name="textField"
                      variant="outlined"
                      style={{ width: '100%' }}
                    />
                  </div>
                  {emailNotificationError.title && <ValidationError error="Email title can not be empty" />}
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(e) => setEditorState(e)}
                    toolbar={{
                      options: ['inline', 'textAlign'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                      },
                      textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify'],
                      },
                    }}
                  />
                  {emailNotificationError.message === 'empty'
                    && <ValidationError error="Email content can not be empty" />}
                  {emailNotificationError.message === 'greater'
                    && <ValidationError error="Email content can not be greater than 2000 alphabets" />}
                  <div className={modalStyles.modalButtonWrapper}>
                    <Button
                      // className={scss.margin_right}
                      onClick={() => {
                        if (emailTitle.length === 0) {
                          setEmailNotificationError({ ...emailNotificationError, title: true });
                        } else if (editorState.getCurrentContent().getPlainText().length === 0) {
                          setEmailNotificationError({ ...emailNotificationError, title: false, message: 'empty' });
                        } else if (editorState.getCurrentContent().getPlainText().length > 2000) {
                          setEmailNotificationError({ ...emailNotificationError, message: 'greater' });
                        } else {
                          setShowOverlay(true);
                          UserInfoService.sendEmail({
                            userId: supportInfo.data.userId._id,
                            subject: emailTitle,
                            message: stateToHTML(editorState.getCurrentContent()),
                          }).then(() => {
                            setEmailTitle(() => '');
                            setShowOverlay(false);
                            setNotification({
                              show: true,
                              message: 'Notification delivered successfully',
                            });
                            setEditorState(EditorState.createEmpty());
                          }).catch((err: any) => {
                            setShowOverlay(false);
                            setNotification({
                              show: true,
                              message: err.response.data.message,
                            }); // Notification delivered successfully.
                          });
                        }
                      }}
                    >
                      Send Notification
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => ({
  supportInfo: state.supportInfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchSupportInfo: (payload: any) => dispatch(getSupportInfo(payload)),
});

const SupportInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(SupportInfoComponent);

export default SupportInfo;
