import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgressLoader,
  Modal,
  UserReportDetail,
  DeleteUserInfo,
  BlockUserInfo,
  UserInfoSendNotification,
  PostReportDetail,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  AccessControl,
  ChannelReportDetailUserManagement,
  CampaignReportDetailUserManagement,
  NotificationDetail,
  NotesDetail,
  EventReportDetail,
  AddNote,
  Overlay,
  Accordion,
  ToastBar,
} from 'components';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import scss from './reportuserinfo.module.scss';
import { getUserInfo } from '../../actions';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import deleteIcon from '../../assets/svg/delete.svg';
import blockIcon from '../../assets/svg/block.svg';
import exportIcon from '../../assets/svg/export.svg';
import userprofile from '../../assets/svg/image-placeholder.png';
import active from '../../assets/svg/active.svg';
import blocked from '../../assets/svg/blocked.svg';
import pending from '../../assets/svg/pending.svg';
import unblock from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  fetchUserInfo: any;
  id: any;
  intl: any;
  userInfo: any;
}

const ReportUserInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    fetchUserInfo,
    id,
    userInfo,
    intl,
  } = props;
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const [deleteUser, setDeleteUser] = React.useState(false);
  const [blockUser, setBlockUser] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [addNote, setAddNote] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  React.useEffect(() => {
    fetchUserInfo(id);
  }, [fetchUserInfo, id]);

  const handleDeleteUser = () => {
    setDeleteUser(true);
    setShowModal(true);
  };
  const handleModalClose = () => {
    setDeleteUser(false);
    setBlockUser(false);
    setShowModal(false);
    setSendNotification(false);
    setAddNote(false);
  };
  const handleBlockUser = () => {
    setBlockUser(true);
    setShowModal(true);
  };

  const handleSendNotification = () => {
    setShowModal(true);
    setSendNotification(true);
  };
  const history = useHistory();

  return (
    <>
      {showOverlay && <Overlay />}
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => setNotification({
              show: false,
              message: '',
            })}
          />
        )
      }
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className={commonStyles.modal_container}>
          {deleteUser && (
            <DeleteUserInfo
              setShowOverlay={setShowOverlay}
              fetchUserInfo={() => history.push('/reportusermanagement')}
              handleModalClose={handleModalClose}
              id={id}
            />
          )}
          {blockUser && (
            <BlockUserInfo
              id={id}
              fetchUserInfo={fetchUserInfo}
              handleModalClose={handleModalClose}
              userInfoStatus={userInfo.data.status}
              setShowOverlay={setShowOverlay}
            />
          )}
          {
            sendNotification && (
              <UserInfoSendNotification
                setNotification={setNotification}
                setShowOverlay={setShowOverlay}
                id={id}
                handleModalClose={handleModalClose}
              />
            )
          }
          {
            addNote && (
              <AddNote
                setShowOverlay={setShowOverlay}
                setNotification={setNotification}
                handleModalClose={() => {
                  fetchUserInfo();
                  handleModalClose();
                }}
                id={id}
              />
            )
          }
        </div>
      </Modal>
      <div>
        {userInfo.data === null || userInfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.headerActions}>
                  <div>
                    <Button onClick={() => {
                      setShowModal(true);
                      setAddNote(true);
                    }}
                    >
                      Add Note
                    </Button>
                    <Button onClick={handleSendNotification}>
                      Send Notification
                    </Button>
                  </div>
                  <div className={commonStyles.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img src={exportIcon} alt="Export" title="Export" />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          role="presentation"
                          src={deleteIcon}
                          alt="Delete"
                          title="Delete"
                          onClick={handleDeleteUser}
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          role="presentation"
                          src={userInfo.data.status === 'blocked'
                            ? unblock : blockIcon}
                          alt="Block"
                          title="Block"
                          onClick={handleBlockUser}
                        />
                      </span>
                    </AccessControl>
                  </div>
                </div>
                <div className={commonStyles.userInformatinc}>
                  <div className={scss.column_left}>
                    <img src={userprofile} alt="userprofile" />
                  </div>
                  <div className={scss.column_right}>
                    <div className={classNames(commonStyles.userInfoinner, scss.userInfo)}>
                      <div>
                        <h2>
                          {userInfo.data.username}
                        </h2>
                        <h3>Bio</h3>
                        <p>
                          {userInfo.data.bio}
                        </p>
                      </div>
                      {
                        userInfo.data.status === 'blocked'
                          ? <img src={blocked} alt="blocked" /> : (
                            userInfo.data.registerationStatus === 'pending'
                              ? <img src={pending} alt="pending" />
                              : <img src={active} alt="active" />
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="User Report Detail">
                <UserReportDetail id={id} />
              </Accordion>
              <Accordion heading="Post Report Detail">
                <PostReportDetail id={id} />
              </Accordion>
              <Accordion heading="Channel Report Detail">
                <ChannelReportDetailUserManagement id={id} />
              </Accordion>
              <Accordion heading="Event Report Detail">
                <EventReportDetail id={id} />
              </Accordion>
              <Accordion heading={intl.formatMessage({ id: 'CampaignReport.label.title' })}>
                <CampaignReportDetailUserManagement />
              </Accordion>
              <Accordion heading="Notification Details">
                <NotificationDetail id={id} />
              </Accordion>
              <Accordion heading="Notes Detail">
                <NotesDetail id={id} />
              </Accordion>
            </>
          )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { userinfo } = state;
  return {
    userInfo: userinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserInfo: (payload: any) => dispatch(getUserInfo(payload)),
});

const ReportUserInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ReportUserInfoComponent);

export default ReportUserInfo;
