import { AuthService } from '../services';
import { ActionTypes } from '../constants/index';

export const authInfoSuccess = (data: any) => (
  {
    type: ActionTypes.AUTH_INFO_SUCCESS,
    payload: data,
  }
);

export const authInfo = (dispatch: any) => {
  const isTokenValid = AuthService.authRequest();
  if (isTokenValid) {
    dispatch(authInfoSuccess({ isAnonymous: false }));
  } else {
    dispatch(authInfoSuccess({ isAnonymous: true }));
  }
};

export const loginRequest = (data: any) => ({
  type: ActionTypes.LOGIN_REQUEST,
  payload: data,
});

export const forgotPassword = (data: any) => ({
  type: ActionTypes.FORGOTPASS_REQUEST,
  payload: data,
});

export const changeSuccessPass = () => ({
  type: ActionTypes.FORGOTPASS_STATEINIT,
});

export const resendOTPRequestSignup = (data: any) => ({
  type: ActionTypes.RESEND_OTP_REQUEST_SIGNUP,
  payload: data,
});

export const logout = () => ({
  type: ActionTypes.LOGOUT_REQUEST,
});
