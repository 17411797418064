import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { FAQSManagement } from '../modal';

export default function reducer(state: FAQSManagement = new FAQSManagement(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FAQS_MGMT_LIST_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.FAQS_MGMT_LIST_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.FAQS_MGMT_LIST_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
