import { EditDomesForm, AddDomesForm } from 'forms';
import React, { useState } from 'react';
import { DomesManagementService } from 'services';
import { useHistory, useParams } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

interface AddDomesInterface {
  id: string;
}

const ManageDomesComponent: React.FC = () => {
  const { id } = useParams<AddDomesInterface>();
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();
  const editDome: boolean = history.location.pathname.includes('edit');
  const [error, setError] = useState('');
  const handleSubmitForm = async (data: any) => {
    setLoader(true);
    const form1 = new FormData();
    const form2 = new FormData();
    const form3 = new FormData();
    const form4 = new FormData();
    form1.append('file', data.selectedIcon[0]);
    form2.append('file', data.unselectedIcon[0]);
    form3.append('file', data.selectedSVG[0]);
    form4.append('file', data.unselectedsvg[0]);
    const selectedImageS3Url = await DomesManagementService.uploadpicture(form1);
    const unselectedImageS3Url = await DomesManagementService.uploadpicture(form2);
    const selectSVGImageS3Url = await DomesManagementService.uploadpicture(form3);
    const unselectSVGImageS3Url = await DomesManagementService.uploadpicture(form4);

    DomesManagementService.addDomes({
      name: data.domeName,
      description: data.description,
      selectedImage: selectedImageS3Url.data.data.url,
      nonSelectedImage: unselectedImageS3Url.data.data.url,
      selectedImageSvg: selectSVGImageS3Url.data.data.url,
      nonSelectedImageSvg: unselectSVGImageS3Url.data.data.url,
    }).then(() => {
      setLoader(false);
      history.push('/domesmanagement');
    }).catch((err: any) => {
      setLoader(false);
      setError(err.response.data.message);
    });
  };
  const handleSaveAsDraftAddomes = async (e: any, data: any) => {
    e.preventDefault();
    setLoader(true);
    let selectedImageS3Url: any = '';
    let unSelectedImageS3Url: any = '';
    let selectSVGImageS3Url: any = '';
    let unselectSVGImageS3Url: any = '';
    if (data.domeName.length > 0) {
      if (typeof (data.selectedIcon) !== 'string' && data.selectedIcon !== null && data.selectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.selectedIcon[0]);
        selectedImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.unselectedIcon) !== 'string' && data.unselectedIcon !== null && data.unselectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.unselectedIcon[0]);
        unSelectedImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.selectedSVG) !== 'string' && data.selectedSVG !== null && data.selectedSVG !== undefined) {
        const form = new FormData();
        form.append('file', data.selectedSVG[0]);
        selectSVGImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.unselectedsvg) !== 'string' && data.unselectedsvg !== null && data.unselectedsvg !== undefined) {
        const form = new FormData();
        form.append('file', data.unselectedsvg[0]);
        unselectSVGImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      const selectedImage = selectedImageS3Url === '' ? {} : { selectedImage: selectedImageS3Url.data.data.url };
      const nonSelectedImage = unSelectedImageS3Url === '' ? {} : { nonSelectedImage: unSelectedImageS3Url.data.data.url };
      const selectedImageSvg = selectSVGImageS3Url === '' ? {} : { selectedSVG: selectSVGImageS3Url.data.data.url };
      const nonSelectedImageSvg = unselectSVGImageS3Url === '' ? {} : { unselectedsvg: unselectSVGImageS3Url.data.data.url };

      DomesManagementService.saveDomesToDraft({
        name: data.domeName,
        description: data.description,
        ...selectedImage,
        ...nonSelectedImage,
        ...selectedImageSvg,
        ...nonSelectedImageSvg,
      }).then(() => {
        setLoader(false);
        history.push('/domesmanagement');
      }).catch((err: any) => {
        setLoader(false);
        setError(err.response.data.message);
      });
    } else {
      setLoader(false);
      setError(() => 'Domes Name cannot be empty for draft');
    }
  };

  const handleSaveAsDraft = async (e: any, data: any) => {
    e.preventDefault();
    setLoader(true);
    let selectedImageS3Url: any = '';
    let unSelectedImageS3Url: any = '';
    let selectSVGImageS3Url: any = '';
    let unselectSVGImageS3Url: any = '';

    if (data.domeName.length > 0) {
      if (typeof (data.selectedIcon) !== 'string' && data.selectedIcon !== null && data.selectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.selectedIcon[0]);
        selectedImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.unselectedIcon) !== 'string' && data.unselectedIcon !== null && data.unselectedIcon !== undefined) {
        const form = new FormData();
        form.append('file', data.unselectedIcon[0]);
        unSelectedImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.selectedIconSVG) !== 'string' && data.selectedIconSVG !== null && data.selectedIconSVG !== undefined) {
        const form = new FormData();
        form.append('file', data.selectedIconSVG[0]);
        selectSVGImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.unselectedsvg) !== 'string' && data.unselectedsvg !== null && data.unselectedsvg !== undefined) {
        const form = new FormData();
        form.append('file', data.unselectedsvg[0]);
        unselectSVGImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      const selectedImage = selectedImageS3Url === '' ? {} : { selectedImage: selectedImageS3Url.data.data.url };
      const nonSelectedImage = unSelectedImageS3Url === '' ? {} : { nonSelectedImage: unSelectedImageS3Url.data.data.url };
      const selectedImageSvg = selectSVGImageS3Url === '' ? {} : { selectedSVG: selectSVGImageS3Url.data.data.url };
      const nonSelectedImageSvg = unselectSVGImageS3Url === '' ? {} : { unselectedsvg: unselectSVGImageS3Url.data.data.url };

      DomesManagementService.savePutDomesToDraft({
        name: data.domeName,
        description: data.description,
        ...selectedImage,
        ...nonSelectedImage,
        ...selectedImageSvg,
        ...nonSelectedImageSvg,
        categoryId: id,
      }).then(() => {
        setLoader(false);
        history.push('/domesmanagement');
      }).catch((err: any) => {
        setLoader(false);
        setError(err.response.data.message);
      });
    } else {
      setLoader(false);
      setError(() => 'Domes Name cannot be empty for draft');
    }
  };

  const handleSubmitFormEdit = async (data: any) => {
    setError('');
    let flag = true;
    const FILE_SIZE = 50 * 1024 * 1024;
    const SUPPORTED_FORMATS = [
      'image/jpg',
      'image/jpeg',
      'image/png',
    ];
    let selectedImageS3Url: any = '';
    let unSelectedImageS3Url: any = '';
    let selectedImageSVGS3Url: any = '';
    let unSelectedImageSVGS3Url: any = '';
    setLoader(true);

    if (data.selectedIcon === null) {
      flag = false;
      setLoader(false);
      setError(() => 'Please fill all required fields');
    }
    if (data.unselectedIcon === null && flag) {
      flag = false;
      setLoader(false);
      setError(() => 'Please fill all required fields');
    }

    if (data.selectedIconSVG === undefined && flag) {
      flag = false;
      setLoader(false);
      setError(() => 'Please fill all required fields');
    }

    if (data.unselectedIconSVG === undefined && flag) {
      flag = false;
      setLoader(false);
      setError(() => 'Please fill all required fields');
    }
    if (typeof (data.selectedIcon) !== 'string' && flag) {
      if (data.selectedIcon[0].size >= FILE_SIZE) {
        flag = false;
        setLoader(false);
        setError(() => 'Selected Icon size must be less than 50 Mb');
      } else if (!SUPPORTED_FORMATS.includes(data.selectedIcon[0].type)) {
        flag = false;
        setLoader(false);
        setError(() => 'Selected Icon must be of type png/jpg');
      }
    }
    if (typeof (data.unselectedIcon) !== 'string' && flag) {
      if (data.unselectedIcon[0].size >= FILE_SIZE) {
        flag = false;
        setLoader(false);
        setError(() => 'Un-SelectedIcon Icon size must be less than 50 Mb');
      } else if (!SUPPORTED_FORMATS.includes(data.unselectedIcon[0].type)) {
        flag = false;
        setLoader(false);
        setError(() => 'Un-Selected Icon must be of type png/jpg');
      }
    }

    if (flag) {
      if (typeof (data.unselectedIcon) !== 'string' && data.unselectedIcon !== null) {
        const form = new FormData();
        form.append('file', data.unselectedIcon[0]);
        unSelectedImageS3Url = await DomesManagementService.uploadpicture(form);
      }
      if (typeof (data.selectedIcon) !== 'string' && data.selectedIcon !== null) {
        const form = new FormData();
        form.append('file', data.selectedIcon[0]);
        selectedImageS3Url = await DomesManagementService.uploadpicture(form);
      }

      if (typeof (data.selectedIconSVG) !== 'string' && data.selectedIcon !== null) {
        const form = new FormData();
        form.append('file', data.selectedIconSVG[0]);
        selectedImageSVGS3Url = await DomesManagementService.uploadpicture(form);
      }

      if (typeof (data.unselectedIconSVG) !== 'string' && data.selectedIcon !== null) {
        const form = new FormData();
        form.append('file', data.unselectedIconSVG[0]);
        unSelectedImageSVGS3Url = await DomesManagementService.uploadpicture(form);
      }
      DomesManagementService.editDetails({
        categoryId: id,
        name: data.domeName,
        description: data.description,
        selectedImage: selectedImageS3Url === '' ? data.selectedIcon : selectedImageS3Url.data.data.url,
        nonSelectedImage: unSelectedImageS3Url === '' ? data.unselectedIcon : unSelectedImageS3Url.data.data.url,
        selectedImageSvg: selectedImageSVGS3Url === '' ? data.selectedImageSVGS3Url : selectedImageSVGS3Url.data.data.url,
        nonSelectedImageSvg: unSelectedImageSVGS3Url === '' ? data.unSelectedImageSVGS3Url : unSelectedImageSVGS3Url.data.data.url,
        status: 'unblocked',
      }).then(() => {
        setLoader(false);
        history.push('/domesmanagement');
      }).catch((err) => {
        setLoader(false);
        setError(err.response.data.message);
      });
    }
  };

  return (
    <>
      {!editDome ? (
        <AddDomesForm
          handleSubmit={handleSubmitForm}
          handleSaveAsDraft={handleSaveAsDraftAddomes}
          loader={loader}
          error={error}
        />
      ) : (
        <>
          <EditDomesForm
            handleSubmit={handleSubmitFormEdit}
            handleSaveAsDraft={handleSaveAsDraft}
            loader={loader}
            id={id}
            error={error}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  routing: state.routing,
});
const mapDispatchToProps = () => ({});

const ManageDomes = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageDomesComponent);

export default ManageDomes;
