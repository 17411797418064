import React from 'react';
import classNames from 'classnames';
import errorImg from '../../assets/svg/error.svg';

import scss from './ValidationError.module.scss';

interface Props {
  rootClassName?: string,
  className?: string,
  error: string | undefined
}
/**
 * This component can be used to show validation errors next to form
 * input fields. The component takes the form Field component
 * `meta` object as a prop and infers if an error message should be
 * shown.
 */
const ValidationError = (props: Props) => {
  const { rootClassName, className, error } = props;
  const classes = classNames(rootClassName || scss.root, className, scss.errorMessage);
  return error ? (
    <div className={classes}>
      <div className={scss.errorImg}>
        <img src={errorImg} alt="error" width="16" />
      </div>
      <p>{error}</p>
    </div>
  ) : null;
};

ValidationError.defaultProps = { rootClassName: null, className: null };

export default ValidationError;
