import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
// import MuiAlert from '@material-ui/core/AppBar';

// const Alert = React.forwardRef<HTMLDivElement, any>((
//   props,
//   ref,
// ) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

interface Props{
    message: string,
    handleCloseMethod: any,
}

const ToastBar: React.FC<Props> = (props: Props) => {
  const { message, handleCloseMethod } = props;
  const [state, setState] = React.useState<any>({
    open: true,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
    handleCloseMethod();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        autoHideDuration={6000}
      />
    </div>
  );
};

export default ToastBar;
