import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { ParticipantDetail } from '../modal';

export default function reducer(state: ParticipantDetail = new ParticipantDetail(),
  action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.PARTICPANT_LIST_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.PARTICPANT_LIST_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.PARTICPANT_LIST_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
