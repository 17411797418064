/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table, TableBody, TableCell, TableHead, TableRow, TextField,
} from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { fetchMasterCondition } from 'actions';
import { CircularProgressLoader, ValidationError } from 'components';
import scss from './mastercondition.module.scss';
import saveIcon from '../../assets/svg/save.svg';
import editIcon from '../../assets/svg/edit.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import blockIcon from '../../assets/svg/block.svg';
import unblockIcon from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  masterconditionmanagement: any;
  fetchMasterConditionList: any;
}

const MasterConditionManagementComponent: React.FC<Props> = (props: Props) => {
  const { masterconditionmanagement, fetchMasterConditionList } = props;
  const [editableRow, setEditableRow] = React.useState<any>({
    id: null,
    age: '',
    existance: '',
    condition: '',
  });
  const [showModal, setShowModal] = React.useState(false);
  const [idx, setIdx] = React.useState<any>();
  const [validation, setValidation] = React.useState<any>([{}]);
  const [validationexiting, setValidationExisting] = React.useState<any>([{}]);
  const [edit, setEdit] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deletePrice, setDeletePrice] = React.useState<any>({
    show: false,
    id: null,
  });
  const [block, setBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblock, setUnBlock] = React.useState<any>({
    show: false,
    id: null,
  });
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    // setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        // setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        // setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        // setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case 'edit':
        // history.push(`/subdomesmanagement/editSubDomes/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const actionButtons = (action: any, idS: any) => (
    <>
      <img
        src={deleteIcon}
        role="presentation"
        alt="block"
        width="35"
        title="Delete"
        height="35"
        onClick={() => handleBlockUnblock('delete', idS)}
      />
      {action === 'publish' || action === 'unblocked' ? (
        <img
          src={blockIcon}
          role="presentation"
          alt="block"
          width="35"
          title="Block"
          height="35"
          onClick={() => handleBlockUnblock('blocked', idS)}
        />
      ) : (
        <img
          src={unblockIcon}
          role="presentation"
          alt="unblock"
          width="35"
          title="Unblock"
          height="35"
          onClick={() => handleBlockUnblock('unblocked', idS)}
        />
      )}
    </>
  );

  React.useEffect(() => {
    fetchMasterConditionList();
  }, [fetchMasterConditionList]);
  return (
    <>
      <div className={scss.page_header}>
        <div className={classNames(commonStyles.buttonWrapper, scss.buttonWrapper)}>
          <h2>Master Condition Management</h2>
        </div>
      </div>
      <hr />
      <div>
        <p>Rules for Campaigns/Events/Channels</p>
      </div>
      <div className={commonStyles.dataTableCover}>
        <Table
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                S.No
              </TableCell>
              <TableCell>
                Description
              </TableCell>
              <TableCell>
                Conditions
              </TableCell>
              <TableCell>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {masterconditionmanagement.loadingInProgress || masterconditionmanagement.data === null
              ? (
                <TableRow>
                  <TableCell>
                    <CircularProgressLoader />
                  </TableCell>
                </TableRow>
              )
              : (
                <>
                  <TableRow>
                    <TableCell>
                      1
                    </TableCell>
                    <TableCell>
                      Minimum Age
                    </TableCell>
                    <TableCell>
                      {idx === 1 ? (
                        <>
                          <TextField
                            name="age"
                            type="number"
                            value={editableRow.value}
                            onChange={(e: any) => {
                              setEditableRow({ ...editableRow, value: e.target.value });
                            }}
                          />
                          {validationexiting[idx] !== undefined
                            && validationexiting[idx].description !== undefined
                            && validationexiting[idx].description.length > 0
                            && <ValidationError error={validationexiting[idx].description} />}
                        </>
                      ) : (
                        <div className={commonStyles.imagewrapper}>
                          <p>{masterconditionmanagement.data.minimumAge}</p>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className={commonStyles.actionBtnsWrapper}>
                        {edit.show && edit.id === 1 ? (
                          <div>
                            <img
                              src={saveIcon}
                              role="presentation"
                              alt="save"
                              width="35"
                              title="Save"
                              height="35"
                              onClick={() => {
                                let flag = true;
                                const valida: any = {};
                                if (editableRow.title === undefined
                                  || editableRow.title === '') {
                                  flag = false;
                                  valida.valuefrom = 'Please enter the required field';
                                }
                                if (editableRow.description === undefined
                                  || editableRow.description === '') {
                                  flag = false;
                                  valida.valueto = 'Please enter the required field';
                                }
                                if (editableRow.reasons === undefined
                                  || editableRow.reasons.length === 0) {
                                  flag = false;
                                  valida.percent = 'Please enter the required field';
                                }

                                if (flag) {
                                  setShowModal(true);
                                  setEdit({
                                    show: true,
                                    id: 1,
                                  });
                                  setIdx(1);
                                } else {
                                  const valid = [...validationexiting];
                                  valid[1] = valida;
                                  setValidationExisting(valid);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={editIcon}
                              role="presentation"
                              alt="edit"
                              width="35"
                              title="Edit"
                              height="35"
                              onClick={() => {
                                setEdit({
                                  ...edit,
                                  id: 1,
                                  show: true,
                                });
                                setIdx(1);
                                setEditableRow({
                                  id: masterconditionmanagement.data._id,
                                  value: masterconditionmanagement.data.minimumAge,
                                });
                              }}
                            />
                          </div>
                        )}
                        <div>
                          <img
                            src={deleteIcon}
                            role="presentation"
                            alt="delete"
                            width="35"
                            title="Delete"
                            height="35"
                            onClick={() => {
                              setShowModal(true);
                              setEdit({
                                show: false,
                                id: null,
                              });
                              setIdx(0);
                              // setDeletePrice({
                              //   ...deletePrice,
                              //   show: true,
                              //   id: item._id,
                              // });
                            }}
                          />
                        </div>
                        <div>
                          {idx === 1 ? (
                            <img
                              src={blockIcon}
                              role="presentation"
                              alt="block"
                              width="35"
                              title="block"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                // setBlock({
                                //   ...block,
                                //   show: true,
                                //   id: item._id,
                                // });
                              }}
                            />
                          ) : (
                            <img
                              src={unblockIcon}
                              role="presentation"
                              alt="unblock"
                              width="35"
                              title="Unblock"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setUnBlock({
                                  ...block,
                                  show: true,
                                  // id: item._id,
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      2
                    </TableCell>
                    <TableCell>
                      User’s existence in the platform
                    </TableCell>
                    <TableCell>
                      {idx === 2 ? (
                        <>
                          <TextField
                            name="description"
                            type="number"
                            value={editableRow.value}
                            onChange={(e: any) => {
                              setEditableRow({ ...editableRow, value: e.target.value });
                            }}
                          />
                          {validationexiting[idx] !== undefined
                            && validationexiting[idx].description !== undefined
                            && validationexiting[idx].description.length > 0
                            && <ValidationError error={validationexiting[idx].description} />}
                        </>
                      ) : (
                        <div className={commonStyles.imagewrapper}>
                          <p>{masterconditionmanagement.data.sessionDuration}</p>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className={commonStyles.actionBtnsWrapper}>
                        {edit.show && edit.id === 2 ? (
                          <div>
                            <img
                              src={saveIcon}
                              role="presentation"
                              alt="save"
                              width="35"
                              title="Save"
                              height="35"
                              onClick={() => {
                                let flag = true;
                                const valida: any = {};
                                if (editableRow.title === undefined
                                  || editableRow.title === '') {
                                  flag = false;
                                  valida.valuefrom = 'Please enter the required field';
                                }
                                if (editableRow.description === undefined
                                  || editableRow.description === '') {
                                  flag = false;
                                  valida.valueto = 'Please enter the required field';
                                }
                                if (editableRow.reasons === undefined
                                  || editableRow.reasons.length === 0) {
                                  flag = false;
                                  valida.percent = 'Please enter the required field';
                                }

                                if (flag) {
                                  setShowModal(true);
                                  setEdit({
                                    show: true,
                                    id: 2,
                                  });
                                  setIdx(2);
                                } else {
                                  const valid = [...validationexiting];
                                  valid[1] = valida;
                                  setValidationExisting(valid);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={editIcon}
                              role="presentation"
                              alt="edit"
                              width="35"
                              title="Edit"
                              height="35"
                              onClick={() => {
                                setEdit({
                                  ...edit,
                                  id: 2,
                                  show: true,
                                });
                                setIdx(2);
                                setEditableRow({
                                  id: masterconditionmanagement.data._id,
                                  value: masterconditionmanagement.data.sessionDuration,
                                });
                              }}
                            />
                          </div>
                        )}
                        <div>
                          <img
                            src={deleteIcon}
                            role="presentation"
                            alt="delete"
                            width="35"
                            title="Delete"
                            height="35"
                            onClick={() => {
                              setShowModal(true);
                              setEdit({
                                show: false,
                                id: null,
                              });
                              setIdx(0);
                              // setDeletePrice({
                              //   ...deletePrice,
                              //   show: true,
                              //   id: item._id,
                              // });
                            }}
                          />
                        </div>
                        <div>
                          {idx === 2 ? (
                            <img
                              src={blockIcon}
                              role="presentation"
                              alt="block"
                              width="35"
                              title="block"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                // setBlock({
                                //   ...block,
                                //   show: true,
                                //   id: item._id,
                                // });
                              }}
                            />
                          ) : (
                            <img
                              src={unblockIcon}
                              role="presentation"
                              alt="unblock"
                              width="35"
                              title="Unblock"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setUnBlock({
                                  ...block,
                                  show: true,
                                  // id: item._id,
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      3
                    </TableCell>
                    <TableCell>
                      Having a premium subscription
                    </TableCell>
                    <TableCell>
                      {idx === 3 ? (
                        <>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Age</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={editableRow.value}
                              label="Age"
                              onChange={(e: any) => setEditableRow({ ...editableRow, value: e.target.value })}
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                          {validationexiting[idx] !== undefined
                            && validationexiting[idx].description !== undefined
                            && validationexiting[idx].description.length > 0
                            && <ValidationError error={validationexiting[idx].description} />}
                        </>
                      ) : (
                        <div className={commonStyles.imagewrapper}>
                          <p>{masterconditionmanagement.data.havingPremiumSubscription ? 'Yes' : 'No'}</p>
                          {/* <p>{item.description}</p> */}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className={commonStyles.actionBtnsWrapper}>
                        {edit.show && edit.id === 3 ? (
                          <div>
                            <img
                              src={saveIcon}
                              role="presentation"
                              alt="save"
                              width="35"
                              title="Save"
                              height="35"
                              onClick={() => {
                                let flag = true;
                                const valida: any = {};
                                if (editableRow.title === undefined
                                  || editableRow.title === '') {
                                  flag = false;
                                  valida.valuefrom = 'Please enter the required field';
                                }
                                if (editableRow.description === undefined
                                  || editableRow.description === '') {
                                  flag = false;
                                  valida.valueto = 'Please enter the required field';
                                }
                                if (editableRow.reasons === undefined
                                  || editableRow.reasons.length === 0) {
                                  flag = false;
                                  valida.percent = 'Please enter the required field';
                                }

                                if (flag) {
                                  setShowModal(true);
                                  setEdit({
                                    show: true,
                                    id: 3,
                                  });
                                  setIdx(3);
                                } else {
                                  const valid = [...validationexiting];
                                  valid[3] = valida;
                                  setValidationExisting(valid);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={editIcon}
                              role="presentation"
                              alt="edit"
                              width="35"
                              title="Edit"
                              height="35"
                              onClick={() => {
                                setEdit({
                                  ...edit,
                                  id: 3,
                                  show: true,
                                });
                                setIdx(3);
                                setEditableRow({
                                  id: masterconditionmanagement.data._id,
                                  value: masterconditionmanagement.data.sessionDuration ? 'true' : 'false',
                                });
                              }}
                            />
                          </div>
                        )}
                        <div>
                          <img
                            src={deleteIcon}
                            role="presentation"
                            alt="delete"
                            width="35"
                            title="Delete"
                            height="35"
                            onClick={() => {
                              setShowModal(true);
                              setEdit({
                                show: false,
                                id: null,
                              });
                              setIdx(0);
                              // setDeletePrice({
                              //   ...deletePrice,
                              //   show: true,
                              //   id: item._id,
                              // });
                            }}
                          />
                        </div>
                        <div>
                          {idx === 3 ? (
                            <img
                              src={blockIcon}
                              role="presentation"
                              alt="block"
                              width="35"
                              title="block"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                // setBlock({
                                //   ...block,
                                //   show: true,
                                //   id: item._id,
                                // });
                              }}
                            />
                          ) : (
                            <img
                              src={unblockIcon}
                              role="presentation"
                              alt="unblock"
                              width="35"
                              title="Unblock"
                              height="35"
                              onClick={() => {
                                setShowModal(true);
                                setEdit({
                                  show: false,
                                  id: null,
                                });
                                setIdx(0);
                                setUnBlock({
                                  ...block,
                                  show: true,
                                  // id: item._id,
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  masterconditionmanagement: state.masterconditionmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchMasterConditionList: () => dispatch(fetchMasterCondition()),
});

const MasterConditionManagement = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(MasterConditionManagementComponent);

export default MasterConditionManagement;
