import api from 'api';

export class MyProfileService {
  static async getMyProfileData() {
    return api.getApiCall('/api/v1/admins/profile');
  }

  static async changePassword(payload: any) {
    return api.putApiCall('/api/v1/admins/change-password', { data: payload.data });
  }

  static async updateProfile(payload: any) {
    return api.putApiCall('/api/v1/admins/profile', payload);
  }

  static async uploadpicture(payload: any) {
    return api.postApiCall('/api/v1/admins/upload-files', payload);
  }

  static async sendOTP(payload: any) {
    return api.putApiCall('/api/v1/admins/send-otp', payload);
  }

  static async verifyOTP(payload: any) {
    return api.putApiCall('/api/v1/admins/verify-otp', payload);
  }
}
