/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import avatcoin from 'assets/svg/avatcoin.svg';
import avatcoinsilver from 'assets/images/silvercoin.png';
import {
  Button,
  CircularProgressLoader,
  Modal,
  RefundTable,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  Accordion,
  PayementNotification,
  Overlay,
  ToastBar,
} from '..';
import { getPaymentInfo } from '../../actions';
import exportImg from '../../assets/svg/export.svg';
import { getDateAndTime } from '../../utils/dateMethods';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  paymentinfo: any;
  id: any;
  fetchPaymentInfo: any;
  intl: any;
}

const PaymentInfoComponent: React.FC<Props> = (props: Props) => {
  const {
    paymentinfo,
    id,
    fetchPaymentInfo,
    intl,
  } = props;

  React.useEffect(() => {
    fetchPaymentInfo(id);
  }, [fetchPaymentInfo, id]);

  const [showModal, setShowModal] = React.useState(false);
  const [overlay, setOverlay] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  const [paymentNotification, setPaymentNotification] = React.useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    setPaymentNotification(false);
  };
  return (
    <>
      {overlay && <Overlay />}
      {notification.message.length > 0 && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {paymentNotification && (
          <div className={commonStyles.modal_container}>
            <PayementNotification
              transactionId={id}
              id={paymentinfo.data === null ? '' : paymentinfo.data.receiverUserId._id}
              handleModalClose={handleModalClose}
              setShowOverlay={setOverlay}
              setNotification={setNotification}
            />
          </div>
        )}
      </Modal>
      {
        paymentinfo.data === null || paymentinfo.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.headerActions}>
                  <div>
                    <Button
                      className={commonStyles.button}
                      onClick={() => {
                        setShowModal(true);
                        setPaymentNotification(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'ChannelInfo.label.SendNotification' })}
                    </Button>
                  </div>
                  <div className={commonStyles.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                  </div>
                </div>
                <div className={commonStyles.userInformatinc}>
                  <div className={commonStyles.userInfoinner}>
                    <div>
                      <h2>{paymentinfo.data.channelName}</h2>
                      <h3>
                        Transaction ID:
                        {` ${paymentinfo.data.transactionId}`}
                      </h3>
                      {paymentinfo.data?.sourceUserId === undefined || paymentinfo.data?.sourceUserId === null ? ''
                        : (
                          <h3>
                            Username :
                            {paymentinfo.data?.sourceUserId.username}
                          </h3>
                        )}
                    </div>
                  </div>
                </div>

                <div className={commonStyles.postDetails}>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Order ID
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.orderId}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Subscription ID
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.subscriptionPlanId?._id}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Purchased Item
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.itemType}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Item type
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.itemType}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Date & Time of purchase
                      </p>
                      <p className={commonStyles.count_backround}>
                        {getDateAndTime(paymentinfo.data.createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Total Price
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.goldPrice}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Transaction status
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.receiverUserPaymentStatus}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Discounted price
                      </p>
                      <div className={commonStyles.c_price}>
                        <img src={avatcoin} alt="avatcoin" />
                        <span>{paymentinfo.data.discountedPrice.gold}</span>
                        <span className={commonStyles.separator}>+</span>
                        <img src={avatcoinsilver} alt="avatcoin" />
                        <span>{paymentinfo.data.discountedPrice.silver}</span>
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Amount paid
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.amount}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Receiver username
                      </p>
                      <p className={commonStyles.count_backround}>
                        {paymentinfo.data.receiverUserId.username}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Refund Details">
                <RefundTable id={undefined} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { paymentinfo } = state;
  return {
    paymentinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPaymentInfo: (payload: any) => dispatch(getPaymentInfo(payload)),
});

const PaymentInfo = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PaymentInfoComponent);

export default PaymentInfo;
