import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateFormatting } from 'utils/dateMethods';
import {
  AccessControl,
  Button,
  DomesManagementBlocked,
  DomesManagementDelete,
  hasAccess,
  Modal,
  Overlay,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ToastBar,
} from 'components';
import { DomesManagementService } from 'services';
import { domesManagement } from 'actions';
import { DomesManagementFilter, EditRankOfDomes } from 'forms';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import avatar from 'assets/svg/image-placeholder.png';
import DataTable from '../Datatable';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import editIcon from '../../assets/svg/edit.svg';
import deleteIcon from '../../assets/svg/delete.svg';

interface Props {
  domeslist: any;
  fetchDomesList: any;
}

const DomesManagement: React.FC<Props> = (props: Props) => {
  const {
    domeslist,
    fetchDomesList,
  } = props;
  const history = useHistory();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    status: '',
    search: '',
    isSortByCategoryName: '',
    isSortByCreated: '',
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [editRankError, setEditRankError] = React.useState('');
  const [editRankErrorLoading, setEditRankErrorLoading] = React.useState(false);
  const [showEditRank, setShowEditRank] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  React.useEffect(() => {
    fetchDomesList(filter);
  }, [fetchDomesList, filter]);
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case 'edit':
        history.push(`/domesmanagement/editDomes/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const actionButtons = (action: any, idS: any) => (
    <div className={commonStyles.actionBtnsWrapper}>
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          <img
            src={editIcon}
            role="presentation"
            alt="edit"
            width="35"
            title="Edit"
            height="35"
            onClick={() => handleBlockUnblock('edit', idS)}
          />
        </div>
      </AccessControl>
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.DELETE}
      >
        <div>
          <img
            src={deleteIcon}
            role="presentation"
            alt="delete"
            width="35"
            title="Delete"
            height="35"
            onClick={() => handleBlockUnblock('delete', idS)}
          />
        </div>
      </AccessControl>
      <AccessControl
        permissionKey={PERMISSION_KEYS.CATERGORIES}
        accessType={PERMISSION_TYPES.EDIT}
      >
        <div>
          {action === 'pending' ? null : (action === 'unblocked' ? (
            <img
              src={blockIcon}
              role="presentation"
              alt="block"
              width="35"
              title="Block"
              height="35"
              onClick={() => handleBlockUnblock('blocked', idS)}
            />
          ) : (
            <img
              src={unblock}
              role="presentation"
              alt="unblock"
              width="35"
              title="Unblock"
              height="35"
              onClick={() => handleBlockUnblock('unblocked', idS)}
            />
          ))}
        </div>
      </AccessControl>
    </div>
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = domeslist.data ? domeslist.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const hideActions = () => hasAccess(PERMISSION_KEYS.CATERGORIES, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.CATERGORIES, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.CATERGORIES, PERMISSION_TYPES.DELETE);

  const handleSubmitChannelFilter = (data: any) => {
    setError(null);
    if (data.search !== '' || data.userType !== '') {
      setFilter({
        ...filter,
        search: data.search.label,
        status: data.userType,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowEditRank(false);
    setBlocked(false);
    setEditRankError('');
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  function getStatus(status: string) {
    switch (status) {
      case 'unblocked':
        return 'Active';
      case 'pending':
        return 'Draft';
      default:
        return 'Blocked';
    }
  }

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      icon: data.selectedImage === '' || data.selectedImage === null || data.selectedImage === undefined ? <img src={avatar} alt="x" /> : <img src={data.selectedImage} alt="x" width={50} height={50} />,
      name: data.name,
      description: <div title={data.description}>{data.description.length > 20 ? `${data.description.substring(0, 20)}...` : data.description}</div>,
      rank: data.rank,
      status: getStatus(data.status),
      createdAt: dateFormatting(data.createdAt),
      ...(hideActions() && {
        action: actionButtons(data.status, idS),
      }),
    };
  }

  const rows: any = [];
  if (domeslist.data !== null && domeslist.data) {
    domeslist.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = hideActions() ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'icon', label: 'Icon', sort: false,
    },
    {
      id: 'name', label: 'Name', sort: true,
    },
    {
      id: 'description', label: 'Description', sort: false,
    },
    {
      id: 'rank', label: 'Rank', sort: false,
    },
    {
      id: 'status', label: 'Status', sort: false,
    },
    {
      id: 'createdAt', label: 'Added on', sort: true,
    },
    {
      id: 'action', label: 'Action', sort: false,
    },
  ] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'icon', label: 'Icon', sort: false,
    },
    {
      id: 'name', label: 'Name', sort: true,
    },
    {
      id: 'description', label: 'Description', sort: false,
    },
    {
      id: 'rank', label: 'Rank', sort: false,
    },
    {
      id: 'status', label: 'Status', sort: false,
    },
    {
      id: 'createdAt', label: 'Added on', sort: true,
    },
  ];

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };
  const handleEditRankOfDomes = (values: any) => {
    const data: any = [];
    setEditRankErrorLoading(true);
    Object.keys(values).map((ele) => data.push({ categoryId: ele, rank: values[ele] }));
    let ValidationError = '';
    data.forEach((ele: any) => {
      if (ele.rank === '' || ele.rank === null) {
        ValidationError = 'Please enter the required fields in numbers !';
      }
    });
    if (ValidationError.length === 0) {
      DomesManagementService.editAllDomes(data)
        .then(() => {
          setNotification({
            show: true,
            message: 'Successfully Updated Domes Rank !!',
          });
          setEditRankErrorLoading(false);
          setFilter({ ...filter, page: 0 });
          handleModalClose();
        });
    } else {
      setEditRankError(ValidationError);
      setEditRankErrorLoading(false);
    }
  };
  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Name: ele.name,
            Description: ele.description.props.title,
            Rank: ele.rank,
            Status: ele.status,
            CreatedAt: ele.createdAt,
            Icon: ele.icon.props.src,
          });
        });
        exportToExcel(selectedRowDataModified, 'DomesManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        DomesManagementService.exportUserListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Name: ele.name,
            Description: ele.description.props.title,
            Rank: ele.rank,
            Status: ele.status,
            CreatedAt: ele.createdAt,
            Icon: ele.icon.props.src,
          });
        });
        exportToCSV(selectedRowDataModified, 'DomesManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        DomesManagementService.exportUserListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      status: '',
      search: '',
      isSortByCategoryName: '',
      isSortByCreated: '',
    });
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          showEditRank && (
            <EditRankOfDomes
              handleFormSubmit={handleEditRankOfDomes}
              handleClose={handleModalClose}
              error={editRankError}
              loading={editRankErrorLoading}
            />
          )
        }
        {
          deleted.show && (
            <DomesManagementDelete
              handleModalClose={handleModalClose}
              setShowOverlay={setShowOverlay}
              fetchDomesList={() => setFilter({ ...filter })}
              id={deleted.id}
            />
          )
        }
        {
          blocked.show && (
            <DomesManagementBlocked
              setShowOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchDomesList={() => setFilter({ ...filter })}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <DomesManagementBlocked
              setShowOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchDomesList={() => setFilter({ ...filter })}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <div className={commonStyles.topButton}>
          <AccessControl
            permissionKey={PERMISSION_KEYS.CATERGORIES}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <Button
              onClick={() => {
                setShowModal(true);
                setShowEditRank(true);
              }}
            >
              Edit Rank
            </Button>
          </AccessControl>
          <AccessControl
            permissionKey={PERMISSION_KEYS.CATERGORIES}
            accessType={PERMISSION_TYPES.ADD}
          >
            <Button
              onClick={() => history.push('/domesmanagement/addDomes')}
            >
              Add New Dome
            </Button>
          </AccessControl>
        </div>
        <DomesManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitChannelFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={domeslist.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { domesmanagement } = state;
  return {
    domeslist: domesmanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchDomesList: (payload: any) => dispatch(domesManagement(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomesManagement);
