import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { RoutingModal } from '../modal';

export default function reducer(state: RoutingModal = new RoutingModal(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.LOCATION_CHANGED:
      return {
        ...state,
        currentLocation: payload.location,
        currentCanonicalUrl: payload.canonicalUrl,
      };

    default:
      return state;
  }
}
