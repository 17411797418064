import { all, fork } from 'redux-saga/effects';
import PostCreated from './postcreated';
import auth from './auth';
import UserManagement from './usermanagement';
import UserInfo from './userinfo';
import ChannelManagement from './channelmanagement';
import ChannelInfo from './channelInfo';
import UserProfile from './userProfile';
import Domesmanagement from './domesmanagement';
import SubDomesmanagement from './submanagement';
import ContentManagement from './contentmanagement';
import FAQs from './faqs';
import RolesManagement from './rolesmanagement';
import LoginDetails from './logindetails';
import SubAdminManagement from './subadminmanagement';
import CreditActivity from './creditactivity';
import DebitActivity from './debitactivity';
import ReportedUserManagement from './reportedusermanagement';
import NotesDetails from './notesdetails';
import UserReportDetail from './userreportdetail';
import PostReportDetail from './postreportdetail';
import ChannelReportDetail from './channelreportdetail';
import NotificationDetail from './notificationdetail';
import PriceManagementDetail from './pricemanagement';
import Notification from './notification';
import NotificationManagement from './notificationmanagement';
import MasterReasonManagement from './masterreasonmanagement';
import MasterConditionManagement from './masterconditionmanagement';
import FeedbackManagement from './feedback';
import ManageVersion from './manageversion';
import LiveStream from './livestreaming';
import LiveStreamInfo from './livestreaminfo';
import ParticipantDetails from './participantdetails';
import EventDetails from './eventdetail';
import NotificationLiveStream from './notificationlive';
import PostDetail from './postdetail';
import PostDetailReportedUserInfoService from './postdetailreporteduserinfo';
import SupportManagementSaga from './support';
import SupportInfoSaga from './supportInfo';
import PaymentManagement from './paymentmanagement';
import PaymentInfo from './paymentinfo';
import UserInfoNotificationDetail from './userinfonotificationdetail';
import ChannelInfoNotification from './channelnotificationdetail';
import CoinActivity from './coinactivity';
import eventreportdetail from './eventreportdetail';
import adminnotification from './adminnotificationsaga';
/**
 * rootSaga
 */
export default function* root() {
  yield all([
    fork(auth),
    fork(UserManagement),
    fork(UserInfo),
    fork(ChannelManagement),
    fork(ChannelInfo),
    fork(UserProfile),
    fork(Domesmanagement),
    fork(SubDomesmanagement),
    fork(ContentManagement),
    fork(FAQs),
    fork(RolesManagement),
    fork(PostCreated),
    fork(LoginDetails),
    fork(SubAdminManagement),
    fork(CreditActivity),
    fork(DebitActivity),
    fork(ReportedUserManagement),
    fork(NotesDetails),
    fork(UserReportDetail),
    fork(PostReportDetail),
    fork(ChannelReportDetail),
    fork(NotificationDetail),
    fork(PriceManagementDetail),
    fork(Notification),
    fork(NotificationManagement),
    fork(MasterReasonManagement),
    fork(MasterReasonManagement),
    fork(MasterConditionManagement),
    fork(FeedbackManagement),
    fork(ManageVersion),
    fork(LiveStream),
    fork(LiveStreamInfo),
    fork(ParticipantDetails),
    fork(EventDetails),
    fork(NotificationLiveStream),
    fork(PostDetail),
    fork(PostDetailReportedUserInfoService),
    fork(SupportManagementSaga),
    fork(SupportInfoSaga),
    fork(PaymentManagement),
    fork(PaymentInfo),
    fork(UserInfoNotificationDetail),
    fork(ChannelInfoNotification),
    fork(CoinActivity),
    fork(eventreportdetail),
    fork(adminnotification),
  ]);
}
