import React from 'react';
import { useForm } from 'react-hook-form';
import searchBarApis from 'utils/searchBarApis';
import {
  Button, FieldSelect, ValidationError, FieldSearchBar, CircularProgressLoader, AccessControl, PERMISSION_KEYS, PERMISSION_TYPES,
} from '../../components';
import { injectIntl } from '../../utils/reactIntl';
import DomesTypes from './SubDomesManagement.json';
import exp from '../../assets/svg/export.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleResetSorts: any;
  intl: any,
  error: any,
  handleExport: any,
  loaderExport: boolean,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const SubDomesManagementFilterForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    intl,
    error,
    handleResetSorts,
    handleExport,
    loaderExport,
  } = props;
  const [toggle, setToggle] = React.useState(false);
  const formProps = useForm();
  const handleReset = () => {
    formProps.reset({
      search: '',
      userType: '',
    });
    handleResetSorts();
  };
  return (
    <form className={commonStyles.filterForm} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div>
            <div className={commonStyles.userType}>
              <FieldSelect
                id="userType"
                name="userType"
                variant={false}
                label={intl.formatMessage({ id: 'SubDomesManagementFilter.subdomes.status' })}
                formProps={formProps}
                options={DomesTypes}
              />
            </div>
            <div className={commonStyles.searchField}>
              <FieldSearchBar
                searchURL={searchBarApis.subDomesManagement}
                formProps={formProps}
                name="search"
                label={intl.formatMessage({ id: 'SubDomesManagementFilter.subdomes.name' })}
                id="search"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.SUB_CATEGORIES}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className={commonStyles.userRightExport}>
              {!loaderExport ? (
                <>
                  <span>
                    <img
                      src={exp}
                      alt="export"
                      role="presentation"
                      onClick={() => setToggle((prevState: any) => !prevState)}
                    />
                  </span>
                  {toggle && (
                    <ul className={commonStyles.userrigthToggle}>
                      <li
                        role="presentation"
                        onClick={() => handleExport('XLSX')}
                      >
                        {intl.formatMessage({ id: 'SubDomesManagementFilter.export.xlsx' })}
                      </li>
                      <li
                        role="presentation"
                        onClick={() => handleExport('CSV')}
                      >
                        {intl.formatMessage({ id: 'SubDomesManagementFilter.export.csv' })}
                      </li>
                    </ul>
                  )}
                </>
              ) : <div className={commonStyles.exportProgressBar}><CircularProgressLoader /></div>}
            </div>
          </AccessControl>
        </div>
        <div className={commonStyles.filterOptions}>
          <div className={commonStyles.buttonWrapper}>
            <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
              {intl.formatMessage({ id: 'SubDomesManagementFilter.apply' })}
            </Button>
            <Button onClick={handleReset} className={commonStyles.button}>
              {intl.formatMessage({ id: 'SubDomesManagementFilter.reset' })}
            </Button>
          </div>
        </div>
        {error && (
          <ValidationError
            className={commonStyles.validation_error}
            error={error}
          />
        )}
      </div>
    </form>
  );
};

export default injectIntl(SubDomesManagementFilterForm);
