import React from 'react';
import { connect } from 'react-redux';
import { fetchPostCreated } from 'actions';
import classNames from 'classnames';
import DataTable from '../Datatable';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import accordion from '../Accordion/accordion.module.scss';

interface Props {
  id: any;
  postcreated: any;
  roleList: any;
}

const RefundTable: React.FC<Props> = (props: Props) => {
  const {
    postcreated,
    roleList,
    id,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  React.useEffect(() => {
    roleList(filter);
  }, [roleList, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = postcreated.data ? postcreated.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const headCellsPostsCreated: any = [
    {
      id: 'sno', numeric: true, disablePadding: true, label: 'S.No',
    },
    {
      id: 'description', numeric: false, disablePadding: false, label: 'Refund status',
    },
    {
      id: 'postedon', numeric: false, disablePadding: false, label: 'Reason of refund request',
    },
    {
      id: 'domes', numeric: false, disablePadding: false, label: 'Date and time of request',
    },
    {
      id: 'action', numeric: false, disablePadding: false, label: 'View count in case of a service(video)',
    },
    {
      id: 'durarion', numeric: false, disablePadding: false, label: 'View duration',
    },
    {
      id: 'tduration', numeric: false, disablePadding: false, label: 'Total duration ',
    },
    {
      id: 'count', numeric: false, disablePadding: false, label: 'Action',
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'domes') {
      setFilter({
        ...filter,
        page: 0,
        isSortByParentCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCategoryName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <div className={classNames(commonStyles.dataTableCover, accordion.accordionTable)}>
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={[]}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={postcreated.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { postcreated } = state;
  return {
    postcreated,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  roleList: (payload: any) => dispatch(fetchPostCreated(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundTable);
