import api from '../api';

export class NotesDetailsService {
  static async getNotesDetailsService(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&userId=${id}`;
    return api.getApiCall(`/api/v1/admins/notes?${apiEndPoint}`);
  }
}
