import { TextField } from '@material-ui/core';
import { Button, ValidationError } from 'components';
import { stateToHTML } from 'draft-js-export-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { UserInfoService } from 'services';
import scss from './userinfosendnotification.module.scss';
import modalStyles from '../Modal/Modal.module.scss';

interface Props {
  id: string;
  handleModalClose: any;
  setShowOverlay: any;
  setNotification: any;
}

const UserInfoSendNotification: React.FC<Props> = (props: Props) => {
  const {
    id,
    handleModalClose,
    setShowOverlay,
    setNotification,
  } = props;
  const [showEmailNotification, setShowEmailNotification] = useState(false);
  const [message, setMessage] = useState('');
  const [sendNotification, setSendNotification] = useState(true);
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [showSMSNotification, setShowSMSNotification] = useState(false);
  const [showPushNotification, setShowPushNotification] = useState(false);
  const [emailTitle, setEmailTitle] = React.useState('');
  const [pushNotification, setPushNotification] = React.useState({
    title: '',
    message: '',
  });
  const [emailNotificationError, setEmailNotificationError] = React.useState({
    title: false,
    message: '',
  });
  const [smsNotificationError, setSMSNotificationError] = React.useState({
    message: '',
  });
  const [pushNotificationError, setPushNotificationError] = React.useState<any>({
    title: '',
    message: '',
  });
  return (
    <>
      {sendNotification && (
        <>
          <div className={scss.sendNotification}>
            <Button
              className={scss.buttoNot}
              onClick={() => {
                setShowEmailNotification(true);
                setSendNotification(false);
              }}
            >
              Email Notification
            </Button>
            <Button
              className={scss.buttoNot}
              onClick={() => {
                setShowSMSNotification(true);
                setSendNotification(false);
              }}
            >
              SMS Notification
            </Button>
            <Button
              className={scss.buttoNot}
              onClick={() => {
                setShowPushNotification(true);
                setSendNotification(false);
              }}
            >
              Push Notification
            </Button>
          </div>
        </>
      )}
      {
        showEmailNotification && (
          <div>
            <h2>Email Notification</h2>
            <TextField
              onChange={(e) => {
                setEmailTitle(e.target.value);
              }}
              id="emailTitle"
              label="*Subject of the Email"
              name="textField"
              placeholder="Email Title"
              variant="outlined"
              style={{ width: '100%' }}
            />
            {emailNotificationError.title && <ValidationError error="Email title can not be empty" />}
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setEditorState(e)}
              toolbar={{
                options: ['inline', 'textAlign'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline'],
                },
                textAlign: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['left', 'center', 'right', 'justify'],
                },
              }}
            />
            {emailNotificationError.message === 'empty'
              && <ValidationError error="Email content can not be empty" />}
            {emailNotificationError.message === 'greater'
              && <ValidationError error="Email content can not be greater than 2000 alphabets" />}
            <div className={modalStyles.modalButtonWrapper}>
              <Button
                className={scss.margin_right}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                className={scss.margin_right}
                onClick={() => {
                  if (emailTitle.length === 0) {
                    setEmailNotificationError({ ...emailNotificationError, title: true });
                  } else if (editorState.getCurrentContent().getPlainText().length === 0) {
                    setEmailNotificationError({ ...emailNotificationError, title: false, message: 'empty' });
                  } else if (editorState.getCurrentContent().getPlainText().length > 2000) {
                    setEmailNotificationError({ ...emailNotificationError, message: 'greater' });
                  } else {
                    setShowOverlay(true);
                    UserInfoService.sendEmail({
                      userId: id,
                      subject: emailTitle,
                      message: stateToHTML(editorState.getCurrentContent()),
                    }).then(() => {
                      setShowOverlay(false);
                      setNotification({
                        show: true,
                        message: 'Email sent successfully!!',
                      });
                      handleModalClose();
                    }).catch((err: any) => {
                      setNotification({
                        show: true,
                        message: err.response.data.message,
                      });
                    });
                  }
                }}
              >
                Send Notification
              </Button>
            </div>
          </div>
        )
      }
      {
        showPushNotification && (
          <div>
            <h2>Push Notification</h2>
            <TextField
              onChange={(e) => {
                setPushNotification({
                  ...pushNotification,
                  title: e.target.value,
                });
              }}
              id="emailTitle"
              label="*Subject of the Notification"
              name="textField"
              placeholder="Email Title"
              variant="outlined"
              style={{ width: '100%' }}
            />
            {pushNotificationError.title === 'empty'
              && <ValidationError error="Notification title cannot be empty" />}
            {pushNotificationError.title === 'greater'
              && <ValidationError error="Notification title cannot be greater than 50 characters" />}
            <textarea
              placeholder="Enter message"
              onChange={(e) => {
                setPushNotification({
                  ...pushNotification,
                  message: e.target.value,
                });
              }}
            />
            {pushNotificationError.message === 'empty'
              && <ValidationError error="Notification message cannot be empty" />}
            {pushNotificationError.message === 'greater'
              && <ValidationError error="Notification message cannot be greater than 150 characters" />}

            <div className={modalStyles.modalButtonWrapper}>
              <Button
                className={scss.margin_right}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                className={scss.margin_right}
                onClick={() => {
                  if (pushNotification.title === '' && pushNotification.message === '') {
                    setPushNotificationError({ ...pushNotificationError, message: 'empty', title: 'empty' });
                  } else if (pushNotification.title.length > 50 && pushNotification.message.length > 150) {
                    setPushNotificationError({ ...pushNotificationError, title: 'greater', message: 'greater' });
                  } else if (pushNotification.title === '') {
                    setPushNotificationError({ ...pushNotificationError, title: 'empty', message: '' });
                  } else if (pushNotification.message === '') {
                    setPushNotificationError({ ...pushNotificationError, message: 'empty', title: '' });
                  } else if (pushNotification.title.length > 50) {
                    setPushNotificationError({ ...pushNotificationError, title: 'greater', message: '' });
                  } else if (pushNotification.message.length > 150) {
                    setPushNotificationError({ ...pushNotificationError, title: '', message: 'greater' });
                  } else {
                    setShowOverlay(true);
                    setPushNotificationError({ ...pushNotificationError, message: '', title: '' });
                    UserInfoService.sendPushNotification({
                      userId: id,
                      title: pushNotification.title,
                      message: pushNotification.message,
                    }).then(() => {
                      setShowOverlay(false);
                      setNotification({
                        show: true,
                        message: 'Notification pushed successfully!!',
                      });
                      handleModalClose();
                    }).catch((err: any) => {
                      setNotification({
                        show: true,
                        message: err.response.data.message,
                      });
                    });
                  }
                }}
              >
                Send Notification
              </Button>
            </div>
          </div>
        )
      }
      {
        showSMSNotification && (
          <div>
            <h2>Send Notification</h2>
            <textarea
              placeholder="Enter message"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            {smsNotificationError.message === 'empty' && <ValidationError error="SMS Message cannot be empty" />}
            {smsNotificationError.message === 'greater' && <ValidationError error="SMS cannot be greater than 120 alphabets" />}

            <div className={modalStyles.modalButtonWrapper}>
              <Button
                className={scss.margin_right}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                className={scss.margin_right}
                onClick={() => {
                  if (message.length === 0) {
                    setSMSNotificationError({ ...smsNotificationError, message: 'empty' });
                  } else if (message.length > 120) {
                    setSMSNotificationError({ ...smsNotificationError, message: 'greater' });
                  } else {
                    setShowOverlay(true);
                    UserInfoService.sendSMS({
                      userId: id,
                      message,
                    }).then(() => {
                      setShowOverlay(false);
                      handleModalClose();
                      setNotification({
                        show: true,
                        message: 'SMS send successfully!!',
                      });
                    }).catch((err: any) => {
                      setNotification({
                        show: true,
                        message: err.response.data.message,
                      });
                    });
                  }
                }}
              >
                Send Notification
              </Button>
            </div>
          </div>
        )
      }
    </>
  );
};

export default UserInfoSendNotification;
