import {
  put, call, takeEvery,
} from 'redux-saga/effects';
import { RewardPointDetailsService } from 'services/rewardpointdetails';
import { ActionTypes } from '../constants';

async function fetchAsync(func: any, arg: any) {
  try {
    const response = arg !== null ? await func(arg) : await func();
    return response;
  } catch (error: any) {
    return {
      error: true,
      message: error.response.data.message,
      status: error.response.data.statusCode,
    };
  }
}

function* debitActivityPoint(action: any): Generator {
  try {
    const data: any = yield call(fetchAsync, RewardPointDetailsService.getRewardPoint, action.payload);
    if (data.error) {
      if (data.status === 403) {
        yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
      } else {
        yield put({ type: ActionTypes.DEBIT_ACTIVITY_LIST_ERROR, payload: data.message });
      }
    } else {
      yield put({ type: ActionTypes.DEBIT_ACTIVITY_LIST_SUCCESS, payload: data.data.data });
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.DEBIT_ACTIVITY_LIST_REQUEST, error: e.data });
  }
}

export function* watchRewardPointSaga() {
  yield takeEvery(ActionTypes.DEBIT_ACTIVITY_LIST_REQUEST, debitActivityPoint);
}
export default watchRewardPointSaga;
