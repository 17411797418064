import React from 'react';

import scss from './SuccessMessage.module.scss';

interface Props {
    message: string
}

const SuccessMessage = (props: Props) => {
  const { message } = props;
  return (
    <>
      <p className={scss.successMessage}>{message}</p>
    </>
  );
};

export default SuccessMessage;
