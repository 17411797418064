import React from 'react';
import { compose } from 'redux';
import { injectIntl } from 'utils/reactIntl';
import { connect } from 'react-redux';
import { FAQFilters } from 'forms';
import { getDateAndTime } from 'utils/dateMethods';
import { useHistory } from 'react-router-dom';
import {
  DeleteFAQS, Modal, FAQsBlocked,
} from 'components';
import Parser from 'html-react-parser';
import InfinitScroll from 'react-infinite-scroll-component';
import { ContentManagementService } from 'services';
import edit from '../../assets/svg/edit.svg';
import scss from './faqs.module.scss';
import blockIcon from '../../assets/svg/block.svg';
import unblockIcon from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import active from '../../assets/svg/active-g.svg';
import block from '../../assets/svg/blocked.svg';

interface QASInterface {
  date: string;
  question: string;
  answer: string;
  status: string;
  id: string;
  handleOperations: any;
}

const QAS: React.FC<QASInterface> = (props: QASInterface) => {
  const {
    date,
    question,
    answer,
    status,
    id,
    handleOperations,
  } = props;
  return (
    <>
      <div className={scss.faqBox}>
        <div className={scss.faqBoxHead}>
          <p>
            <span>Added On:</span>
            {date}
          </p>
          <div>
            <div className={scss.faqBoxaction}>
              <img
                src={edit}
                role="presentation"
                alt="edit"
                width="35"
                title="Edit"
                height="35"
                onClick={() => handleOperations('edit', id)}
              />
              <img
                src={deleteIcon}
                role="presentation"
                alt="block"
                width="35"
                title="Delete"
                height="35"
                onClick={() => handleOperations('delete', id)}
              />
              {status !== 'draft' && (
                <img
                  src={status === 'unblocked' ? blockIcon : unblockIcon}
                  alt={status === 'unblocked' ? 'blockIcon' : 'unblockIcon'}
                  width="35"
                  title={status === 'unblocked' ? 'Block' : 'Unblock'}
                  height="35"
                  role="presentation"
                  onClick={() => handleOperations(status === 'unblocked'
                    ? 'blocked' : 'unblocked', id)}
                />
              )}
            </div>
            {status === 'draft' ? null
              : (status === 'unblocked' ? (
                <img
                  src={active}
                  alt="active"
                  title="active"
                />
              ) : (
                <img
                  src={block}
                  alt="blocked"
                  title="blocked"
                />
              ))}
          </div>
        </div>
        <div className={scss.qstAns}>
          <div>
            <h2>
              <span>Q.</span>
              {' '}
              {Parser(question)}
            </h2>
          </div>
          <div>
            {Parser(answer)}
          </div>
        </div>
      </div>
    </>
  );
};

const FQASComponent: React.FC = () => {
  const [filters, setFilters] = React.useState<any>({
    page: 1,
    limit: 10,
    search: '',
    startDate: '',
    endDate: '',
    status: '',
  });
  const history = useHistory();
  const [deleteState, setDeleteState] = React.useState<any>({
    id: null,
    show: false,
  });
  const [blockState, setBlockState] = React.useState<any>({
    id: null,
    show: false,
  });
  const [unblock, setUnBlock] = React.useState<any>({
    id: null,
    show: false,
  });
  const [scrollableData, setScrollableData] = React.useState<any>({
    ques: [],
    count: 0,
    msg: '',
  });

  const [count, setCount] = React.useState(1);
  React.useEffect(() => {
    setScrollableData({
      ques: [],
      count: 0,
      msg: '',
    });
    ContentManagementService
      .getFAQS(filters).then((res: any) => {
        setScrollableData({
          // ...scrollableData,
          ques: [...res.data.data.data],
          // /ques: [...scrollableData.ques, ...res.data.data.data],
          count: res.data.data['Total-Page'],
        });
        setCount((prevState: any) => prevState + 1);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleResetSorts = () => {
    setFilters({
      ...filters,
      search: '',
      startDate: '',
      endDate: '',
      status: '',
    });
  };

  const [showModal, setShowModal] = React.useState(false);
  const handleFormSubmit = (values: any) => {
    setCount(1);
    if (values.search !== ''
      || values.startDate !== null
      || values.endDate !== null
      || values.status !== '') {
      setFilters({
        ...filters,
        page: 1,
        search: values.search,
        startDate: values.startDate !== null ? values.startDate.toISOString() : '',
        endDate: values.endDate !== null ? values.endDate.toISOString() : '',
        status: values.status,
      });
    }
  };
  const handleModalClose = () => {
    setShowModal(false);
    setDeleteState({
      id: null,
      show: false,
    });
    setBlockState({
      id: null,
      show: false,
    });
    setUnBlock({
      id: null,
      show: false,
    });
  };

  const handleOperations = (type: string, id: string) => {
    setShowModal(true);
    switch (type) {
      case 'edit':
        history.push(`/contentmanagement/editFAQs/${id}`);
        break;
      case 'delete':
        setDeleteState({
          id,
          show: true,
        });
        break;
      case 'blocked':
        setBlockState({
          id,
          show: true,
        });
        break;
      case 'unblocked':
        setUnBlock({
          id,
          show: true,
        });
        break;
      default:
        break;
    }
  };

  const fetchNextUsers = () => {
    setCount((prevState: any) => prevState + 1);
    ContentManagementService
      .getFAQS({
        page: count,
        limit: 10,
        startDate: '',
        endDate: '',
        status: '',
        search: '',
      }).then((res: any) => {
        setScrollableData({
          ...scrollableData,
          ques: [...scrollableData.ques, ...res.data.data.data],
          count: res.data.data['Total-Page'],
        });
      });
  };
  return (
    <>
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {deleteState.show && (
          <DeleteFAQS
            handleModalClose={() => {
              setFilters({ ...filters, page: 1 });
              handleModalClose();
            }}
            id={deleteState.id}
          />
        )}
        {blockState.show && (
          <FAQsBlocked
            handleModalClose={handleModalClose}
            id={blockState.id}
            fetchFaqsList={() => setFilters({ ...filters, page: 1 })}
            status="blocked"
          />
        )}
        {unblock.show && (
          <FAQsBlocked
            handleModalClose={handleModalClose}
            id={unblock.id}
            fetchFaqsList={() => setFilters({ ...filters, page: 1 })}
            status="unblocked"
          />
        )}
      </Modal>
      <FAQFilters
        handleResetSorts={handleResetSorts}
        handleFormSubmit={handleFormSubmit}
        error=""
      />
      <h2 className={scss.contentHeading}>
        FAQs
        <button
          type="button"
          className={scss.headingEdit}
          onClick={() => history.push('/contentmanagement/addFAQS')}
        >
          Add FAQ
        </button>
      </h2>
      <div id="scrollableDiv" style={{ height: '100vh', overflowY: 'scroll' }}>
        <InfinitScroll
          dataLength={scrollableData.ques.length}
          next={fetchNextUsers}
          hasMore={count <= scrollableData.count}
          loader={<h4>Loading ... </h4>}
          scrollableTarget="scrollableDiv"
        >
          {scrollableData.ques.map((ele: any, index: any) => (
            <QAS
              key={index}
              date={getDateAndTime(ele.createdAt)}
              question={ele.question}
              answer={ele.answer}
              status={ele.status}
              id={ele._id}
              handleOperations={handleOperations}
            />
          ))}
        </InfinitScroll>
      </div>
    </>
  );
};
const mapStateToProps = () => ({
});
const mapDispatchToProps = () => ({
});

const FQAS = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(FQASComponent);

export default FQAS;
