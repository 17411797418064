import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

export function exportToExcel(jsonData: any, fileName: any) {
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { User: ws }, SheetNames: ['User'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}.xlsx`);
}

export function exportToCSV(jsonData: any, fileName: any) {
  const ws = XLSX.utils.json_to_sheet(jsonData);
  const wb = { Sheets: { User: ws }, SheetNames: ['User'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `${fileName}.csv`);
}
