import { AddNewVersionForm } from 'forms';
import { ManageVersionManagement } from 'services';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { Overlay } from 'components';

const ManageVersion = () => {
  const history = useHistory();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [error, setError] = React.useState('');
  return (
    <>
      {showOverlay && <Overlay />}

      <AddNewVersionForm
        handleSubmit={(data: any) => {
          if (data.launch === 'false') {
            setShowOverlay(false);
            setError('Please select launch type');
          } else {
            setError('');
            setShowOverlay(true);
            ManageVersionManagement
              .manageVersionManagement(data)
              .then(() => {
                history.push('/versionmanagement');
                setShowOverlay(false);
              });
          }
        }}
        error={error}
      />
    </>
  );
};

export default ManageVersion;
