import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import searchIcon from 'assets/svg/ic_search.svg';
import classNames from 'classnames';
import FieldSelect from './FieldSelect';
import scss from './FieldSelect.module.scss';

interface Props {
  name: string, id: string, className?: string, label?: string, search?: boolean, placeholder?: string, options: { key: string; label: any; }[], required?: boolean,
  formProps?: any, variant?: boolean, showError?: boolean, defaultValue?: string,
}

// search options replced by options
const CategorySelect = (props: Props) => {
  const {
    name,
    id,
    options,
    label,
    search,
    placeholder,
    required,
    className,
    showError,
    defaultValue,
    variant,
    formProps: { formState: { errors }, control },
  } = props;
  const [open, setOpen] = useState(false);
  const [searchedKey, setSearchedKey] = useState('');
  const [searchedOptions, setSearchedOptions] = useState(options);
  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);
  const selectClasses = classNames(errors[id] ? scss.red : null, className);

  const searchProps = {
    open,
    onOpen: handleOpen,
    onClose: handleClose,
  };

  const handleSearch = (e: any) => {
    const searchedValue = e.target.value;
    setSearchedKey(searchedValue);
    try {
      setSearchedOptions(options.filter(
        (fieldObj: any) => fieldObj?.label?.toLowerCase().includes(searchedValue.toLowerCase()),
      ));
    } catch (err: any) {
      // something went wrong
    }
  };

  return (
    <>
      <FieldSelect
        id={id}
        fieldName={name}
        label={label}
        required={required}
        control={control}
        errors={id === 'countryCode' ? !!errors.phoneNo : !!errors[id]}
        defaultValue={defaultValue}
        className={selectClasses}
        showError={showError}
        search={search}
        searchProps={searchProps}
        placeholder={placeholder}
        variant={variant}
      >
        {placeholder ? (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        ) : null}
        {search ? (
          <div role="presentation" className={scss.search_input} onClick={handleOpen}>
            <input
              type="text"
              placeholder="Search"
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e: any) => {
                e.stopPropagation();
                handleSearch(e);
              }}
              value={searchedKey}
            />
            <span className={scss.icon}>
              <img src={searchIcon} alt="icon" />
            </span>
          </div>
        ) : null}
        {search ? (searchedOptions.length > 0 ? searchedOptions.map((option: any, index: number) => (
          <MenuItem key={`${option.key}.${index}`} value={option.key} onKeyDown={(e) => e.stopPropagation()} className="f_spacebw">
            {option.label ? (<span>{option.label}</span>) : null}
            <span>{option.name ?? ''}</span>
          </MenuItem>
        )) : null) : (options.length > 0 ? options.map((option: any, index: number) => (
          <MenuItem key={`${option.key}.${index}`} value={option.key} onKeyDown={(e) => e.stopPropagation()} className="f_spacebw">
            {option.label ? (<span>{option.label}</span>) : null}
            <span>{option.name ?? ''}</span>
          </MenuItem>
        )) : null)}
      </FieldSelect>
    </>
  );
};

CategorySelect.defaultProps = {
  required: false,
  label: null,
  className: null,
  search: false,
  placeholder: null,
  defaultValue: undefined,
  showError: true,
  variant: true,
  formProps: { formState: { errors: {} }, control: null },
};

export default CategorySelect;
