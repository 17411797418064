import roles from '../AdminRoles/roles.enum';

const hasAcccess = (permissionKey: any, accessType: string) => {
  const permissions: any = localStorage.getItem('permissions');
  if (localStorage.getItem('adminType') === roles.superadmin
  || localStorage.getItem('adminType') === null
  || localStorage.getItem('adminType') === undefined) {
    return true;
  }

  return JSON.parse(permissions) && JSON.parse(permissions).length > 0
    ? JSON.parse(permissions)
      .filter((ele: any) => ele.key === permissionKey)[0][accessType] : false;
};

export default hasAcccess;
