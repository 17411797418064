import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldTextInput,
  ValidationError,
} from 'components';
import classNames from 'classnames';
import { Editor } from 'react-draft-wysiwyg';
import { Notification } from 'services';
import {
  EditorState,
} from 'draft-js';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { stateToHTML } from 'draft-js-export-html';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  error: string;
  setError: any;
  setOverlay: any;
}

const AddNotificationEmailForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    loader,
    error,
    setError,
    setOverlay,
  } = props;
  const history = useHistory();

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [editorError, setEditorError] = React.useState('');
  const addNotificationSchema = yup.object().shape({
    title: yup.string()
      .max(250, 'Please enter characters less than 250 characters for description')
      .required('Please fill all required fields'),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addNotificationSchema),
  });

  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    if (editorState.getCurrentContent().getPlainText().length === 0) {
      setEditorError('Please fill description');
    } else {
      setEditorError('');
      setOverlay(true);
      Notification.addNotification({
        subject: data.title,
        message: stateToHTML(editorState.getCurrentContent()),
        sendBy: 'email',
        status: 'draft',
      }).then(() => {
        history.push('/notification');
        setOverlay(false);
      }).catch((err: any) => {
        setError(err.response.data.message);
        setOverlay(false);
      });
    }
  };

  const handleSubmit = (data: any) => {
    if (editorState.getCurrentContent().getPlainText().length === 0) {
      setEditorError('Please fill description');
    } else {
      setEditorError('');
      setOverlay(true);
      Notification.addNotification({
        subject: data.title,
        message: stateToHTML(editorState.getCurrentContent()),
        sendBy: 'email',
        status: 'sent',
      }).then(() => {
        history.push('/notification');
        setOverlay(false);
      }).catch((err) => {
        setOverlay(false);
        setError(err.response.data.message);
      });
    }
  };

  return (
    <div className={classNames(commonStyles.cardBg, commonStyles.addDetailsPage)}>
      <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
        <FieldTextInput
          id="title"
          name="title"
          formName="addNotification"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName={commonStyles.passwordInput}
          label={intl.formatMessage({ id: 'AddNotification.label.subject' })}
        />
        <div>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(e) => setEditorState(e)}
            placeholder={intl.formatMessage({ id: 'AddNotification.label.description' })}
            toolbar={{
              options: ['inline', 'textAlign'],
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'italic', 'underline'],
              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['left', 'center', 'right', 'justify'],
              },
            }}
          />
        </div>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className={commonStyles.validation_error}
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
            />
          )}
        {Object.keys(formProps.formState.errors).length === 0
          && <ValidationError error={error} />}
        {Object.keys(formProps.formState.errors).length === 0
          && editorError.length > 0
          && <ValidationError error="Please fill the description" />}
        {loader
          ? (
            <div className={commonStyles.savingLoader}>
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className={commonStyles.buttonWrapper}>
                <Button
                  buttonType={ButtonTypes.button}
                  onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  {intl.formatMessage({ id: 'AddNotification.label.Save.as.draft' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  {intl.formatMessage({ id: 'AddNotification.label.Submit' })}
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default injectIntl(AddNotificationEmailForm);
