import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import constant from '../api/constant';
import api from '../api';

const isAuthTokenValid = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded:any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    return false;
  }
  return true;
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    constant.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    Cookies.remove('jwt_access_token');
    delete constant.axios.defaults.headers.common.Authorization;
  }
};

export class AuthService {
  static authRequest() {
    const accessToken = Cookies.get('jwt_access_token');
    if (!accessToken) {
      return false;
    }

    if (isAuthTokenValid(accessToken)) {
      setSession(accessToken);
      return true;
    }
    setSession(null);
    return false;
  }

  static login(payload: any) {
    return api.postApiCall('/api/v1/admins/login', { data: payload });
  }

  static forgotpass(payload: any) {
    return api.putApiCall('/api/v1/admins/forgot-password', payload);
  }

  static logout() {
    return api.putApiCall('/api/v1/admins/logout', {});
  }

  static resetPassword(payload: any) {
    return api.putApiCall('/api/v1/admins/reset-password', { data: payload.data });
  }
}
