import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Button, IconClose } from '..';
import { injectIntl } from '../../utils/reactIntl';

import scss from './Modal.module.scss';

const KEY_CODE_ESCAPE = 27;

interface Props {
    children?: React.ReactNode,
    className?: string,
    scrollLayerClassName?: string,
    containerClassName?: string,
    contentClassName?: string,
    lightCloseButton?: boolean,
    id: string,
    intl: any,
    isClosedClassName?: string,
    isOpen?: boolean,
    onClose?: any,
    onManageDisableScrolling: any
}

const ModalComponent: React.FC<Props> = (props: Props) => {
  const {
    children,
    className,
    id,
    scrollLayerClassName,
    containerClassName,
    contentClassName,
    lightCloseButton,
    intl,
    isClosedClassName,
    onManageDisableScrolling,
    onClose,
    isOpen,
  } = props;

  const handleClose = useCallback((event: any) => {
    onManageDisableScrolling(id, false);
    onClose(event);
  }, [id, onClose, onManageDisableScrolling]);

  const handleBodyKeyUp = useCallback(
    (event: any) => {
      if (event.keyCode === KEY_CODE_ESCAPE && isOpen) {
        handleClose(event);
      }
    },
    [handleClose, isOpen],
  );
  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    document.body.addEventListener('keyup', handleBodyKeyUp);
    return () => {
      document.body.removeEventListener('keyup', handleBodyKeyUp);
    };
  }, [handleBodyKeyUp, id, isOpen, onManageDisableScrolling]);

  useEffect(() => {
    onManageDisableScrolling(id, isOpen);
    return () => {
      onManageDisableScrolling(id, false);
    };
  }, [onManageDisableScrolling, id, isOpen]);

  const closeModalMessage = intl.formatMessage({ id: 'Modal.closeModal' });
  const closeButtonClasses = classNames(scss.close, {
    [scss.closeLight]: lightCloseButton,
  });
  const closeBtn = isOpen ? (
    <Button
      onClick={handleClose}
      rootClassName={closeButtonClasses}
      title={closeModalMessage}
    >
      <IconClose rootClassName={scss.closeIcon} />
    </Button>
  ) : null;

  const modalClass = isOpen ? scss.isOpen : isClosedClassName;
  const classes = classNames(modalClass, className);
  const scrollLayerClasses = scrollLayerClassName || scss.scrollLayer;
  const containerClasses = containerClassName || scss.container;

  return (
    <div className={classes}>
      <div className={scrollLayerClasses}>
        <div className={containerClasses}>
          {closeBtn}
          <div className={classNames(contentClassName || scss.content)}>{children}</div>
        </div>
      </div>
    </div>
  );
};

ModalComponent.defaultProps = {
  children: null,
  className: '',
  scrollLayerClassName: '',
  containerClassName: '',
  contentClassName: '',
  lightCloseButton: false,
  isClosedClassName: scss.isClosed,
  isOpen: false,
  onClose: null,
};

export default injectIntl(ModalComponent);
