import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  ValidationError,
  CircularProgressLoader,
} from 'components';
import {
  RolesManagementService,
  SubAdminManagementService,
} from 'services';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import commonStyles from 'components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import subadmin from '../AddSubAdmin/addsubadmin.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  error: any;
  id: any;
}

const EditSubAdminForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    error,
    id,
    intl,
  } = props;
  const history = useHistory();
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const editSubAdminSchema = yup.object().shape({
    name: yup.string()
      .required(intl.formatMessage({ id: 'EditSubAdmin.error.names.required' })),
    email: yup.string()
      .required(intl.formatMessage({ id: 'EditSubAdmin.error.email.required' })),
    phoneNumber: yup.string()
      .required(intl.formatMessage({ id: 'EditSubAdmin.error.phoneNumber.required' })),
    countryCode: yup.string().required(intl.formatMessage({ id: 'EditSubAdmin.error.countryCode.required' })),
    roleId: yup.string()
      .required(intl.formatMessage({ id: 'EditSubAdmin.error.roleId.required' })),
  }, []);
  const formProps = useForm({
    resolver: yupResolver(editSubAdminSchema),
  });

  React.useEffect(() => {
    Promise.all([
      SubAdminManagementService
        .getDetail(id),
      RolesManagementService
        .getAllRoles(),
    ]).then((responses: any) => {
      setLoading(false);
      formProps.reset({
        name: responses[0].data.data.name,
        email: responses[0].data.data.email,
        phoneNumber: responses[0].data.data.phoneNo,
        roleId: responses[0].data.data.roleId,
        countryCode: `+${responses[0].data.data.countryCode}`,
      });
      const data: any = [];
      responses[1].data.data.forEach((ele: any) => {
        data.push({ key: ele._id, label: ele.role });
      });
      setRoles(data);
    });
  }, [formProps, id]);

  return (
    <>
      {loading ? (
        <div>
          <CircularProgressLoader />
        </div>
      ) : (
        <div className={classNames(commonStyles.cardBg, commonStyles.addDetailsPage)}>
          <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
            <h2 className={commonStyles.sign_text}>
              {intl.formatMessage({ id: 'EditDetails.label.details' })}
            </h2>
            <div className="flex_row">
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="name"
                  name="name"
                  formName="subAdmin"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName={commonStyles.passwordInput}
                  label={intl.formatMessage({ id: 'EditDetails.label.name' })}
                />
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  id="email"
                  name="email"
                  formName="subAdmin"
                  className="textInput"
                  type="text"
                  formProps={formProps}
                  rootClassName={commonStyles.passwordInput}
                  label={intl.formatMessage({ id: 'EditDetails.label.email' })}
                />
              </div>
              <div className="flex_col_sm_6">
                <FieldTextInput
                  className="phoneNumber2"
                  id="phoneNo"
                  name="phoneNumber"
                  formName="UpdateProfile"
                  type="text"
                  // defaultValueCc={formProps.watch('countryCode')}
                  formProps={formProps}
                  label={intl.formatMessage({ id: 'EditDetails.label.mobile' })}
                />
              </div>
              <div className="flex_col_sm_6">
                <div className={subadmin.userType}>
                  <FieldSelect
                    id="roleId"
                    name="roleId"
                    label={intl.formatMessage({ id: 'EditDetails.label.role' })}
                    variant={false}
                    formProps={formProps}
                    options={roles}
                    placeholder="Assign a role"
                  />
                </div>
              </div>
            </div>

            {Object.keys(formProps.formState.errors).length > 0
              && (
                <ValidationError
                  className={commonStyles.validation_error}
                  error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
                />
              )}
            {Object.keys(formProps.formState.errors).length === 0
              && <ValidationError error={error} />}
            <div className={commonStyles.buttonWrapper}>
              <Button onClick={() => history.push('/subadminmanagement')}>
                {intl.formatMessage({ id: 'EditDetails.label.cancel' })}
              </Button>
              <Button buttonType={ButtonTypes.submit}>
                {intl.formatMessage({ id: 'EditDetails.label.update' })}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default injectIntl(EditSubAdminForm);
