import api from '../api';

export class ReportedUserManagementService {
  static async getReportedUserList(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      startDate,
      endDate,
      isSortByCreated,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByUsername !== undefined && isSortByUsername !== '' ? `&isSortByUserName=${isSortByUsername}` : ''}${startDate !== undefined && startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reported-users/reported?${apiEndPoint}`);
  }

  static async exportListToMail(payload: any) {
    const {
      startDate,
      endDate,
      status,
      search,
      isSortByCreated,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByUsername !== undefined && isSortByUsername !== '' ? `&isSortByUsername=${isSortByUsername}` : ''}${startDate !== undefined && startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== undefined && endDate !== '' ? `&endDate=${endDate}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reported-users/reported?${apiEndPoint}`);
  }

  static async addNote(payload: any) {
    return api.postApiCall('/api/v1/admins/notes', payload);
  }
}
