import roles from 'components/AdminRoles/roles.enum';
import React from 'react';

interface Props {
  children: any,
  permissionKey: any,
  accessType: string,
}

const AccessControl: React.FC<Props> = (props: Props) => {
  const { children, permissionKey, accessType } = props;
  const permissions: any = localStorage.getItem('permissions');
  if (localStorage.getItem('adminType') === roles.superadmin) {
    return children;
  }
  return JSON.parse(permissions)
    .filter((ele: any) => ele.key === permissionKey)[0][accessType]
    ? children : null;
};

export default AccessControl;
