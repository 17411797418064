import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutWrapperMain,
  Page,
  RoleManagement,
} from '../../components';
import config from '../../config.js';
import css from './rolemanagement.module.scss';

interface Props{
  intl: any;
  scrollingDisabled: any;
}

const RoleManagementComponent:React.FC<Props> = (props: Props) => {
  const { intl, scrollingDisabled } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'Rolemanagement.page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain className={css.layoutWrapperMain}>
        <div className={css.content}>
          <RoleManagement />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const RoleManagementComponentPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(RoleManagementComponent);

export default RoleManagementComponentPage;
