import React from 'react';
import moment from 'moment';
import { injectIntl } from 'utils/reactIntl';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Button,
  FieldDatePicker,
  FieldSelect,
  FieldSearchBar,
  CircularProgressLoader,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  AccessControl,
} from 'components';
import searchBarApis from 'utils/searchBarApis';
import { useForm } from 'react-hook-form';
import carretDown from '../../assets/svg/caretdown.svg';
import exp from '../../assets/svg/export.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import scss from './LiveStreamingFilter.module.scss';

interface Props {
  intl: any;
  handleFormSubmit: any,
  handleExport: any,
  handleResetSorts: any,
  domes: any,
  loaderExport: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const LiveStreamingManagementFilterForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    handleFormSubmit,
    domes,
    handleExport,
    loaderExport,
    handleResetSorts,
  } = props;

  const [showFilter, setShowFilter] = React.useState(true);
  const [toggle, setToggle] = React.useState(false);
  const formProps = useForm();

  const handleReset = () => {
    formProps.reset({
      search: '',
      startDate: null,
      endDate: null,
      domes: '',
      userType: '',
    });
    handleResetSorts();
  };
  return (
    <form className={commonStyles.filterForm} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div className={scss.search_wrapper}>
            <div className={commonStyles.filter}>
              {intl.formatMessage({ id: 'LiveStreaming.label.filter' })}
              <span
                className={commonStyles.closeBtn}
                role="presentation"
                onClick={() => setTimeout(() => {
                  setShowFilter((prevState) => !prevState);
                }, 200)}
              >
                {showFilter
                  ? (
                    <ClearIcon />
                  ) : <img src={carretDown} alt="down" />}
              </span>
            </div>
            <div className={commonStyles.fieldSet}>
              <FieldSearchBar
                searchURL={searchBarApis.livestreamingManagement}
                formProps={formProps}
                name="search"
                label={intl.formatMessage({ id: 'LiveStreaming.label.search' })}
                id="search"
              />
            </div>
          </div>
          <AccessControl
            permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
            accessType={PERMISSION_TYPES.EDIT}
          >
            <div className={commonStyles.userRightExport}>
              {!loaderExport ? (
                <span>
                  <img
                    src={exp}
                    alt="export"
                    role="presentation"
                    onClick={() => setToggle((prevState: boolean) => !prevState)}
                  />
                  {toggle && (
                    <ul className={commonStyles.userrigthToggle}>
                      <li
                        role="presentation"
                        onClick={() => handleExport('XLSX')}
                      >
                        {intl.formatMessage({ id: 'LiveStreaming.label.export.xlsx' })}
                      </li>
                      <li
                        role="presentation"
                        onClick={() => handleExport('CSV')}
                      >
                        {intl.formatMessage({ id: 'LiveStreaming.label.export.csv' })}
                      </li>
                    </ul>
                  )}
                </span>
              ) : (
                <div className={commonStyles.exportProgressBar}>
                  <CircularProgressLoader />
                </div>
              )}

            </div>
          </AccessControl>
        </div>
        {showFilter && (
          <div>
            <p>
              {intl.formatMessage({ id: 'LiveStreaming.label.added' })}
            </p>
            <div className={commonStyles.filterOptions}>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="startDate"
                  name="startDate"
                  className="dateOfBirthInput"
                  label={intl.formatMessage({ id: 'LiveStreaming.label.startDate' })}
                  formProps={formProps}
                  maxDate={formProps.watch('endDate') !== null ? formProps.watch('endDate') : moment().subtract(0, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.datepickerField}>
                <FieldDatePicker
                  id="endDate"
                  name="endDate"
                  className="dateOfBirthInput"
                  label={intl.formatMessage({ id: 'LiveStreaming.label.endDate' })}
                  formProps={formProps}
                  minDate={formProps.watch('startDate') !== null ? formProps.watch('startDate') : moment().subtract(118, 'years').format('YYYY-MM-DD')}
                />
              </div>
              <div className={commonStyles.userType}>
                <FieldSelect
                  id="domes"
                  name="domes"
                  variant={false}
                  label={intl.formatMessage({ id: 'LiveStreaming.label.domes' })}
                  formProps={formProps}
                  options={domes}
                />
              </div>
              <div className={commonStyles.userType}>
                <FieldSelect
                  id="status"
                  name="status"
                  variant={false}
                  label={intl.formatMessage({ id: 'LiveStreaming.label.status' })}
                  formProps={formProps}
                  options={[
                    { key: '', label: '--Select--' },
                    { key: 'live', label: 'Ongoing' },
                    { key: 'scheduled', label: 'Scheduled' },
                    { key: 'completed', label: 'Ended' },
                    { key: 'blocked', label: 'Cancelled' },
                  ]}
                />
              </div>
              <div className={commonStyles.userType}>
                <FieldSelect
                  id="liveType"
                  name="liveType"
                  variant={false}
                  label={intl.formatMessage({ id: 'LiveStreaming.label.liveType' })}
                  formProps={formProps}
                  options={[
                    { key: '', label: '--Select--' },
                    { key: 'private_event_paid', label: 'Private & Paid' },
                    { key: 'private_event_free', label: 'Private & Free' },
                    { key: 'public_event_free', label: 'Public & Free' },
                    { key: 'public_event_paid', label: 'Public & Paid' },
                  ]}
                />
              </div>
              <div className={commonStyles.buttonWrapper}>
                <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
                  {intl.formatMessage({ id: 'LiveStreaming.label.apply' })}
                </Button>
                <Button onClick={handleReset} className={commonStyles.button}>
                  {intl.formatMessage({ id: 'LiveStreaming.label.reset' })}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default injectIntl(LiveStreamingManagementFilterForm);
