import React from 'react';
import { connect } from 'react-redux';
import { getDateAndTime } from 'utils/dateMethods';
import {
  Modal,
  NotificationManagementShowSave,
  Overlay,
  ToastBar,
} from 'components';
import { SubAdminManagementService } from 'services';
import { getPaymentManagement } from 'actions';
import {
  PaymentManagementFilter,
} from 'forms';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import avatcoin from 'assets/svg/avatcoin.svg';
import avatcoinsilver from 'assets/images/silvercoin.png';
import DataTable from '../Datatable';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  payementmanagement: any;
  fetchNotificationList: any;
}

const PaymentManagement: React.FC<Props> = (props: Props) => {
  const {
    payementmanagement,
    fetchNotificationList,
  } = props;
  const history = useHistory();
  const [filter, setFilter] = React.useState<any>({
    platform: '',
    startDate: '',
    endDate: '',
    search: '',
    userType: '',
    page: 0,
    rowsPerPage: 10,
    isSortByCreatedAt: '',
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [showResend, setShowResend] = React.useState<any>({
    show: false,
    id: null,
    status: '',
  });
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    fetchNotificationList(filter);
  }, [fetchNotificationList, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = payementmanagement.data.length !== 0 ? payementmanagement.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    if (data.search !== ''
      || data.endDate !== null
      || data.startDate !== null
      || data.status !== '') {
      setFilter({
        ...filter,
        search: data.search,
        startDate: data.startDate ? data.startDate.utc() : '',
        endDate: data.endDate ? data.endDate.utc() : '',
        status: data.status,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowResend({
      show: false,
      id: null,
      status: '',
    });
  };

  const handleStatus = (status: any) => {
    switch (status) {
      case 'Success':
        return 'Successful';
      case 'pending':
        return 'Pending';
      case 'refund':
        return 'Refunded';
      default:
        return '';
    }
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    const totalPrice = (
      <div className={commonStyles.c_price}>
        <img src={avatcoin} alt="avatcoin" />
        <span>{data.goldCoin}</span>
      </div>
    );

    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      orderId: data.orderId.length > 10 ? (
        <div title={data.orderId}>
          {data.orderId.substring(0, 10)}
          ...
        </div>
      ) : data.orderId,
      transactionId: data.transactionId.length > 10 ? (
        <div title={data.transactionId}>
          {data.transactionId.substring(0, 10)}
          ...
        </div>
      ) : data.transactionId,
      subscriptionId: data.subscriptionPlanId ? data.subscriptionPlanId : '__',
      username: data.receiverUser.username,
      transactionDateAndTime: getDateAndTime(data.createdAt),
      purchasedItem: data.title,
      itemType: data.itemType,
      totalPrice,
      discountedPrice: data.discountedPrice ? (
        <div className={commonStyles.c_price}>
          <img src={avatcoin} alt="avatcoin" />
          <span>{data.discountedPrice.gold}</span>
          <span className={commonStyles.separator}>+</span>
          <img src={avatcoinsilver} alt="avatcoin" />
          <span>{data.discountedPrice.silver}</span>
        </div>
      ) : 0,
      amountpaid: `$${data.amount}`,
      transactionstatus: handleStatus(data.sourceUserPaymentStatus),
    };
  }

  const rows: any = [];

  if (payementmanagement.data.length !== 0 && payementmanagement.data) {
    payementmanagement.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'orderId', label: 'Order ID', sort: false,
    },
    {
      id: 'transactionId', label: 'Transaction ID', sort: false,
    },
    {
      id: 'subscriptionId', label: 'Subscription ID', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: false,
    },
    {
      id: 'transactionDateAndTime', label: 'Transaction Date & Time', sort: true,
    },
    {
      id: 'purchasedItem', label: 'Purchased Item', sort: false,
    },
    {
      id: 'itemType', label: 'Item type', sort: false,
    },
    {
      id: 'totalPrice', label: 'Total Price', sort: false,
    },
    {
      id: 'discountedPrice', label: 'Discounted Price', sort: false,
    },
    {
      id: 'amountpaid', label: 'Amount paid', sort: false,
    },
    {
      id: 'transactionstatus', label: 'Transaction Status', sort: false,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'transactionDateAndTime') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreatedAt: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    } else {
      history.push(`/paymentmanagement/paymentinfo/${name.id}`);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      startDate: '',
      endDate: '',
      search: '',
      page: 0,
      rowsPerPage: 10,
      isSortByCreatedAt: '',
      status: '',
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Email: ele.email,
            AdminName: ele.name,
            PhoneNo: ele.phoneno,
            Role: ele.role,
            AddedOn: ele.createdAt,
          });
        });
        exportToExcel(selectedRowDataModified, 'SubAdminManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        SubAdminManagementService.exportSubAdmin({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Email: ele.email,
            AdminName: ele.name,
            PhoneNo: ele.phoneno,
            Role: ele.role,
            AddedOn: ele.createdAt,
          });
        });
        exportToCSV(selectedRowDataModified, 'SubAdminManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        SubAdminManagementService.exportSubAdmin({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          showResend.show && (
            <NotificationManagementShowSave
              handleModalClose={handleModalClose}
              id={showResend.id}
              fetchAdminList={() => setFilter({ page: 0 })}
              setShowOverlay={setShowOverlay}
              status={showResend.status}
            />
          )
        }
      </Modal>
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={() => setNotification({
              ...notification,
              show: false,
              message: '',
            })}
          />
        )
      }
      <div>
        <PaymentManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={payementmanagement.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  payementmanagement: state.payementmanagement,
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotificationList: (payload: any) => dispatch(getPaymentManagement(payload)),
});

const PaymentManagementComponent = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PaymentManagement);

export default PaymentManagementComponent;
