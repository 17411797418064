import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { AuthDataModal } from '../modal';

const authenticated = (authInfo: any) => authInfo && authInfo.isAnonymous === false;

export default function reducer(state: AuthDataModal = new AuthDataModal(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.AUTH_INFO_REQUEST:
      return state;
    case ActionTypes.AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: [],
      };

    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginInProgress: false,
        isAuthenticated: true,
        loginInfoData: payload.data.data.admin,
      };
    case ActionTypes.LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case ActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logoutInProgress: true,
        loginError: null,
        logoutError: null,
      };
    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        logoutInProgress: false,
        isAuthenticated: false,
        authScopes: [],
      };
    case ActionTypes.LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case ActionTypes.FORGOTPASS_REQUEST:
      return {
        ...state,
        forgotpassInprogress: true,
        forgotpassError: null,
        forgotpassSucess: false,
      };
    case ActionTypes.FORGOTPASS_SUCCESS:
      return {
        ...state,
        forgotpassInprogress: false,
        forgotpassError: null,
        forgotpassSucess: true,
      };
    case ActionTypes.FORGOTPASS_ERROR:
      return {
        ...state,
        forgotpassInprogress: false,
        forgotpassError: payload,
        forgotpassSucess: false,
      };
    case ActionTypes.FORGOTPASS_STATEINIT:
      return {
        ...state,
        forgotpassInprogress: false,
        forgotpassError: null,
        forgotpassSucess: false,
      };
    case ActionTypes.CONFIRM_REQUEST:
      return {
        ...state,
        confirmInProgress: true,
        loginError: null,
        confirmError: null,
      };
    case ActionTypes.CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case ActionTypes.CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };
    case ActionTypes.LOGOUT_WITH_ERROR_SUCCESS:
      return {
        ...state,
        logoutInProgress: true,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
