import { Button } from 'components';
import React from 'react';
import { ChannelInfoService } from 'services';
import warning from '../../assets/svg/warning.svg';
import modalStyles from '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchChannelList: any;
  setOverlay: any;
  status: any;
}
const ChannelManagementBlocked: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    setOverlay,
    fetchChannelList,
    status,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const blockService = () => {
    setOverlay(true);
    ChannelInfoService.blockChannelApi(id, status === 'blocked' ? 'inactive' : 'active').then(() => {
      setBlocked(true);
      setOverlay(false);
    });
  };

  return (
    <>
      {!blocked ? (
        <>
          <div>
            <img src={warning} alt="warning" />
            <h2>
              {status === 'blocked' ? 'Block' : 'Unblock'}
              {' '}
              Channel
            </h2>
            <p>
              Are you sure you want to
              {' '}
              {status === 'blocked' ? 'block' : 'unblock'}
              {' '}
              this channel?
            </p>
            <div className={modalStyles.modalButtonWrapper}>
              <Button onClick={handleModalClose}>No</Button>
              <Button onClick={blockService}>Yes</Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>
            Channel
            {' '}
            {status === 'blocked' ? 'blocked' : 'unblocked'}
            {' '}
            successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchChannelList({
              page: 0,
              rowsPerPage: 10,
            });
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default ChannelManagementBlocked;
