import { ActionTypes } from '../constants/index';

export const getSubAdminManagement = (payload: any) => ({
  type: ActionTypes.SUBAMIN_LIST_REQUEST,
  payload: {
    page: payload.page,
    rowsPerPage: payload.rowsPerPage,
    search: payload.search,
    isSortByName: payload.isSortByName,
    isSortByCreated: payload.isSortByCreated,
  },
});
