import { Button } from 'components';
import React from 'react';
import { ContentManagementService } from 'services';
import warning from '../../assets/svg/warning.svg';
import modalStyles from '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
}

const DomesManagementDelete: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
  } = props;

  const [deleted, setDeleted] = React.useState(false);
  const deleteDomesService = () => {
    ContentManagementService.deleteFAQ({ faqId: id }).then(() => {
      setDeleted(true);
    });
  };

  return (
    <>
      {!deleted ? (
        <>
          <img src={warning} alt="warning" />
          <h2>Delete FAQs? </h2>
          <p>Are you sure you want to delete this FAQ? </p>
          <div className={modalStyles.modalButtonWrapper}>
            <Button onClick={handleModalClose}>No</Button>
            <Button onClick={deleteDomesService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            FAQs deleted successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
          }}
          >
            Ok
          </Button>
        </>
      )}

    </>
  );
};

export default DomesManagementDelete;
