import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MyProfileService } from 'services';
import { aesEncrypted } from 'utils/aesEncrypt';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import {
  FieldTextInput,
  Button,
  ValidationError,
  CircularProgressLoader,
} from '../../components';
import { FormattedMessage } from '../../utils/reactIntl';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import scss from './changepassword.module.scss';

interface Props {
  intl: any;
  setShowAlert: any;
  showAlert: any;
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const ChangePassForm: React.FC<Props> = (props: Props) => {
  const { setShowAlert, showAlert, intl } = props;
  const [showChangePassLoader, setShowChangePassLoader] = useState(false);
  const [changePassword, setChangePassword] = useState({
    error: false,
    message: '',
  });
  const changePassSchema = yup.object().shape({
    currentpassword: yup.string()
      .required(intl.formatMessage({ id: 'ChangePass.error.currentpassword.required' }))
      .matches(/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8})/, intl.formatMessage({ id: 'ChangePass.error.currentpassword.matches' }))
      .max(30, intl.formatMessage({ id: 'ChangePass.error.currentpassword.max' })),
    newpassword: yup.string()
      .required(intl.formatMessage({ id: 'ChangePass.error.newpassword.required' }))
      .matches(/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8})/, intl.formatMessage({ id: 'ChangePass.error.newpassword.matches' }))
      .max(30, intl.formatMessage({ id: 'ChangePass.error.newpassword.max' })),
    confirmpassword: yup.string()
      .required(intl.formatMessage({ id: 'ChangePass.error.confirmpassword.required' }))
      .matches(/((?=.*\d)(?=.*[A-Z])(?=.*\W).{8})/, intl.formatMessage({ id: 'ChangePass.error.confirmpassword.required' }))
      .max(30, intl.formatMessage({ id: 'ChangePass.error.confirmpassword.required' })),
  }, []);
  const formProps = useForm({
    resolver: yupResolver(changePassSchema),
  });

  const handleFormSubmit = (values: any) => {
    if (values.newpassword === values.confirmpassword) {
      setShowChangePassLoader(true);
      setShowAlert({
        ...showAlert,
        show: false,
        message: '',
      });
      const encryptedData = aesEncrypted(JSON.stringify({
        oldPassword: values.currentpassword,
        password: values.newpassword,
      }));
      MyProfileService.changePassword({
        data: encryptedData,
      }).then(() => {
        setShowChangePassLoader(false);
        setShowAlert({
          ...showAlert,
          show: true,
          message: intl.formatMessage({ id: 'ChangePass.success.passchange' }),
        });
        setChangePassword({
          error: false,
          message: '',
        });
        formProps.reset({
          currentpassword: '',
          newpassword: '',
          confirmpassword: '',
        });
      }).catch((error: any) => {
        setShowChangePassLoader(false);
        setChangePassword({
          error: true,
          message: error.response.data.message,
        });
      });
    } else {
      setChangePassword({
        error: true,
        message: intl.formatMessage({ id: 'ChangePass.error.confirmpassword' }),
      });
    }
  };
  const [passwordType, setPasswordType] = useState('password');
  const [confirmpasswordType, setConfirmpasswordType] = useState('password');
  const [newpasswordType, setNewpasswordType] = useState('password');

  const passwordTypeHandler = () => {
    setPasswordType(passwordType === 'text' ? 'password' : 'text');
  };
  const confirmPasswordTypeHandler = () => {
    setConfirmpasswordType(confirmpasswordType === 'text' ? 'password' : 'text');
  };
  const newPasswordTypeHandler = () => {
    setNewpasswordType(newpasswordType === 'text' ? 'password' : 'text');
  };

  return (
    <form className={classNames(commonStyles.form, scss.changePasswordForm)} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <h2 className={scss.sign_text}>{intl.formatMessage({ id: 'ChangePassword.label.changepassword' })}</h2>
      <div className={scss.formRow}>
        <div className={scss.formField}>
          <FieldTextInput
            id="currentpassword"
            name="currentpassword"
            formName="changepassword"
            className="textInput"
            type={passwordType}
            passwordTypeHandler={passwordTypeHandler}
            formProps={formProps}
            showVisibilityIcon
            rootClassName={scss.passwordInput}
            label={intl.formatMessage({ id: 'ChangePassword.label.currentpass' })}
          />
        </div>
        <div className={scss.dFlex}>
          <div className={scss.formField}>
            <FieldTextInput
              id="newpassword"
              name="newpassword"
              formName="changepassword"
              className="textInput"
              type={newpasswordType}
              passwordTypeHandler={newPasswordTypeHandler}
              formProps={formProps}
              showVisibilityIcon
              rootClassName={scss.passwordInput}
              label={intl.formatMessage({ id: 'ChangePassword.label.newpass' })}
            />
          </div>
          <div className={scss.formField}>
            <FieldTextInput
              id="confirmpassword"
              name="confirmpassword"
              formName="changepassword"
              className="textInput"
              type={confirmpasswordType}
              passwordTypeHandler={confirmPasswordTypeHandler}
              formProps={formProps}
              showVisibilityIcon
              rootClassName={scss.passwordInput}
              label={intl.formatMessage({ id: 'ChangePassword.label.confirmpass' })}
            />
          </div>
        </div>
      </div>
      {Object.keys(formProps.formState.errors).length > 0
        && (
          <ValidationError
            className={scss.validation_error}
            error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
          />
        )}
      {
        changePassword.error && Object.keys(formProps.formState.errors).length === 0 && (
          <ValidationError
            className={scss.validation_error}
            error={changePassword.message}
          />
        )
      }
      {showChangePassLoader
        ? <div className={classNames(commonStyles.savingLoader, scss.resetPasswordLoader)}><CircularProgressLoader /></div>
        : (
          <div className={scss.buttonCover}>
            <Button buttonType={ButtonTypes.submit}>
              {showChangePassLoader && <CircularProgressLoader />}
              <FormattedMessage id="ChangePassword.reset.button" />
            </Button>
          </div>
        )}
    </form>
  );
};

export default injectIntl(ChangePassForm);
