export default {
  AUTH_INFO_REQUEST: 'app/Auth/AUTH_INFO_REQUEST',
  AUTH_INFO_SUCCESS: 'app/Auth/AUTH_INFO_SUCCESS',

  LOGIN_REQUEST: 'app/Auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'app/Auth/LOGIN_SUCCESS',
  LOGIN_ERROR: 'app/Auth/LOGIN_ERROR',

  LOGOUT_REQUEST: 'app/Auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'app/Auth/LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'app/Auth/LOGOUT_ERROR',

  FORGOTPASS_REQUEST: 'app/Auth/FORGOTPASS_REQUEST',
  FORGOTPASS_SUCCESS: 'app/Auth/FORGOTPASS_SUCCESS',
  FORGOTPASS_ERROR: 'app/Auth/FORGOTPASS_ERROR',
  FORGOTPASS_STATEINIT: 'app/Auth/FORGOTPASS_ERROR',

  CONFIRM_REQUEST: 'app/Auth/CONFIRM_REQUEST',
  CONFIRM_SUCCESS: 'app/Auth/CONFIRM_SUCCESS',
  CONFIRM_ERROR: 'app/Auth/CONFIRM_ERROR',
  RESEND_OTP_REQUEST_SIGNUP: 'app/Auth/RESEND_OTP_SIGNUP',

  LOGOUT_WITH_ERROR_REQUEST: 'app/Auth/LOGOUT_WITH_ERROR_REQUEST',
  LOGOUT_WITH_ERROR_SUCCESS: 'app/Auth/LOGOUT_WITH_ERROR_SUCCESS',
};
