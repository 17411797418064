import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dateFormatting } from 'utils/dateMethods';
import {
  AccessControl,
  Button,
  hasAccess,
  Modal,
  Overlay,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  SubAdminManagementBlocked,
  SubAdminManagementDelete,
  ToastBar,
} from 'components';
import { SubAdminManagementService } from 'services';
import { getSubAdminManagement } from 'actions';
import {
  SubAdminManagementFilter,
} from 'forms';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import DataTable from '../Datatable';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import editIcon from '../../assets/svg/edit.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  subadminmanagement: any;
  fetchSubAdminList: any;
}

const SubAdminManagement: React.FC<Props> = (props: Props) => {
  const {
    subadminmanagement,
    fetchSubAdminList,
  } = props;
  const history = useHistory();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    search: '',
    isSortByName: '',
    isSortByCreated: '',
  });
  const [error, setError] = React.useState<any>(null);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [edited, setEdited] = React.useState<any>({
    show: false,
    id: null,
  });
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    fetchSubAdminList(filter);
  }, [fetchSubAdminList, filter]);
  const hideActions = () => hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.DELETE);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      case 'edit':
        history.push(`/subadminmanagement/editSubAdmin/${blockedid}`);
        break;
      default:
        break;
    }
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = subadminmanagement.data ? subadminmanagement.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    setError(null);
    if (data.search !== '') {
      setFilter({
        ...filter,
        search: data.search.label,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setEdited({ ...edited, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const actionButtons = (action: any, idS: any) => (
    <>
      <div className={commonStyles.actionBtnsWrapper}>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div>
            <img
              src={editIcon}
              role="presentation"
              alt="edit"
              width="35"
              title="Edit"
              height="35"
              onClick={() => handleBlockUnblock('edit', idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.DELETE}
        >
          <div>
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock('delete', idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div>
            {action === 'pending' ? null : (action === 'unblocked' ? (
              <img
                src={blockIcon}
                role="presentation"
                alt="block"
                width="35"
                title="Block"
                height="35"
                onClick={() => handleBlockUnblock('blocked', idS)}
              />
            ) : (
              <img
                src={unblock}
                role="presentation"
                alt="unblock"
                width="35"
                title="Unblock"
                height="35"
                onClick={() => handleBlockUnblock('unblocked', idS)}
              />
            ))}
          </div>
        </AccessControl>
      </div>
    </>
  );

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      name: data.name,
      email: data.email,
      role: data.role !== undefined ? (data.role.role !== undefined ? data.role.role : '') : '',
      phoneno: data.phoneNo,
      createdAt: dateFormatting(data.createdAt),
      ...(hideActions() && { action: actionButtons(data.status, idS) }),
    };
  }

  const rows: any = [];
  if (subadminmanagement.data !== null && subadminmanagement.data) {
    subadminmanagement.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = hideActions() ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'name', label: 'Name', sort: true,
    },
    {
      id: 'email', label: 'Email', sort: true,
    },
    {
      id: 'role', label: 'Role', sort: false,
    },
    {
      id: 'phoneNo', label: 'Phone Number', sort: false,
    },
    {
      id: 'createdAt', label: 'Added On', sort: true,
    },
    {
      id: 'action', label: 'Action', sort: false,
    },
  ] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'name', label: 'Name', sort: true,
    },
    {
      id: 'email', label: 'Email', sort: true,
    },
    {
      id: 'role', label: 'Role', sort: false,
    },
    {
      id: 'phoneNo', label: 'Phone Number', sort: false,
    },
    {
      id: 'createdAt', label: 'Added On', sort: true,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      search: '',
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Email: ele.email,
            AdminName: ele.name,
            PhoneNo: ele.phoneno,
            Role: ele.role,
            AddedOn: ele.createdAt,
          });
        });
        exportToExcel(selectedRowDataModified, 'SubAdminManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        SubAdminManagementService.exportSubAdmin({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Email: ele.email,
            AdminName: ele.name,
            PhoneNo: ele.phoneno,
            Role: ele.role,
            AddedOn: ele.createdAt,
          });
        });
        exportToCSV(selectedRowDataModified, 'SubAdminManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        SubAdminManagementService.exportSubAdmin({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  return (
    <>
      {showOverlay && <Overlay />}
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <SubAdminManagementDelete
              setShowOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchAdminList={() => { setFilter({ ...filter, page: 0 }); }}
              id={deleted.id}
            />
          )
        }
        {
          blocked.show && (
            <SubAdminManagementBlocked
              setShowOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchAdminList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <SubAdminManagementBlocked
              setShowOverlay={setShowOverlay}
              handleModalClose={handleModalClose}
              fetchAdminList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <AccessControl
          permissionKey={PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT}
          accessType={PERMISSION_TYPES.ADD}
        >
          <div className={commonStyles.topButton}>
            <Button
              onClick={() => history.push('/subadminmanagement/addSubAdmin')}
            >
              Add New Sub Admin
            </Button>
          </div>
        </AccessControl>
        <SubAdminManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={subadminmanagement.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { subadminmanagement } = state;
  return {
    subadminmanagement,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSubAdminList: (payload: any) => dispatch(getSubAdminManagement(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminManagement);
