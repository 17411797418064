import {
  put, call, takeEvery,
} from 'redux-saga/effects';
import { SupportManagementService } from '../services';
import { ActionTypes } from '../constants';

async function fetchAsync(func: any, args: any) {
  const response = await func(args);
  return response;
}

function* watchSupportInfo(action: any): Generator {
  try {
    const data:any = yield call(fetchAsync, SupportManagementService.getSupportList, { data: action.payload });
    yield put({ type: ActionTypes.SUPPORT_MGMT_LIST_SUCCESS, payload: data.data.data });
  } catch (e: any) {
    yield put({ type: ActionTypes.SUPPORT_MGMT_LIST_ERROR, error: e.data });
  }
}

export function* watchSupport() {
  yield takeEvery(ActionTypes.SUPPORT_MGMT_LIST_REQUEST, watchSupportInfo);
}
export default watchSupport;
