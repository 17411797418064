import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';

const Overlay: React.FC = () => (
  <Backdrop
    open
    style={{ zIndex: 999 }}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default Overlay;
