import api from '../api';

export class PostDetailReportedUserInfoService {
  static async getPostDetail(payload: any) {
    const {
      rowsPerPage,
      page,
      id,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&postId=${id}`;
    return api.getApiCall(`api/v1/admins/reported-posts?${apiEndPoint}`);
  }
}
