import { Button } from 'components';
import React from 'react';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import modalStyles from '../Modal/Modal.module.scss';
import warning from '../../assets/svg/warning.svg';

interface Props {
  handleModalClose: any,
  deleteChannelService: any;
}

const ChannelManagementDelete: React.FC<Props> = (props: Props) => {
  const { handleModalClose, deleteChannelService } = props;
  return (
    <div className={commonStyles.delete_modalContainer}>
      <img src={warning} alt="warning" />
      <h2>Delete Channel </h2>
      <p>Are you sure you want to delete this Channel? </p>
      <div className={modalStyles.modalButtonWrapper}>
        <Button
          onClick={handleModalClose}
        >
          No
        </Button>
        <Button
          onClick={deleteChannelService}
        >
          Yes
        </Button>
      </div>
    </div>
  );
};
export default ChannelManagementDelete;
