import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import scss from './accordion.module.scss';

interface Props {
  heading: string;
  children: any;
}

const Accordion: React.FC<Props> = (props: Props) => {
  const { heading, children } = props;
  const [toggle, setToggle] = useState(false);
  return (
    <div
      className={scss.container}
    >
      <div
        role="presentation"
        className={scss.header}
        onClick={() => setToggle((prevState: any) => !prevState)}
      >
        <h2>{heading}</h2>
        {toggle ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
      <div className={toggle ? scss.showAccordionBody : scss.hideAccordionBody}>
        {toggle && children}
      </div>
    </div>
  );
};

export default Accordion;
