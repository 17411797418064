import { Button, ValidationError } from 'components';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { ReportedUserManagementService } from 'services';
import modalStyles from '../Modal/Modal.module.scss';
import scss from './addnote.module.scss';

interface Props {
  handleModalClose: any;
  setShowOverlay: any;
  setNotification: any;
  id: any;
  intl: any;
}

const AddNote: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose, id, intl, setShowOverlay, setNotification,
  } = props;
  const [message, setMessage] = useState('');
  const [smsNotificationError, setSMSNotificationError] = useState({
    message: '',
  });
  return (
    <div>
      <h2>
        {intl.formatMessage({ id: 'AddNote.label' })}
      </h2>
      <textarea
        placeholder="Enter message"
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      {smsNotificationError.message === 'empty'
        && <ValidationError error="Note cannot be empty" />}
      {smsNotificationError.message === 'greater'
        && <ValidationError error="Note cannot be greater than 300 alphabets" />}

      <div className={modalStyles.modalButtonWrapper}>
        <Button
          className={scss.margin_right}
          onClick={handleModalClose}
        >
          {intl.formatMessage({ id: 'AddNote.label.Cancel' })}
        </Button>
        <Button
          className={scss.margin_right}
          onClick={() => {
            if (message.length === 0) {
              setSMSNotificationError({ ...smsNotificationError, message: 'empty' });
            } else if (message.length > 300) {
              setSMSNotificationError({ ...smsNotificationError, message: 'greater' });
            } else {
              setShowOverlay(true);
              ReportedUserManagementService.addNote({
                userId: id,
                description: message,
              }).then(() => {
                setNotification({
                  show: true,
                  message: 'Note added successfully',
                });
                setShowOverlay(false);
                handleModalClose();
              });
            }
          }}
        >
          {intl.formatMessage({ id: 'AddNote.label' })}
        </Button>
      </div>
    </div>
  );
};

export default injectIntl(AddNote);
