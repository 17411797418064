import { TextField } from '@material-ui/core';
import { Button, ValidationError } from 'components';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import { ChannelInfoService } from 'services';
import { stateToHTML } from 'draft-js-export-html';
import { injectIntl } from '../../utils/reactIntl';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import notification from '../UserInfoSendNotification/userinfosendnotification.module.scss';

interface Props {
  handleModalClose: any;
  intl: any;
  id: any,
  channelInfo: any
  setShowOverlay: any
}

const ChannelManagementSendNotification: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    channelInfo,
    setShowOverlay,
    intl,
  } = props;
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [sendNotification, setSendNotification] = React.useState(true);
  const [showEmailNotification, setShowEmailNotification] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [showSMSNotification, setShowSMSNotification] = React.useState(false);
  const [showPushNotification, setShowPushNotification] = React.useState(false);
  const [emailTitle, setEmailTitle] = React.useState('');
  const [pushNotification, setPushNotification] = React.useState({
    title: '',
    message: '',
  });
  const [emailNotificationError, setEmailNotificationError] = React.useState({
    title: false,
    message: '',
  });
  const [smsNotificationError, setSMSNotificationError] = React.useState({
    message: '',
  });
  const [pushNotificationError, setPushNotificationError] = React.useState({
    title: false,
    message: '',
  });
  return (
    <>
      {
        sendNotification && (
          <div>
            <div className={notification.sendNotification}>
              <Button
                className={notification.buttoNot}
                onClick={() => {
                  setShowEmailNotification(true);
                  setSendNotification(false);
                }}
              >
                {intl.formatMessage({ id: 'ChannelSendNotification.label.emailnotification' })}
              </Button>
              <Button
                className={notification.buttoNot}
                onClick={() => {
                  setShowSMSNotification(true);
                  setSendNotification(false);
                }}
              >
                {intl.formatMessage({ id: 'ChannelSendNotification.label.smsnotification' })}
              </Button>
              <Button
                className={notification.buttoNot}
                onClick={() => {
                  setShowPushNotification(true);
                  setSendNotification(false);
                }}
              >
                {intl.formatMessage({ id: 'ChannelSendNotification.label.pushnotification' })}
              </Button>
            </div>
          </div>
        )
      }
      {
        showEmailNotification && (
          <div>
            <h2>{intl.formatMessage({ id: 'ChannelSendNotification.label.emailnotification' })}</h2>
            <TextField
              onChange={(e) => {
                setEmailTitle(e.target.value);
              }}
              id="emailTitle"
              label={intl.formatMessage({ id: 'ChannelSendNotification.label.email' })}
              name="textField"
              variant="outlined"
              style={{ width: '100%' }}
            />
            {emailNotificationError.title
              && <ValidationError error="Email title can not be empty" />}
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setEditorState(e)}
              toolbar={{
                options: ['inline', 'textAlign'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline'],
                },
                textAlign: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['left', 'center', 'right', 'justify'],
                },
              }}
            />
            {emailNotificationError.message === 'empty' && <ValidationError error="Email content can not be empty" />}
            {emailNotificationError.message === 'greater' && <ValidationError error="Email content can not be greater than 2000 alphabets" />}

            <div className={commonStyles.modal_footer}>
              <Button
                className={commonStyles.margin_right}
                onClick={handleModalClose}
              >
                {intl.formatMessage({
                  id: 'ChannelSendNotification.label.cancel',
                })}
              </Button>
              <Button
                className={commonStyles.margin_right}
                onClick={() => {
                  if (emailTitle.length === 0) {
                    setEmailNotificationError({ ...emailNotificationError, title: true });
                  } else if (editorState.getCurrentContent().getPlainText().length === 0) {
                    setEmailNotificationError({ ...emailNotificationError, title: false, message: 'empty' });
                  } else if (editorState.getCurrentContent().getPlainText().length > 2000) {
                    setEmailNotificationError({ ...emailNotificationError, message: 'greater' });
                  } else {
                    setShowOverlay(true);
                    ChannelInfoService.sendEmail({
                      channelId: id,
                      subject: emailTitle,
                      message: stateToHTML(editorState.getCurrentContent()),
                      userId: channelInfo.data.userId._id,
                    }).then(() => {
                      setShowOverlay(false);
                      handleModalClose();
                    });
                  }
                }}
              >
                {intl.formatMessage({
                  id: 'ChannelSendNotification.label.notification',
                })}
              </Button>
            </div>
          </div>
        )
      }
      {
        showPushNotification && (
          <div>
            <h2>{intl.formatMessage({ id: 'ChannelSendNotification.label.push' })}</h2>
            <TextField
              onChange={(e) => {
                setPushNotification({
                  ...pushNotification,
                  title: e.target.value,
                });
              }}
              id="emailTitle"
              label={intl.formatMessage({ id: 'ChannelSendNotification.label.notification' })} // "ChannelSendNotification.label.notification"
              name="textField"
              placeholder="Email Title"
              variant="outlined"
              style={{ width: '100%' }}
            />
            {pushNotificationError.title
              && <ValidationError error="Notification title cannot be empty" />}
            <textarea
              placeholder={intl.formatMessage({ id: 'ChannelSendNotification.label.message' })}
              onChange={(e) => {
                setPushNotification({
                  ...pushNotification,
                  message: e.target.value,
                });
              }}
            />
            {pushNotificationError.message === 'empty'
              && <ValidationError error="Notification message cannot be empty" />}
            {pushNotificationError.message === 'greater' && <ValidationError error="Notification message cannot be greater than 300 alphabets" />}

            <div className={commonStyles.modal_footer}>
              <Button
                className={commonStyles.margin_right}
                onClick={handleModalClose}
              >
                {intl.formatMessage({
                  id: 'ChannelSendNotification.label.cancel',
                })}
              </Button>
              <Button
                className={commonStyles.margin_right}
                onClick={() => {
                  if (pushNotification.title === '') {
                    setPushNotificationError({ ...pushNotificationError, title: true });
                  } else if (pushNotification.message === '') {
                    setPushNotificationError({ ...pushNotificationError, title: false, message: 'empty' });
                  } else if (pushNotification.message.length > 300) {
                    setPushNotificationError({ ...pushNotificationError, message: 'greater' });
                  } else {
                    setShowOverlay(true);
                    ChannelInfoService.sendPushNotification({
                      channelId: id,
                      title: pushNotification.title,
                      message: pushNotification.message,
                      userId: channelInfo.data.userId._id,
                    }).then(() => {
                      setShowOverlay(false);
                      handleModalClose();
                    });
                  }
                }}
              >
                {intl.formatMessage({
                  id: 'ChannelSendNotification.label.notification',
                })}
              </Button>
            </div>
          </div>
        )
      }
      {
        showSMSNotification && (
          <div>
            <h2>
              {intl.formatMessage({
                id: 'ChannelSendNotification.label.smsnotification',
              })}
            </h2>
            <textarea
              placeholder="Enter Message"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            {smsNotificationError.message === 'empty'
              && <ValidationError error="SMS Message cannot be empty" />}
            {smsNotificationError.message === 'greater'
              && <ValidationError error="SMS cannot be greater than 300 alphabets" />}

            <div className={commonStyles.modal_footer}>
              <Button
                className={commonStyles.margin_right}
                onClick={handleModalClose}
              >
                {intl.formatMessage({
                  id: 'ChannelSendNotification.label.cancel',
                })}
              </Button>
              <Button
                className={commonStyles.margin_right}
                onClick={() => {
                  if (message.length === 0) {
                    setSMSNotificationError({ ...smsNotificationError, message: 'empty' });
                  } else if (message.length > 300) {
                    setSMSNotificationError({ ...smsNotificationError, message: 'greater' });
                  } else {
                    setShowOverlay(true);
                    ChannelInfoService.sendSMS({
                      channelId: id,
                      message,
                      userId: channelInfo.data.userId._id,
                    }).then(() => {
                      setShowOverlay(false);
                      handleModalClose();
                    });
                  }
                }}
              >
                {intl.formatMessage({
                  id: 'ChannelSendNotification.label.notification',
                })}
              </Button>
            </div>
          </div>
        )
      }
    </>
  );
};

export default injectIntl(ChannelManagementSendNotification);
