import api from '../api';

export class PaymentManagementService {
  static async getParticipantDetails(payload: any) {
    const {
      page,
      rowsPerPage,
      search,
      endDate,
      startDate,
      status,
      isSortByCreatedAt,
    } = payload;

    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${status !== '' && status !== undefined ? `&transactionStatus=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreatedAt !== '' ? `&isSortByCreatedAt=${isSortByCreatedAt}` : ''}`;
    return api.getApiCall(`api/v1/admins/transactions?${apiEndPoint}`);
  }

  static async sendEmail(payload: any) {
    const {
      userId, transactionId, subject, message,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-transaction-notifications', {
      transactionId,
      userId,
      subject,
      message,
    });
    return data;
  }

  static async sendPushNotification(payload: any) {
    const {
      userId, transactionId, title, message,
    } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-transaction-notifications', {
      transactionId,
      userId,
      title,
      message,
    });
    return data;
  }

  static async sendSMS(payload: any) {
    const { userId, transactionId, message } = payload;
    const data = await api.postApiCall('/api/v1/admins/notifications/send-transaction-notifications', {
      transactionId,
      userId,
      message,
    });
    return data;
  }
}
