import { nanoid } from 'nanoid';

import { ActionTypes } from '../constants/index';

export const showAlert = (message: any, options: any) => {
  const timeout = options.variant === 'danger' ? 0 : 5;
  const payload = {
    id: options.id || nanoid(),
    icon: options.icon,
    message,
    position: options.position || 'top-center',
    variant: options.variant || 'dark',
    timeout: typeof options.timeout === 'number' ? options.timeout : timeout,
  };
  return { type: ActionTypes.SHOW_ALERT, payload };
};
