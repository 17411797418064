import api from '../api';

export class UserReportService {
  static async getUserList(payload: any) {
    const {
      page,
      rowsPerPage,
      id,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&userId=${id}`;

    return api.getApiCall(`/api/v1/admins/reported-users?${apiEndPoint}`);
  }
}
