import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  ManageSubAdmin,
  LayoutWrapperMain, Page,
} from 'components';
import config from '../../config.js';
import css from './managesubadmin.module.scss';

interface Props {
  intl: any;
  scrollingDisabled: any;
}

const ManageSubAdminComponent: React.FC<Props> = (props: Props) => {
  const { intl, scrollingDisabled } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'Rolemanagement.page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain className={css.layoutWrapperMain}>
        <div className={css.content}>
          <ManageSubAdmin />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ManageSubAdminComponentPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageSubAdminComponent);

export default ManageSubAdminComponentPage;
