import React from 'react';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import css from './FieldInputFile.module.scss';
import edit from '../../assets/svg/edit.svg';

interface Props {
  rootClassName?: string,
  className?: string,
  id: any,
  name: string,
  formProps: any,
  disabled?: boolean,
}

const FieldFileInput = (props: Props) => {
  const {
    rootClassName,
    className,
    id,
    name,
    formProps: { control },
    disabled,
  } = props;

  const classes = classNames(rootClassName || css.root, className ? css[className] : null);
  const [_value, _setValue] = React.useState<any>('');
  const [file, setFile] = React.useState<any>('');

  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        render={({
          field: {
            ref, onChange, value,
          },
        }) => (
          <>
            <div className={css.file}>
              <div className={css.fileiconU}>
                <input
                  type="file"
                  id={id}
                  ref={ref}
                  onChange={(e: any) => {
                    if (e.target.files.length > 0) {
                      _setValue(e.target.value);
                      setFile(e.target.files[0]);
                      onChange(e.target.files);
                    }
                  }}
                  value={_value}
                  disabled={disabled}
                  title=""
                />
                <img src={edit} alt="userprofile" />
              </div>
            </div>
            <span className={css.fileimage}>
              {value !== null && typeof (value) === 'string' && <img src={value} alt="upload" />}
              {value !== null && typeof (value) === 'object' && <img src={URL.createObjectURL(file)} alt="upload" />}
            </span>
          </>
        )}
      />
    </div>
  );
};

FieldFileInput.defaultProps = {
  rootClassName: null,
  className: null,
  disabled: false,
};

export default FieldFileInput;
