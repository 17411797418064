import React from 'react';
import { LoginForm } from '../../forms';

interface Props {
  handleSubmit: any;
  loginError: any;
  changeSuccessPass: any;
}

const Login: React.FC<Props> = (props: Props) => {
  const { handleSubmit, loginError, changeSuccessPass } = props;
  const handleFormSubmit = (data: any) => {
    handleSubmit(data);
  };

  return (
    <section>
      <LoginForm
        handleFormSubmit={handleFormSubmit}
        loginError={loginError}
        changeSuccessPass={changeSuccessPass}
      />
    </section>
  );
};

export default Login;
