import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { withViewport } from '../../utils/contextHelpers.jsx';
import {
  // NamedLink,
  TopbarDesktop,
} from '..';
import { logout } from '../../actions';

import scss from './Topbar.module.scss';

interface Props {
  className?: string,
  rootClassName?: string,
  desktopClassName?: string,
  isAuthenticated: boolean,
  logoutAction: any;
  // These are passed from Page to keep Topbar rendering aware of location changes
  history: any;
  // from injectIntl
  // intl: any,
}

const TopbarComponent = (props: Props & RouteComponentProps<any>) => {
  const {
    className,
    rootClassName,
    desktopClassName,
    isAuthenticated,
    history,
    // intl,
    logoutAction,
  } = props;
  const classes = classNames(rootClassName || scss.root, className);

  return (
    <div className={classes}>
      {/* <div
        className={classNames(
          mobileRootClassName || scss.container,
          mobileClassName,
        )}
      >
        <button
          className={scss.menu}
          type="button"
          onClick={handleMobileMenuOpen}
          title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
        >
          <MenuIcon className={scss.menuIcon} />
        </button>
        <NamedLink
          className={scss.home}
          name="LoginPage"
          title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
        >
          Logo
        </NamedLink>
      </div> */}
      <div className={scss.desktop}>
        <TopbarDesktop
          className={desktopClassName}
          history={history}
          isAuthenticated={isAuthenticated}
          // notificationCount={notificationCount}
          logoutAction={logoutAction}
        />
      </div>
    </div>
  );
};

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutAction: () => dispatch(logout()),
});

const Topbar = compose(
  withViewport,
  // injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
