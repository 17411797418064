import { Button } from 'components';
import React from 'react';
import { UserInfoService } from 'services';
import warning from '../../assets/svg/warning.svg';
import modalStyles from '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchUserInfo: any;
  setShowOverlay: any;
}
enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const DeleteUserInfo: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchUserInfo,
    setShowOverlay,
  } = props;
  const [deleted, setDeleted] = React.useState(false);

  const deleteUserService = async (e: any) => {
    e.preventDefault();
    setShowOverlay(true);
    const data = await UserInfoService.deleteUserApi(id);
    if (data.data.statusCode === 202) {
      setDeleted(true);
      setShowOverlay(false);
    }
  };
  return (
    <>
      {!deleted ? (
        <>
          <img src={warning} alt="warning" />
          <h2>Delete User </h2>
          <p>Are you sure you want to delete this user? </p>
          <div className={modalStyles.modalButtonWrapper}>
            <Button buttonType={ButtonTypes.button} onClick={handleModalClose}>No</Button>
            <Button buttonType={ButtonTypes.button} onClick={deleteUserService}>Yes</Button>
          </div>
        </>
      ) : (
        <>
          <h3>
            User deleted successfully !
          </h3>
          <Button onClick={() => {
            handleModalClose();
            fetchUserInfo();
          }}
          >
            Ok
          </Button>
        </>
      )}
    </>
  );
};

export default DeleteUserInfo;
