import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  PayementInfoComponentInfo, LayoutWrapperMain, Page,
} from '../../components';
import config from '../../config.js';

interface Props {
  params: any;
  intl: any;
  scrollingDisabled: any;
}

const PaymentInfoComponent: React.FC<Props> = (props: Props) => {
  const { intl, scrollingDisabled, params } = props;
  const { siteTitle } = config;
  const schemaTitle = intl.formatMessage(
    { id: 'PaymentManagement.page.title' },
    { siteTitle },
  );
  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
    >
      <LayoutWrapperMain>
        <div>
          <PayementInfoComponentInfo id={params.id} />
        </div>
      </LayoutWrapperMain>
    </Page>
  );
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const PaymentInfoComponentPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PaymentInfoComponent);

export default PaymentInfoComponentPage;
