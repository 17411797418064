import api from 'api';

export class LiveStreamingManagement {
  static async getLiveStream(payload: any) {
    const {
      page,
      rowsPerPage,
      startDate,
      endDate,
      userType,
      search,
      liveType,
      domes,
      isSortByTitle,
      isSortByCreated,
      isSortByScheduledDateTime,
      isSortByCategory,
      isAcceptedUserCount,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${startDate !== '' && startDate !== null && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== null && endDate !== undefined ? `&endDate=${endDate}` : ''}${userType !== 'Select' && userType !== '' && userType !== undefined ? `&status=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${domes !== 'Select' && domes !== '' && domes !== undefined ? `&categoryId=${domes}` : ''}${isSortByCategory !== undefined && isSortByCategory !== '' ? `&isSortByCategory=${isSortByCategory}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByScheduledDateTime !== undefined && isSortByScheduledDateTime !== '' ? `&isSortByScheduledDateTime=${isSortByScheduledDateTime}` : ''}${isSortByTitle !== undefined && isSortByTitle !== '' ? `&isSortByTitle=${isSortByTitle}` : ''}${liveType !== undefined && liveType !== '' ? `&eventType=${liveType}` : ''}${isAcceptedUserCount !== undefined && isAcceptedUserCount !== '' ? `&isAcceptedUserCount=${isAcceptedUserCount}` : ''}`;
    return api.getApiCall(`/api/v1/admins/events?${apiEndPoint}`);
  }

  static async getDomes() {
    return api.getApiCall('/api/v1/admins/categories/names');
  }

  static async deleteApi(payload: any) {
    return api.deleteApiCall('/api/v1/admins/events', {
      eventId: payload,
    });
  }

  static async LiveStreamingBlocked(payload: any) {
    return api.putApiCall('/api/v1/admins/events', {
      eventId: payload,
    });
  }

  static async blockDomes(payload: any) {
    return api.putApiCall('/api/v1/admins/events', {
      eventId: payload.eventId,
      status: payload.status,
    });
  }
}
