import axios from 'axios/index';

const { REACT_APP_AVATAR_API_URL } = process.env;

export class AvatarService {
  static loadAvatar(data: any) {
    const url = REACT_APP_AVATAR_API_URL;
    if (url) { return axios.post(url, data); }
    return false;
  }
}
