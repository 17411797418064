import { Overlay } from 'components';
import {
  AddSubAdmin,
  EditSubAdmin,
} from 'forms';
import React,
{ useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom';
import { SubAdminManagementService } from 'services';

interface ManageSubDomesInterface {
  id: string;
}

const ManageSubAdmin = () => {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const { id } = useParams<ManageSubDomesInterface>();
  const history = useHistory();
  const editDome: boolean = history.location.pathname.includes('editSubAdmin');
  const [error, setError] = useState('');

  const handleSubmitForm = async (data: any) => {
    setShowOverlay(true);
    SubAdminManagementService.addRole({
      name: data.name,
      phoneNo: data.phoneNo,
      countryCode: data.countryCode.replace('+', ''),
      email: data.email,
      roleId: data.roleId,
    }).then(() => {
      setShowOverlay(false);
      history.push('/subadminmanagement');
    }).catch((err) => {
      setShowOverlay(false);
      setError(err.response.data.message);
    });
  };

  const handleSubmitFormEdit = async (data: any) => {
    setShowOverlay(true);
    SubAdminManagementService.updateRole({
      name: data.name,
      phoneNo: data.phoneNumber,
      countryCode: data.countryCode.replace('+', ''),
      email: data.email,
      adminId: id,
      roleId: data.roleId,
    }).then(() => {
      setShowOverlay(false);
      history.push('/subadminmanagement');
    }).catch((err) => {
      setShowOverlay(false);
      setError(err.response.data.message);
    });
  };

  return (
    <>
      {showOverlay && <Overlay />}
      {editDome ? (
        <EditSubAdmin
          handleSubmit={handleSubmitFormEdit}
          id={id}
          error={error}
        />
      )
        : (
          <AddSubAdmin
            handleSubmit={handleSubmitForm}
            error={error}
          />
        )}
    </>
  );
};

export default ManageSubAdmin;
