import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDateAndTime } from 'utils/dateMethods';
import {
  AccessControl,
  Button,
  hasAccess,
  Modal,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  ToastBar,
} from 'components';
import { ManageVersionManagement } from 'services';
import { fetchManageVersion } from 'actions';
import {
  VersionManagementFilter,
} from 'forms';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import DataTable from '../Datatable';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  manageversion: any;
  fetchManageVersions: any;
}

const SubAdminManagement: React.FC<Props> = (props: Props) => {
  const {
    manageversion,
    fetchManageVersions,
  } = props;
  const history = useHistory();
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    search: '',
    isSortByName: '',
    isSortByCreated: '',
  });
  const [error, setError] = React.useState<any>(null);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    fetchManageVersions(filter);
  }, [fetchManageVersions, filter]);
  const hideActions = () => hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.SUB_ADMIN_MANAGEMENT, PERMISSION_TYPES.DELETE);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = manageversion.data ? manageversion.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const handlePlatform = (platform: any) => {
    if (platform === '1') {
      return 'Android';
    }
    if (platform === '2') {
      return 'IOS';
    }
    return 'Web';
  };
  const handleSubmitFilter = (data: any) => {
    setError(null);
    if (data.search !== '') {
      setFilter({
        ...filter,
        search: data.search,
        page: 0,
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      versionNo: data.versionNo,
      platform: handlePlatform(data.platform),
      launchType: data.launchType,
      description: data.description.length > 20 ? (
        <div title={data.description}>
          {data.description.substring(0, 20)}
          ...
          {' '}
        </div>
      ) : data.description,
      createdAt: getDateAndTime(data.dateTime),
    };
  }

  const rows: any = [];
  if (manageversion.data !== null && manageversion.data) {
    manageversion.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = hideActions() ? [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'versionNo', label: 'Version Number', sort: false,
    },
    {
      id: 'platform', label: 'Platform', sort: false,
    },
    {
      id: 'launchType', label: 'Launch Type', sort: false,
    },
    {
      id: 'description', label: 'Version Description', sort: false,
    },
    {
      id: 'createdAt', label: 'Hosted On', sort: true,
    },
  ] : [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'versionNo', label: 'Version Number', sort: true,
    },
    {
      id: 'platform', label: 'Platform', sort: true,
    },
    {
      id: 'launchType', label: 'Launch Type', sort: false,
    },
    {
      id: 'description', label: 'Version Description', sort: false,
    },
    {
      id: 'createdAt', label: 'Hosted On', sort: true,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'name') {
      setFilter({
        ...filter,
        page: 0,
        isSortByName: order === 'asc' ? '1' : '-1',
      });
    }
    if (orderBy === 'createdAt') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreated: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      search: '',
    });
  };

  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Version_Number: ele.versionNo,
            PlatForm: ele.platform,
            Description: ele.description,
            Launch_Type: ele.launchType,
            AddedOn: ele.createdAt,
          });
        });
        exportToExcel(selectedRowDataModified, 'VersionManagement');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        ManageVersionManagement.exportAPI({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            SNo: ele.sno,
            Version_Number: ele.versionNo,
            PlatForm: handlePlatform(ele.platform),
            Description: ele.description,
            Launch_Type: ele.launchType,
            AddedOn: ele.createdAt,
          });
        });
        exportToCSV(selectedRowDataModified, 'VersionManagement');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        ManageVersionManagement.exportAPI({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      />
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <AccessControl
          permissionKey={PERMISSION_KEYS.VERSION_MANAGEMENT}
          accessType={PERMISSION_TYPES.ADD}
        >
          <div className={commonStyles.topButton}>
            <Button
              onClick={() => history.push('/versionmanagement/addVersion')}
            >
              Add New Version
            </Button>
          </div>
        </AccessControl>
        <VersionManagementFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          error={error}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={manageversion.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { manageversion } = state;
  return {
    manageversion,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchManageVersions: (payload: any) => dispatch(fetchManageVersion(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubAdminManagement);
