import { ActionTypes } from '../constants/index';

export const subdomesManagement = (payload: any) => ({
  type: ActionTypes.SUBDOMES_MGMT_LIST_REQUEST,
  payload: {
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
    status: payload.status,
    search: payload.search,
    isSortByParentCategoryName: payload.isSortByParentCategoryName,
    isSortByCategoryName: payload.isSortByCategoryName,
    isSortByCreated: payload.isSortByCreated,
  },
});
