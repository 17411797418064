import api from 'api';

export class RewardPointDetailsService {
  static async getRewardPoint(payload: any) {
    const {
      page,
      rowsPerPage,
      startDate,
      type,
      endDate,
      userType,
      search,
      isSortByRole,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&type=${type}${userType !== undefined && userType !== '' ? `&status=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByRole !== '' && isSortByRole !== undefined ? `&isSortByRole=${isSortByRole}` : ''}`;
    return api.getApiCall(`/api/v1/admins/reward-points?${apiEndPoint}`);
  }

  static async blockDomes(payload: any) {
    return api.putApiCall('/api/v1/admins/reward-points/activate-deactivate', {
      rewardPointId: payload.id,
      status: payload.status,
    });
  }

  static async updatePoints(payload: any) {
    return api.putApiCall('/api/v1/admins/reward-points', payload);
  }
}
