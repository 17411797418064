import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { AddNotificationEmailForm, AddNotificationSMSForm, AddNotificationPushForm } from 'forms';
import commonStyles from 'components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import { Overlay, ToastBar } from 'components';
import scss from './AddNotification.module.scss';

const AddNotification = () => {
  const [notification, setNotification] = useState({
    email: false,
    sms: false,
    push: false,
  });
  const [value, setValue] = React.useState('female');
  const [error, setError] = React.useState('');
  const [overlay, setOverlay] = React.useState(false);
  const handleChange = (event: any) => {
    setValue(event.target.value);
    if (event.target.value === 'email') {
      setNotification({ email: true, sms: false, push: false });
    }
    if (event.target.value === 'sms') {
      setNotification({ email: false, sms: true, push: false });
    }
    if (event.target.value === 'push') {
      setNotification({ email: false, sms: false, push: true });
    }
  };
  return (
    <>
      {error.length > 0 && (
        <ToastBar
          message={error}
          handleCloseMethod={() => setError('')}
        />
      )}
      {overlay && <Overlay />}
      <div className={classNames(commonStyles.cardBg)}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <h2 className={scss.heading}>Add Details</h2>
          </FormLabel>
          <RadioGroup
            aria-label="gender"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
            style={{ flexDirection: 'row' }}
          >
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="sms" control={<Radio />} label="SMS" />
            <FormControlLabel value="push" control={<Radio />} label="Push" />
          </RadioGroup>
        </FormControl>
        {notification.email && (
          <AddNotificationEmailForm
            loader={false}
            error=""
            setError={setError}
            setOverlay={setOverlay}
          />
        )}
        {notification.sms && (
          <AddNotificationSMSForm
            loader={false}
            error=""
            setError={setError}
            setOverlay={setOverlay}
          />
        )}
        {notification.push && (
          <AddNotificationPushForm
            loader={false}
            error=""
            setError={setError}
            setOverlay={setOverlay}
          />
        )}
      </div>
    </>
  );
};

export default AddNotification;
