import classNames from 'classnames';
import { PriceManagement, SuperAdminAccessControl } from 'components';
import roles from 'components/AdminRoles/roles.enum';
import React from 'react';
import { connect } from 'react-redux';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

const Settings: React.FC = () => (
  <>
    <div className={classNames(commonStyles.cardBg)}>
      <SuperAdminAccessControl rules={[roles.superadmin]}>
        <PriceManagement />
      </SuperAdminAccessControl>
      {/* <MasterConditionManagement /> */}
    </div>
  </>
);

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
