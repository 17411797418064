import {
  AccessControl,
  DataTable,
  hasAccess,
  Modal,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  PostCreatedBlocked,
  PostCreatedDelete,
} from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { getPostReportDetail } from 'actions';
import { getDateAndTime } from 'utils/dateMethods';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import blockIcon from '../../assets/svg/block.svg';
import unblock from '../../assets/svg/unblock.svg';
import deleteIcon from '../../assets/svg/delete.svg';
import accordion from '../Accordion/accordion.module.scss';

interface Props {
  id: any
  fetchUserInfo: any;
  postreportdetail: any;
}

const PostReportDetail: React.FC<Props> = (props: Props) => {
  const { fetchUserInfo, postreportdetail, id } = props;
  const [selected, setSelected] = React.useState<string[]>([]);
  const [showModal, setShowModal] = React.useState(false);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });
  const history = useHistory();
  const [blocked, setBlocked] = React.useState<any>({
    show: false,
    id: null,
  });
  const [deleted, setDeleted] = React.useState<any>({
    show: false,
    id: null,
  });
  const [unblocked, setUnBlocked] = React.useState<any>({
    show: false,
    id: null,
  });

  const hideActions = () => hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.ADD)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.EDIT)
    || hasAccess(PERMISSION_KEYS.REPORT_USER_MANAGEMENT, PERMISSION_TYPES.DELETE);

  React.useEffect(() => {
    fetchUserInfo(filter);
  }, [fetchUserInfo, filter]);
  const handleBlockUnblock = (modalValue: any, blockedid: string) => {
    setShowModal(true);
    switch (modalValue) {
      case 'blocked':
        setBlocked({ ...blocked, show: true, id: blockedid });
        break;
      case 'unblocked':
        setUnBlocked({ ...unblocked, show: true, id: blockedid });
        break;
      case 'delete':
        setDeleted({ ...deleted, show: true, id: blockedid });
        break;
      default:
        break;
    }
  };

  const actionButtons = (action: any, idS: any) => (
    <>
      <div className={commonStyles.actionBtnsWrapper}>
        <AccessControl
          permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
          accessType={PERMISSION_TYPES.DELETE}
        >
          <div className={commonStyles.actionBtnCover}>
            <img
              src={deleteIcon}
              role="presentation"
              alt="delete"
              width="35"
              title="Delete"
              height="35"
              onClick={() => handleBlockUnblock('delete', idS)}
            />
          </div>
        </AccessControl>
        <AccessControl
          permissionKey={PERMISSION_KEYS.REPORT_USER_MANAGEMENT}
          accessType={PERMISSION_TYPES.EDIT}
        >
          <div className={commonStyles.actionBtnCover}>
            {action === 'pending' ? null : (action === 'unblocked' ? (
              <img
                src={blockIcon}
                role="presentation"
                alt="block"
                width="35"
                title="Block"
                height="35"
                onClick={() => handleBlockUnblock('blocked', idS)}
              />
            ) : (
              <img
                src={unblock}
                role="presentation"
                alt="unblock"
                width="35"
                title="Unblock"
                height="35"
                onClick={() => handleBlockUnblock('unblocked', idS)}
              />
            ))}
          </div>
        </AccessControl>
      </div>
    </>
  );

  function createData(data: any, index: number): any {
    const id_ = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      description: data.description !== null ? (data.description.length > 20
        ? (
          <div title={data.description}>
            {`${data.description.substring(0, 20)}...`}
          </div>
        ) : data.description) : '',
      reportscount: data.reportCount,
      createdOn: getDateAndTime(data.createdAt),
      ...(hideActions() && {
        action: actionButtons(data.status, id_),
      }),
    };
  }

  const rows: any = [];
  if (postreportdetail !== undefined
    && postreportdetail.data !== null
    && postreportdetail.data) {
    postreportdetail.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName !== 'img') {
      history.push(`/reportusermanagement/reportuserinfo/${id}/reportedpost/${name.id}`);
    }
  };

  const headerCellsUserReports: any = hideActions() ? [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'description', label: 'Description',
    },
    {
      id: 'reportscount', label: 'Reports count',
    },
    {
      id: 'createdOn', label: 'Last Reported Date & Time',
    },
    {
      id: 'action', label: 'Action',
    },
  ] : [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'description', label: 'Description',
    },
    {
      id: 'reportscount', label: 'Reports count',
    },
    {
      id: 'createdOn', label: 'Last Reported Date & Time',
    },
  ];
  const handleModalClose = () => {
    setShowModal(false);
    setBlocked(false);
    setDeleted({ ...deleted, show: false, id: null });
    setBlocked({ ...blocked, show: false, id: null });
    setUnBlocked({ ...unblocked, show: false, id: null });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = postreportdetail !== undefined
    && postreportdetail.data !== null
    ? postreportdetail.data['Pagination-Count'] : 0;

  return (
    <>
      <Modal
        id="block-user"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {
          deleted.show && (
            <PostCreatedDelete
              id={deleted.id}
              handleModalClose={handleModalClose}
              fetchPostList={() => { setFilter({ ...filter, page: 0 }); }}
            />
          )
        }
        {
          blocked.show && (
            <PostCreatedBlocked
              handleModalClose={handleModalClose}
              fetchPostList={() => { setFilter({ ...filter, page: 0 }); }}
              id={blocked.id}
              status="blocked"
            />
          )
        }
        {
          unblocked.show && (
            <PostCreatedBlocked
              handleModalClose={handleModalClose}
              fetchPostList={() => { setFilter({ ...filter, page: 0 }); }}
              id={unblocked.id}
              status="unblocked"
            />
          )
        }
      </Modal>
      <div className={classNames(commonStyles.dataTableCover, accordion.accordionTable)}>
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={postreportdetail.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { postreportdetail } = state;
  return {
    postreportdetail,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserInfo: (payload: any) => dispatch(getPostReportDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostReportDetail);
