import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  BreadCrumbs,
  NamedLink,
  Notification as NotificationComponent,
} from 'components';
import { FormattedMessage } from '../../../utils/reactIntl';

import scss from './TopbarDesktop.module.scss';
import userbig from '../../../assets/svg/userbig.svg';
import setting from '../../../assets/svg/settings.svg';
import logout from '../../../assets/svg/logout.svg';

interface Props {
  className?: string,
  rootClassName?: string,
  isAuthenticated: boolean,
  history: any,
  logoutAction: any,
}

const TopbarDesktop = (props: Props) => {
  const {
    className,
    rootClassName,
    isAuthenticated,
    history,
    logoutAction,
  } = props;
  const [mounted, setMounted] = useState(false);
  const handleLogout = () => {
    logoutAction();
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || scss.root, className);
  const profileMenu = authenticatedOnClientSide ? (
    <p role="presentation" className={scss.logout} onClick={handleLogout}>
      <img src={logout} alt="userprofile" />
      <FormattedMessage id="TopbarDesktop.logout" />
    </p>
  ) : null;

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={scss.loginLink}>
      <span className={scss.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  function pageTiTle() {
    if (history.location.pathname.includes('/myProfile')) {
      return (
        <NamedLink className={scss.logoLink} name="MyProfile">
          My Profile
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/usermanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="UserManagement">
          User Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/channelmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="ChannelManagement">
          Channel Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/domesmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="DomesManagement">
          Domes Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/subdomesmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="SubDomesManagement">
          Sub Domes Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/rolemanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="RoleManagement">
          Role Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/rewardpoints')) {
      return (
        <NamedLink className={scss.logoLink} name="RewardPointManagement">
          Reward Point
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/subadminmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="SubAdminManagement">
          Sub Admin Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/subadminmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="SubAdminManagement">
          SubAdmin Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/reportusermanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="ReportUserManagement">
          Report User Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/contentmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="Settings">
          Content Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/notification')) {
      return (
        <NamedLink className={scss.logoLink} name="Settings">
          Notification Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/settings')) {
      return (
        <NamedLink className={scss.logoLink} name="Settings">
          Settings
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/masterreason')) {
      return (
        <NamedLink className={scss.logoLink} name="MasterReason">
          Master Reason Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/livestreaming')) {
      return (
        <NamedLink className={scss.logoLink} name="LiveStreaming">
          Live Streaming
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/feedback')) {
      return (
        <NamedLink className={scss.logoLink} name="Feedback">
          Rating(Feedback) Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/versionmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="VersionManagement">
          Version Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/paymentmanagement')) {
      return (
        <NamedLink className={scss.logoLink} name="PaymentManagement">
          Payment Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/support')) {
      return (
        <NamedLink className={scss.logoLink} name="SupportManagement">
          Support Management
        </NamedLink>
      );
    }
    if (history.location.pathname.includes('/coinactivity')) {
      return (
        <NamedLink className={scss.logoLink} name="CoinActivity">
          Avatcoin Activity Management
        </NamedLink>
      );
    }
    return '';
  }

  return (
    <nav className={classes}>
      <div>
        {pageTiTle()}
        <BreadCrumbs />
      </div>
      <div className={scss.rightSide}>
        <NotificationComponent />
        <div className={scss.userInfo}>
          <img src={userbig} alt="userprofile" />
          <h4>{localStorage.getItem('username')}</h4>
          <div className={scss.userSubmenu}>
            <ul>
              <li role="presentation" onClick={() => history.push('/myProfile')}>
                <p>
                  <img
                    role="presentation"
                    src={userbig}
                    alt="userprofile"
                  />
                  My Profile
                </p>
              </li>
              <li role="presentation" onClick={() => history.push('/settings')}>
                <p>
                  <img src={setting} alt="setting" />
                  Settings
                </p>
              </li>
              <li>
                {profileMenu}
              </li>
            </ul>
          </div>
        </div>
        {loginLink}
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
};

export default TopbarDesktop;
