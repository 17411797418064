import constant from './constant';
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const postApiCall = (
  endPoint: string,
  params: any,
) => constant.axios
  .post(endPoint, params);

/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */

const getApiCall = (
  endPoint: string,
  params: any = undefined,
) => constant.axios
  .get(endPoint, { params });
/** ******************************************************************************************* */
/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const deleteApiCall = (
  endPoint: string,
  params: any = '',
) => constant.axios
  .delete(endPoint, { data: params });
/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (
  endPoint: string,
  params: any,
) => {
  constant.axios
    .patch(endPoint, params);
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const putApiCall = (
  endPoint: string,
  params: any,
) => constant.axios
  .put(endPoint, params);

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const contentApiCall = (
  endPoint: string,
) => {
  constant.axios
    .get(endPoint);
};

/**
 * export all function
 */
export default {
  postApiCall,
  getApiCall,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  contentApiCall,
};
