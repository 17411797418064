import api from '../api';

export class UserManagementService {
  static async getUserList(payload: any) {
    const {
      page,
      rowsPerPage,
      search,
      startDate,
      endDate,
      userType,
      isSortByUserName,
      isSortByRegister,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}${startDate !== '' ? `&startDate=${startDate}` : ''}${endDate !== '' ? `&endDate=${endDate}` : ''}${userType !== 'Select' && userType !== '' && userType !== undefined ? `&userType=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByUserName !== undefined && isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}${isSortByRegister !== undefined && isSortByRegister !== '' ? `&isSortByRegister=${isSortByRegister}` : ''}`;

    return api.getApiCall(`/api/v1/admins/users?${apiEndPoint}`);
  }

  static async exportUserListToMail(payload: any) {
    const {
      search,
      startDate,
      endDate,
      userType,
      isSortByUserName,
      isSortByRegister,
      type,
    } = payload;
    const apiEndPoint = `${startDate !== '' && startDate !== undefined ? `&startDate=${startDate}` : ''}${endDate !== '' && endDate !== undefined ? `&endDate=${endDate}` : ''}${userType !== 'Select' && userType !== '' && userType !== undefined ? `&userType=${userType}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByUserName !== undefined && isSortByUserName !== '' ? `&isSortByUserName=${isSortByUserName}` : ''}${isSortByRegister !== undefined && isSortByRegister !== '' ? `&isSortByRegister=${isSortByRegister}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/users/exports?${apiEndPoint}`);
  }
}
