import React, { Suspense } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';

import PropTypes from 'prop-types';
import {
  Environment, OrbitControls, Html, useProgress,
} from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      {progress}
      % loaded
    </Html>
  );
}

const ModelMain = ({ modelURL }) => {
  const gltf = useLoader(GLTFLoader, modelURL);
  return (
    <>
      <primitive object={gltf.scene} scale={1} />
    </>
  );
};

const Model = (props) => {
  const { modelURL } = props;
  return (
    <Canvas>
      <Suspense fallback={<Loader />}>
        <ModelMain modelURL={modelURL} />
        <OrbitControls />
        <Environment preset="sunset" />
      </Suspense>
    </Canvas>
  );
};

Model.defaultProps = {
  modelURL: '',
};

const {
  string,
} = PropTypes;

Model.propTypes = {
  modelURL: string,
};

export default Model;
