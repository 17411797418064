import { ActionTypes } from '../constants/index';

export const fetchPostCreated = (payload: any) => ({
  type: ActionTypes.POST_CREATED_LIST_REQUEST,
  payload: {
    id: payload.id,
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
  },
});
