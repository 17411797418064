import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'utils/reactIntl';
import {
  Button,
  FieldSelect,
  FieldTextInput,
  CircularProgressLoader,
  ValidationError,
} from 'components';
import {
  ChannelManagementService,
  SubDomesManagementService,
} from 'services';
import classNames from 'classnames';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';
import subdomes from '../AddSubDomesComponent/AddSubDomes.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  handleSubmit: any;
  handleSaveAsDraftPut: any;
  error: string;
  id: string;
}

const EditSubDomesForm: React.FC<Props> = (props: Props) => {
  const {
    handleSubmit,
    intl,
    id,
    error,
    handleSaveAsDraftPut,
  } = props;
  const [domes, setDomes] = React.useState([]);
  const [initialLoader, setInitialLoader] = React.useState(true);
  const editSubDomesSchema = yup.object().shape({
    domes: yup.string().required(intl.formatMessage({ id: 'EditSubDomes.error.domes.required' })),
    domeName: yup.string()
      .required(intl.formatMessage({ id: 'EditSubDomes.error.domeName.required' })),
    description: yup.string()
      .required(intl.formatMessage({ id: 'EditSubDomes.error.description.required' }))
      .max(250, intl.formatMessage({ id: 'EditSubDomes.error.description.max' })),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(editSubDomesSchema),
  });

  React.useEffect(() => {
    ChannelManagementService.getDomes().then((data) => {
      const domesData: any = [];
      if (data.data.data !== undefined) {
        data.data.data.forEach((ele: any) => {
          domesData.push({ key: ele._id, label: ele.name });
        });
      }
      setDomes(domesData);
    });
    SubDomesManagementService.getDetails(id).then((data: any) => {
      formProps.reset({
        domes: data.data.data.parentId._id,
        domeName: data.data.data.name,
        description: data.data.data.description,
        selectedIcon: data.data.data.selectedImage,
        unselectedIcon: data.data.data.nonSelectedImage,
      });
      setInitialLoader(false);
    });
  }, [formProps, id]);

  return (
    <>
      {initialLoader ? (
        <div>
          <CircularProgressLoader />
        </div>
      )
        : (
          <div className={classNames(commonStyles.cardBg, commonStyles.addDetailsPage)}>
            <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
              <h2 className={commonStyles.sign_text}>
                {intl.formatMessage({ id: 'EditSubDomes.label.title' })}
              </h2>
              <div className="flex_row">
                <div className="flex_col_sm_6">
                  <div className={subdomes.userType}>
                    <FieldSelect
                      id="domes"
                      name="domes"
                      label={intl.formatMessage({ id: 'EditSubDomes.label.domes' })}
                      variant={false}
                      formProps={formProps}
                      options={domes}
                    />
                  </div>
                </div>
                <div className="flex_col_sm_6">
                  <FieldTextInput
                    id="domeName"
                    name="domeName"
                    formName="editSubDomes"
                    className="textInput"
                    type="text"
                    formProps={formProps}
                    label={intl.formatMessage({ id: 'EditSubDomes.label.subDome' })}
                  />
                </div>
                <div className="flex_col_sm_12">
                  <div>
                    <FieldTextInput
                      id="description"
                      name="description"
                      formName="editSubDomes"
                      className="textInput"
                      type="textarea"
                      formProps={formProps}
                      label={intl.formatMessage({ id: 'EditSubDomes.label.description' })}
                    />
                  </div>
                </div>
              </div>
              {Object.keys(formProps.formState.errors).length > 0
                && (
                  <ValidationError
                    className={commonStyles.validation_error}
                    error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
                  />
                )}
              {
                Object.keys(formProps.formState.errors).length === 0
                && <ValidationError error={error} />
              }
              <div className={commonStyles.buttonWrapper}>
                <Button
                  onClick={(e: any) => handleSaveAsDraftPut(e, formProps.getValues())}
                >
                  {intl.formatMessage({ id: 'EditSubDomes.label.saveAsDraft' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  {intl.formatMessage({ id: 'EditSubDomes.label.publish' })}
                </Button>
              </div>
            </form>
          </div>
        )}
    </>
  );
};

export default injectIntl(EditSubDomesForm);
