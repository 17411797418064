import api from 'api';
import moment from 'moment';

export class ManageVersionManagement {
  static async getVersion(payload: any) {
    const {
      page,
      rowsPerPage,
      search,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `?limit=${rowsPerPage}&pageNo=${page + 1}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}`;
    return api.getApiCall(`/api/v1/admins/app-versions${apiEndPoint}`);
  }

  static async manageVersionManagement(payload: any) {
    const date = moment(payload.date).format('YYYY-MM-DD');
    const time = moment(payload.time).format('HH:mm:ss');
    const datemodified = moment(`${date}T${time}`).utc();
    const data = {
      versionNo: payload.versionNumber,
      platform: payload.platform,
      launchType: payload.launch,
      description: payload.description,
      dateTime: datemodified,
    };
    return api.postApiCall('/api/v1/admins/app-versions', data);
  }

  static async exportAPI(payload: any) {
    const {
      page,
      rowsPerPage,
      search,
      type,
    } = payload;
    const apiEndPoint = `?limit=${rowsPerPage}&pageNo=${page + 1}${search !== '' && search !== undefined ? `&search=${search}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/app-versions${apiEndPoint}`);
  }
}
