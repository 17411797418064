import { ActionTypes } from '../constants/index';

export const fetchRolesList = (payload: any) => ({
  type: ActionTypes.ROLES_MGMT_REQUEST,
  payload: {
    page: payload.page,
    rowsPerPage: payload.rowsPerPage,
    startDate: payload.startDate,
    endDate: payload.endDate,
    userType: payload.userType,
    search: payload.search,
    isSortByRole: payload.isSortByRole,
    isSortByCreated: payload.isSortByCreated,
  },
});
