import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import OtpInput from 'react-otp-input';
import Countdown, { zeroPad } from 'react-countdown';
import {
  Button, ValidationError, SuccessMessage, DotsLoader,
} from '..';
import { injectIntl } from '../../utils/reactIntl';
import scss from './OtpVerification.module.scss';
import otpLock from '../../assets/svg/ic_otp_locked.svg';

interface Props {
  handleOtpSubmit: any
  forgotScreenOtp?: boolean
  showLoader?: boolean
  handleResendOTP: any
  invalid: any
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const OtpVerificationComponent: React.FC<Props> = (props: Props) => {
  const {
    handleOtpSubmit,
    forgotScreenOtp,
    handleResendOTP,
    invalid,
    showLoader,
  } = props;
  const [otp, setOtp] = useState('');
  const [otpResendSuccess, setOtpResendSuccess] = useState(false);
  const [countdownKey, setCountdownKey] = useState(1);
  const counterTime = useRef(Date.now() + 120000);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (otp.length === 4) handleOtpSubmit(otp);
  };

  const handleOtpChange = (value: any) => {
    setOtp(value);
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return (
        <>
          <span className={scss.showlabel}>
            <span className={scss.timer}>
              {` ${zeroPad(minutes)}`}
              :
              {zeroPad(seconds)}
            </span>
          </span>
          <div className={scss.resend_wrapper}>
            <p>Haven’t received the code?</p>
            <p
              role="presentation"
              style={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleResendOTP().then(() => {
                  setOtpResendSuccess(true);
                  counterTime.current = Date.now() + 120000;
                  setCountdownKey(countdownKey + 1);
                });
              }}
            >
              Resend OTP
            </p>
          </div>
        </>
      );
    }
    return (
      <span className={scss.showlabel}>
        <span className={scss.timer}>
          {` ${zeroPad(minutes)}`}
          :
          {zeroPad(seconds)}
        </span>
      </span>
    );
  };

  return (
    <div className={scss.otp_box}>
      <figure className={scss.lock_icon}>
        <img src={otpLock} alt="OTP-lock" />
      </figure>
      <h2>Authentication Required</h2>
      <p className="info">
        We have sent a 4-digit One Time Password (OTP) to your email & phone number. Please enter to complete the verification process.
      </p>
      <form onSubmit={handleSubmit} className={scss.otp_wrappr}>
        <div className={scss.otpInput}>
          <OtpInput
            numInputs={4}
            value={otp}
            // placeholder="----"
            onChange={(value: string) => {
              handleOtpChange(value);
            }}
          />
          <ValidationError
            error={invalid ? 'Wrong OTP Entered' : ''}
            className={scss.errorMargin}
          />
        </div>
        {otpResendSuccess ? (
          <SuccessMessage message="Otp has been resent successfully." />
        ) : null}
        {!forgotScreenOtp ? (
          <div className="form_field">
            <>
              <Countdown
                date={counterTime.current}
                renderer={renderer}
                key={countdownKey}
              />
            </>
          </div>
        ) : null}
        <Button
          disabled={otp.length !== 4}
          className="fill_red_btn btn-effect"
          buttonType={ButtonTypes.submit}
        >
          {showLoader ? (<DotsLoader />) : 'Verify'}
        </Button>
      </form>
    </div>
  );
};

OtpVerificationComponent.defaultProps = {
  forgotScreenOtp: false,
  showLoader: false,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = () => ({
  // resendOTP: (data: any) => dispatch(resendOTPRequestSignup(data)),
});

const OtpVerification = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(OtpVerificationComponent);

export default OtpVerification;
