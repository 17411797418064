import api from '../api';

export class PriceManagementService {
  static async getPriceManagement(payload: any) {
    const {
      page,
      rowsPerPage,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}`;
    return api.getApiCall(`api/v1/admins/commissions?${apiEndPoint}`);
  }

  static async addPriceEntry(payload: any) {
    return api.postApiCall('api/v1/admins/commissions', payload);
  }

  static async updatePriceEntry(payload: any) {
    return api.putApiCall('api/v1/admins/commissions', payload);
  }

  static async deletePriceEntry(payload: any) {
    return api.deleteApiCall('api/v1/admins/commissions', {
      commissionId: payload,
    });
  }

  static async blockPriceEntry(payload: any) {
    return api.putApiCall('api/v1/admins/commissions/activate-deactivate', payload);
  }
}
