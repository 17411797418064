import { DataTable } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { getUserReportedUser } from 'actions';
import { getDateAndTime } from 'utils/dateMethods';
import classNames from 'classnames';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import accordion from '../Accordion/accordion.module.scss';

interface Props {
  fetchUserInfo: any;
  userreportdetail: any;
  id: any;
}

const UserReportDetail: React.FC<Props> = (props: Props) => {
  const { fetchUserInfo, userreportdetail, id } = props;
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });

  React.useEffect(() => {
    fetchUserInfo(filter);
  }, [fetchUserInfo, filter]);

  function createData(data: any, index: number): any {
    const id_ = data._id;

    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      reporter: data.user?.username,
      reason: data.reason,
      createdOn: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (userreportdetail.data !== null && userreportdetail.data) {
    userreportdetail.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'reporter', label: 'Reporter Username',
    },
    {
      id: 'reason', label: 'Reason',
    },
    {
      id: 'createdOn', label: 'Reported Date & Time',
    },
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = userreportdetail.data ? userreportdetail.data['Pagination-Count'] : 0;
  return (
    <>
      <div className={classNames(commonStyles.dataTableCover, accordion.accordionTable)}>
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={userreportdetail.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { userreportdetail } = state;
  return {
    userreportdetail,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserInfo: (payload: any) => dispatch(getUserReportedUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserReportDetail);
