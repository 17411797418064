import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { CreditActivityDetails } from '../modal';

export default function reducer(
  state: CreditActivityDetails = new CreditActivityDetails(),
  action: AnyAction,
) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.DEBIT_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.DEBIT_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.DEBIT_ACTIVITY_LIST_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
