import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import {
  CircularProgressLoader,
  Modal,
  PostDetailReportedUserInfo,
  AccessControl,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  PostCreatedBlocked,
  PostCreatedDelete,
  Accordion,
} from '..';
import { getPostDetails } from '../../actions';
import deleteImg from '../../assets/svg/delete.svg';
import blockImg from '../../assets/svg/block.svg';
import exportImg from '../../assets/svg/export.svg';
import { dateFormatting } from '../../utils/dateMethods';
import unblockImg from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  postdetails: any;
  id: any;
  fetchPostDetails: any;
  intl: any;
}

const PostDetailsComponent: React.FC<Props> = (props: Props) => {
  const {
    postdetails,
    id,
    fetchPostDetails,
    intl,
  } = props;

  React.useEffect(() => {
    fetchPostDetails(id);
  }, [fetchPostDetails, id]);

  const [showModal, setShowModal] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showBlock, setShowBlock] = React.useState(false);

  const handleModalClose = React.useCallback(() => {
    setShowDelete(false);
    setShowModal(false);
    setShowBlock(false);
  }, []);

  return (
    <>
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className={commonStyles.modal_container}>
          {
            showBlock && (
              <PostCreatedBlocked
                handleModalClose={handleModalClose}
                fetchPostList={() => { fetchPostDetails(id); }}
                id={id}
                status={postdetails.data.status === 'unblocked' ? 'blocked' : 'unblocked'}
              />
            )
          }
          {showDelete && (
            <PostCreatedDelete
              id={id}
              handleModalClose={handleModalClose}
              fetchPostList={() => { fetchPostDetails(id); }}
            />
          )}
        </div>
      </Modal>
      {
        postdetails.data === null || postdetails.loadingInProgress
          ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.headerActions}>
                  <div>
                    {/* <Button
                      className={commonStyles.button}
                      onClick={() => {
                        setShowModal(true);
                        setSendNotification(true);
                      }}
                    >
                      {intl.formatMessage({ id: 'ChannelInfo.label.SendNotification' })}
                    </Button> */}
                  </div>
                  <div className={commonStyles.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          src={exportImg}
                          alt="exportImg"
                          title="Export"
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          src={deleteImg}
                          alt="deleteImg"
                          role="presentation"
                          title="Delete"
                          onClick={() => {
                            setShowModal(true);
                            setShowDelete(true);
                          }}
                        />
                      </span>
                    </AccessControl>

                    <AccessControl
                      permissionKey={PERMISSION_KEYS.CHANNEL_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      {postdetails.data.status === 'unblocked' ? (
                        <span className={commonStyles.iconWrapper}>
                          <img
                            src={blockImg}
                            alt="blockImg"
                            title="Block"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      ) : (
                        <span className={commonStyles.iconWrapper}>
                          <img
                            src={unblockImg}
                            alt="unblockImg"
                            title="unblock"
                            role="presentation"
                            onClick={() => {
                              setShowModal(true);
                              setShowBlock(true);
                            }}
                          />
                        </span>
                      )}
                    </AccessControl>
                  </div>
                </div>
                <div className={commonStyles.userInformatinc}>
                  <div className={commonStyles.profileImgWrapper}>
                    {postdetails.data.media[0].thumbnailImageUrl !== ''
                      && postdetails.data.media[0].thumbnailImageUrl !== undefined
                      ? (
                        <img
                          className={commonStyles.profileImg}
                          src={postdetails.data.media[0].thumbnailImageUrl}
                          alt="profileImg"
                        />
                      )
                      : <img src={postdetails.data.media[0].thumbnailImageUrl} alt="profile" />}
                  </div>
                  <div className={commonStyles.userInfoinner}>
                    <div>
                      <h2>Title:</h2>
                      <h2>{postdetails.data.channelName}</h2>
                      <h3>{intl.formatMessage({ id: 'ChannelInfo.label.description' })}</h3>
                      <p>
                        {postdetails.data.description}
                      </p>
                    </div>
                    <div className={commonStyles.right_container}>
                      <h3>
                        {intl.formatMessage({ id: 'ChannelInfo.label.createdOn' })}
                        :
                        <span>
                          {dateFormatting(postdetails.data.createdAt)}
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <hr />
                <div className={commonStyles.column}>
                  <h2>Other Media</h2>
                  <video width="320" height="240" controls>
                    <source src={postdetails.data.media[0].filePath} type="video/mp4" />
                    <track src="" kind="captions" srcLang="en" label="english_captions" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className={commonStyles.postDetails}>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Like
                      </p>
                      <p className={commonStyles.count_backround}>
                        {postdetails.data.likeCount}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Reactions
                      </p>
                      <p className={commonStyles.count_backround}>
                        {postdetails.data.link}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Reports Count
                      </p>
                      <p className={commonStyles.count_backround}>
                        {postdetails.data.reportCount}
                      </p>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Post type
                      </p>
                      <p className={commonStyles.count_backround}>
                        {postdetails.data.postType}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Dome
                      </p>
                      <p className={commonStyles.count_backround}>
                        0
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Accordion heading="Post Report Detail">
                <PostDetailReportedUserInfo id={id} />
              </Accordion>
            </>
          )
      }
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { postdetails } = state;
  return {
    postdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchPostDetails: (payload: any) => dispatch(getPostDetails(payload)),
});

const PostDetails = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PostDetailsComponent);

export default PostDetails;
