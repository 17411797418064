import api from '../api';

export class FeedbackManagementService {
  static async getFeedbackService(payload: any) {
    const {
      page,
      rowsPerPage,
      ratings,
      search,
      isSortByCreated,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page + 1}${ratings !== '' && ratings !== undefined ? `&rating=${ratings}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== '' && isSortByCreated !== undefined ? `&isSortByCreated=${isSortByCreated}` : ''}`;
    return api.getApiCall(`/api/v1/admins/feedbacks?${apiEndPoint}`);
  }

  static async getAverge() {
    return api.getApiCall('/api/v1/admins/feedbacks/average-rating');
  }
}
