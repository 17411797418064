import axios from 'axios';

/**
 * setup axios instance
 */
const $axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
    platform: '3',
    language: 'en',
    timezone: '0',
  },
});

// const API_ERROR_CODE = {
//   emailExits: 409,
//   timeout: 408,
//   invalidEmail: 400,
//   inValidUser: 401,
//   sessionExpired: 423,
// };

export default {
  axios: $axios,
  // paramsSerializers,
};
