import { ActionTypes } from '../constants/index';

export const getUserList = (payload: any) => ({
  type: ActionTypes.USER_MGMT_LIST_REQUEST,
  payload: {
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
    startDate: payload.startDate,
    endDate: payload.endDate,
    userType: payload.userType,
    search: payload.search,
    isSortByUserName: payload.isSortByUserName,
    isSortByRegister: payload.isSortByRegister,
  },
});
