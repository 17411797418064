import { Button, ValidationError } from 'components';
import {
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ContentManagementService } from 'services';
import { useHistory } from 'react-router-dom';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  id: string,
  answer: any;
  question: any;
}

const EditFAQs: React.FC<Props> = (props: Props) => {
  const { answer, question, id } = props;
  let blocksFromHTML = convertFromHTML(answer);
  const [answerState, setAnswerState] = React.useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )),
  );
  blocksFromHTML = convertFromHTML(question);
  const [questionState, setQuestionState] = React.useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )),
  );
  const [error, setError] = React.useState('');
  const history = useHistory();
  return (
    <>
      <h2>Edit FAQs</h2>
      <h2>Question</h2>
      <Editor
        editorState={questionState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(e) => setQuestionState(e)}
        toolbar={{
          options: ['inline', 'textAlign'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
          },
        }}
      />
      <h2>Answer</h2>
      <Editor
        editorState={answerState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={(e) => setAnswerState(e)}
        toolbar={{
          options: ['inline', 'textAlign'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center', 'right', 'justify'],
          },
        }}
      />
      {error.length > 0 && <ValidationError error={error} />}
      <div className={commonStyles.buttonWrapper}>
        <Button
          buttonType={ButtonTypes.button}
          onClick={() => {
            if (answerState.getCurrentContent().getPlainText().length === 0
              || questionState.getCurrentContent().getPlainText().length === 0) {
              setError('Please fill all fields.');
            } else {
              ContentManagementService.editFAQs(
                {
                  faqId: id,
                  status: 'draft',
                  question: stateToHTML(questionState.getCurrentContent()),
                  answer: stateToHTML(answerState.getCurrentContent()),
                },
              ).then(() => {
                setError('');
                history.push('/contentmanagement?active=FAQ');
              });
            }
          }}
        >
          Save as Draft
        </Button>
        <Button
          buttonType={ButtonTypes.button}
          onClick={() => {
            if (answerState.getCurrentContent().getPlainText().length === 0
              || questionState.getCurrentContent().getPlainText().length === 0) {
              setError('Please fill all fields.');
            } else {
              ContentManagementService.editFAQs(
                {
                  question: stateToHTML(questionState.getCurrentContent()),
                  answer: stateToHTML(answerState.getCurrentContent()),
                  faqId: id,
                  status: 'unblocked',
                },
              ).then(() => {
                setError('');
                history.push('/contentmanagement?active=FAQ');
              });
            }
          }}
        >
          Publish
        </Button>
      </div>
    </>
  );
};

export default EditFAQs;
