import api from 'api';

export class MasterReasonManagement {
  static async addMasterReason(payload: any) {
    return api.postApiCall('/api/v1/admins/master-reasons', payload);
  }

  static async getMasterReasonManagementList(payload: any) {
    const {
      page,
      rowsPerPage,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}`;
    return api.getApiCall(`/api/v1/admins/master-reasons?${apiEndPoint}`);
  }

  static async editMasterReason(payload: any) {
    return api.putApiCall('/api/v1/admins/master-reasons', payload);
  }

  static async deleteMasterReason(payload: any) {
    return api.deleteApiCall('api/v1/admins/master-reasons', payload);
  }

  static async blockEntry(payload: any) {
    return api.putApiCall('api/v1/admins/master-reasons/activate-deactivate', payload);
  }
}
