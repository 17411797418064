import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { EventDetail } from '../modal';

export default function reducer(state: EventDetail = new EventDetail(), action: AnyAction) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.EVENT_DETAIL_LIST_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.EVENT_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.EVENT_DETAIL_LIST_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
