import { DataTable } from 'components';
import React from 'react';
import { connect } from 'react-redux';
import { getNotificationDetails } from 'actions';
import { getDateAndTime } from 'utils/dateMethods';

import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import classNames from 'classnames';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import accordion from '../Accordion/accordion.module.scss';

interface Props {
  id: any
  fetchNotificationReport: any;
  notificationdetails: any;
}

const NotificationDetail: React.FC<Props> = (props: Props) => {
  const { fetchNotificationReport, notificationdetails, id } = props;
  const [selected, setSelected] = React.useState([]);
  const [filter, setFilter] = React.useState({
    id,
    page: 0,
    rowsPerPage: 10,
  });

  React.useEffect(() => {
    fetchNotificationReport(filter);
  }, [fetchNotificationReport, filter]);

  function createData(data: any, index: number): any {
    const id_ = data._id;
    const blocksFromHTML = convertFromHTML(data.message);
    const htmlData = EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    ));
    const desc = (
      <div title={htmlData.getCurrentContent().getPlainText()}>
        {htmlData.getCurrentContent().getPlainText().length > 20 ? `${htmlData.getCurrentContent().getPlainText().substring(0, 20)}...` : htmlData.getCurrentContent().getPlainText()}
      </div>
    );
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: id_,
      title: data.title,
      description: desc,
      sentBy: data.sendBy,
      createdOn: getDateAndTime(data.createdAt),
    };
  }

  const rows: any = [];
  if (notificationdetails !== undefined
    && notificationdetails.data !== null
    && notificationdetails.data) {
    notificationdetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const headerCellsUserReports: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'title', label: 'Title',
    },
    {
      id: 'description', label: 'Notification descripton',
    },
    {
      id: 'sentBy', label: 'Sent by',
    },
    {
      id: 'createdOn', label: 'Sent on',
    },
  ];

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };
  const count = notificationdetails !== undefined
    && notificationdetails.data !== null
    ? notificationdetails.data['Pagination-Count'] : 0;

  return (
    <>
      {/* <h2>Notification Details</h2> */}
      <div className={classNames(commonStyles.dataTableCover, accordion.accordionTable)}>
        <DataTable
          headCells_={headerCellsUserReports}
          rows_={rows}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={notificationdetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={() => null}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { notificationdetails } = state;
  return {
    notificationdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchNotificationReport: (payload: any) => dispatch(getNotificationDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetail);
