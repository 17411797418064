import React, { useState } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'utils/reactIntl';
import { connect } from 'react-redux';
import {
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Button } from 'components';
import { stateToHTML } from 'draft-js-export-html';
import Parser from 'html-react-parser';
import { ContentManagementService } from 'services';
import { getContentManagement } from 'actions';
import contentManagementScss from '../ContentManagement/contentmanagement.module.scss';
import edit from '../../assets/svg/edit.svg';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  contentmanagement: any;
  fetchContentManagement: any;
  intl: any;
}

const AboutUsComponent = (props: Props) => {
  const { contentmanagement, fetchContentManagement, intl } = props;
  const [editable, setEditable] = useState(false);
  const blocksFromHTML = convertFromHTML(contentmanagement.data[2].content);
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    )),
  );

  return (
    <>
      <h2 className={contentManagementScss.contentHeading}>
        {intl.formatMessage({ id: 'Aboutus.label.title' })}
        <button type="button" className={contentManagementScss.headingEdit}>
          <img
            src={edit}
            alt="edit"
            role="presentation"
            onClick={() => setEditable(true)}
          />
        </button>
      </h2>
      {editable
        ? (
          <div>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setEditorState(e)}
              toolbar={{
                options: ['inline', 'textAlign'],
                inline: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline'],
                },
                textAlign: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['left', 'center', 'right', 'justify'],
                },
              }}
            />
            <div className="text-center">
              <Button
                buttonType={ButtonTypes.button}
                onClick={() => {
                  ContentManagementService.updatePrivacyPolicy({
                    contentType: 'aboutus',
                    content: stateToHTML(editorState.getCurrentContent()),
                  }).then(() => {
                    fetchContentManagement();
                    setEditable(false);
                  });
                }}
              >
                {intl.formatMessage({ id: 'Aboutus.button.save' })}
              </Button>
            </div>
          </div>
        )
        : (
          <div>
            {Parser(contentmanagement.data[2].content)}
          </div>
        )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  contentmanagement: state.contentmanagement,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchContentManagement: () => dispatch(getContentManagement()),
});

const AboutUs = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(AboutUsComponent);

export default AboutUs;
