import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import routeConfiguration from '../../routes/routeConfiguration.js';
import { pathByRouteName, findRouteByRouteName } from '../../utils/routes';

interface Props extends RouteComponentProps<any>{
    name: string,
    params?: any,
    title?: string,
    children: any,
    to?: any,
    className?: string,
    activeClassName?: any,
    style?: any,

    // withRouter props
    history: any,
    location: any,
    match: any
}

export const NamedLinkComponent : React.FC<Props> = (props: Props) => {
  const { name, params, title } = props;
  const routes = routeConfiguration();
  const onOver = () => {
    const { component: Page } = findRouteByRouteName(name, routes);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  // Link props
  const { to, children } = props;
  const pathname = pathByRouteName(name, routes, params);
  const { match } = props;
  const active = match.url && match.url === pathname;

  // <a> element props
  const { className, style, activeClassName } = props;
  const aElemProps = {
    className: classNames(className, { [activeClassName]: active }),
    style,
    title,
  };

  return (
    <Link onMouseOver={onOver} onTouchStart={onOver} to={{ pathname, ...to }} {...aElemProps}>
      {children}
    </Link>
  );
};

NamedLinkComponent.defaultProps = {
  params: {},
  to: {},
  className: '',
  style: {},
  activeClassName: 'NamedLink_active',
  title: '',
};

// This ensures a nice display name in snapshots etc.
NamedLinkComponent.displayName = 'NamedLink';

const NamedLink = withRouter(NamedLinkComponent);
NamedLink.displayName = 'NamedLink';

export default NamedLink;
