import { Button } from 'components';
import React from 'react';
import { Notification } from 'services';
import warning from '../../assets/svg/warning.svg';
import modalStyles from '../Modal/Modal.module.scss';

interface Props {
  handleModalClose: any;
  id: any;
  fetchAdminList: any;
  setShowOverlay: any;
  status: any;
}
const NotificationManagementShowSave: React.FC<Props> = (props: Props) => {
  const {
    handleModalClose,
    id,
    fetchAdminList,
    setShowOverlay,
    status,
  } = props;

  const [blocked, setBlocked] = React.useState(false);
  const [hasError, setHasError] = React.useState({
    show: false,
    message: '',
  });
  const sendService = () => {
    setShowOverlay(true);
    Notification.sendResend(
      id,
    ).then(() => {
      setShowOverlay(false);
      setBlocked(true);
    }).catch((err: any) => {
      setShowOverlay(false);
      setHasError({
        show: true,
        message: err.response.data.message,
      });
    });
  };

  return (
    <>
      {
        hasError.show ? (
          <>
            <p>{hasError.message}</p>
            <Button onClick={() => {
              handleModalClose();
              fetchAdminList();
            }}
            >
              Ok
            </Button>
          </>
        )
          : (
            <>
              {!blocked ? (
                <>
                  <img src={warning} alt="warning" />
                  <h2>
                    {status === 'draft' ? 'Send' : 'Resend'}
                    {' '}
                    Notification
                  </h2>
                  <p>
                    Are you sure you want to
                    {' '}
                    {status === 'draft' ? 'send' : 'resend'}
                    {' '}
                    this Notification?
                  </p>
                  <div className={modalStyles.modalButtonWrapper}>
                    <Button onClick={handleModalClose}>No</Button>
                    <Button onClick={sendService}>Yes</Button>
                  </div>
                </>
              ) : (
                <>
                  <h3>
                    Notification
                    {' '}
                    {status === 'draft' ? 'send' : 'resend'}
                    {' '}
                    successfully !
                  </h3>
                  <Button onClick={() => {
                    handleModalClose();
                    fetchAdminList();
                  }}
                  >
                    Ok
                  </Button>
                </>
              )}

            </>
          )
      }
    </>
  );
};

export default NotificationManagementShowSave;
