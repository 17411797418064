import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Topbar } from '../../components';

interface Props {
  authInProgress: boolean,
  currentPage?: string | null,
  currentSearchParams?: any,
  isAuthenticated: boolean,
  authScopes?: any,
  notificationCount?: number,
  onLogout: any,
  onManageDisableScrolling: any,
  hasGenericError: boolean,

  // from withRouter
  history: any,
  match : any,
  location: any,
}

const TopbarContainerComponent: React.FunctionComponent<Props> = (props: Props & RouteComponentProps) => {
  const {
    authInProgress,
    currentPage,
    currentSearchParams,
    history,
    isAuthenticated,
    authScopes,
    hasGenericError,
    location,
    notificationCount,
    onLogout,
    onManageDisableScrolling,
    match,
    ...rest
  } = props;

  return (
    <Topbar
      authInProgress={authInProgress}
      currentPage={currentPage}
      currentSearchParams={currentSearchParams}
      history={history}
      isAuthenticated={isAuthenticated}
      authScopes={authScopes}
      location={location}
      notificationCount={notificationCount}
      onLogout={onLogout}
      onManageDisableScrolling={onManageDisableScrolling}
      showGenericError={hasGenericError}
      match={match}
      {...rest}
    />
  );
};

TopbarContainerComponent.defaultProps = {
  currentPage: null,
  currentSearchParams: null,
  notificationCount: 0,
  authScopes: null,
};

const mapStateToProps = (state: any) => {
  const { isAuthenticated, logoutError, authScopes } = state.auth;
  const hasGenericError = !!(logoutError);

  return {
    hasGenericError,
    isAuthenticated,
    authScopes,
  };
};

const mapDispatchToProps = () => ({

});

const TopbarContainer = compose<React.ElementType>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(TopbarContainerComponent);

export default TopbarContainer;
