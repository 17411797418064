import api from '../api';

export class ParticipantDetailService {
  static async getParticipantDetails(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&eventId=${id}${isSortByUsername !== '' && isSortByUsername !== 0 ? `&isSortByUsername=${isSortByUsername}` : ''}`;
    return api.getApiCall(`api/v1/admins/events/participants?${apiEndPoint}`);
  }
}
