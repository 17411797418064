import { ActionTypes } from '../constants/index';

export const getChannelList = (payload: any) => ({
  type: ActionTypes.CHL_MGMT_LIST_REQUEST,
  payload: {
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
    startDate: payload.startDate,
    endDate: payload.endDate,
    userType: payload.userType,
    domes: payload.domes,
    search: payload.search,
    isSortByUserName: payload.isSortByUserName,
    isSortByChannelName: payload.isSortByChannelName,
    isSortByCategoryName: payload.isSortByCategoryName,
    isSortByCreated: payload.isSortByCreated,
  },
});
