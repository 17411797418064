import React from 'react';
import {
  Button,
  CircularProgressLoader,
  Modal,
  RemoveProfilePicture,
  ToastBar,
} from 'components';
import { ChangePassword, UpdateProfileForm } from 'forms';
import { compose } from 'redux';
import { injectIntl } from 'utils/reactIntl';
import { connect } from 'react-redux';
import { MyProfileService } from 'services';
import OtpVerification from 'components/OtpVerification';
import scss from './myprofile.module.scss';
import userprofile from '../../assets/svg/image-placeholder.png';
import edit from '../../assets/svg/edit.svg';
import { getUserUpdatedProfile } from '../../actions';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  getProfileInfo: any;
  userProfile: any;
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const MyProfile: React.FC<Props> = (props: Props) => {
  const { getProfileInfo, userProfile } = props;
  const [image, setImage] = React.useState<any>(null);
  const [showModal, setShowModal] = React.useState(false);
  const [showUploadOptions, setShowUploadOptions] = React.useState(false);
  const [showUpdateInfo, setShowUpdateInfo] = React.useState(false);
  const [updateProfile, setUpdateProfile] = React.useState(false);
  const [showOtp, setShowOtp] = React.useState(false);
  const [invalidOtp, setInvalidOtp] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [profileUpdatedValues, setProfileUpdatedValues] = React.useState<any>();
  const [updateProfileLoader, setUpdateProfileLoader] = React.useState<any>();
  const [disabledUpdateButton, setDisabledUpdateButton] = React.useState(false);
  const [showRemove, setShowRemove] = React.useState(false);
  const [updateProfileError, setUpdateProfileError] = React.useState({
    error: false,
    message: '',
  });
  const [showAlert, setShowAlert] = React.useState({
    show: false,
    message: '',
  });
  const { data, loadingInProgress } = userProfile;
  React.useEffect(() => {
    getProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleModalClose = () => {
    setShowModal(false);
    setShowUpdateInfo(false);
    setShowOtp(false);
    setInvalidOtp(false);
    setUpdateProfileLoader(false);
    setUpdateProfileError({
      ...updateProfileError,
      error: false,
      message: '',
    });
    setShowRemove(false);
  };

  const handleOtpSubmit = (otp: any) => {
    setInvalidOtp(false);
    setOtpLoader(true);
    MyProfileService.verifyOTP({ otp }).then(() => {
      setShowAlert({
        ...showAlert,
        show: true,
        message: 'Profile updated successfully!!',
      });
      setOtpLoader(false);
      getProfileInfo();
      handleModalClose();
    }).catch(() => {
      setInvalidOtp(true);
      setOtpLoader(false);
    });
  };

  const handleUserProfileFormSubmit = (profileValues: any) => {
    setUpdateProfileLoader(true);
    setProfileUpdatedValues(profileValues);
    if (profileValues.phoneNumber !== userProfile.data.phoneNo) {
      handleModalClose();
      MyProfileService.sendOTP({
        phoneNo: profileValues.phoneNumber,
        countryCode: profileValues.countryCode.replace('+', ''),
        name: profileValues.name,
      }).then(() => {
        setShowModal(true);
        setShowOtp(true);
        setShowUpdateInfo(false);
      }).catch((err: any) => {
        setUpdateProfileError({
          ...updateProfileError,
          error: true,
          message: err.response.data.message,
        });
      });
    } else {
      MyProfileService.updateProfile({ name: profileValues.name })
        .then(() => {
          setShowUpdateInfo(false);
          handleModalClose();
          getProfileInfo();
          setShowAlert({
            ...showAlert,
            show: true,
            message: 'Profile Updated successfully!!',
          });
        }).catch((error: any) => {
          setUpdateProfileLoader(false);
          setUpdateProfileError({
            error: true,
            message: error.response.data.message,
          });
        });
    }
  };

  return (
    <>
      <Modal
        id="edit-email-phone"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        {showUpdateInfo && (
          <UpdateProfileForm
            handleFormSubmit={handleUserProfileFormSubmit}
            userProfile={data}
            error={updateProfileError}
            handleModalClose={handleModalClose}
            updateProfileLoader={updateProfileLoader}
          />
        )}
        {showOtp && (
          <OtpVerification
            handleOtpSubmit={handleOtpSubmit}
            invalid={invalidOtp}
            handleResendOTP={() => MyProfileService.sendOTP({
              phoneNo: profileUpdatedValues.phoneNumber,
              countryCode: profileUpdatedValues.countryCode.replace('+', ''),
              name: profileUpdatedValues.name,
            })}
            showLoader={otpLoader}
          />
        )}
        {
          showRemove && (
            <RemoveProfilePicture
              handleModalClose={() => {
                setImage(null);
                handleModalClose();
                getProfileInfo();
              }}
            />
          )
        }
      </Modal>
      {showAlert.show
        && (
          <ToastBar
            message={showAlert.message}
            handleCloseMethod={() => {
              setShowAlert({
                ...showAlert,
                show: false,
                message: '',
              });
            }}
          />
        )}
      {loadingInProgress && data === null
        ? (
          <div className={scss.loaderWrapper}>
            <CircularProgressLoader />
          </div>
        ) : (
          <>
            <div className={commonStyles.cardBg}>
              <div className={scss.profilemain}>
                <div className={scss.profileBox}>
                  <div className={scss.w80per}>
                    <div>
                      <div className={scss.profileWrapperimg}>
                        {image !== null
                          ? <img src={URL.createObjectURL(image)} alt="Profile" />
                          : (
                            data !== null && data?.profilePicture !== '' && data?.profilePicture !== undefined
                              ? <img src={data?.profilePicture} alt="userprofile" />
                              : <img src={userprofile} alt="userprofile" />)}
                        <span className={scss.profileEdit}>
                          <input
                            type="button"
                            name="image"
                            id="image"
                            onClick={() => setShowUploadOptions((prevState: any) => !prevState)}
                          />
                          <img
                            src={edit}
                            alt="userprofile"
                          />
                        </span>
                        {showUploadOptions && (
                          <div>
                            <div>
                              <ul className={scss.profileMenuopen}>
                                <li>
                                  <span>
                                    <input
                                      type="file"
                                      name="image"
                                      id="image"
                                      title=""
                                      onChange={(e: any) => {
                                        if (e.target.files[0]) {
                                          setImage(e.target.files[0]);
                                          setDisabledUpdateButton(false);
                                        }
                                        setShowUploadOptions(false);
                                      }}
                                    />
                                  </span>
                                  Update Profile

                                </li>
                                <li>
                                  <span>
                                    <input
                                      type="button"
                                      name="image"
                                      id="image"
                                      onClick={() => {
                                        setShowRemove(true);
                                        setShowUploadOptions(false);
                                        setShowModal(true);
                                      }}
                                    />
                                  </span>
                                  Remove
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={scss.profileWrapperdetails}>
                        <div className="flex_row">
                          <div className="flex_col_sm_3">
                            <span>Name</span>
                          </div>
                          <div className="flex_col_sm_9">
                            <span><strong>{data?.name}</strong></span>
                          </div>
                          <div className="flex_col_sm_3">
                            <span>Email</span>
                          </div>
                          <div className="flex_col_sm_9">
                            <span><strong>{data?.email}</strong></span>
                          </div>
                          <div className="flex_col_sm_3">
                            <span> Phone No.</span>
                          </div>
                          <div className="flex_col_sm_9">
                            <span><strong>{`+${data?.countryCode}-${data?.phoneNo}`}</strong></span>
                          </div>
                          <div className="flex_col_sm_3">
                            <span>Title</span>
                          </div>
                          <div className="flex_col_sm_9">
                            <span>
                              <strong>
                                {data?.adminType}
                                Admin
                              </strong>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {updateProfile
                      ? (
                        <div>
                          <CircularProgressLoader />
                        </div>
                      ) : (
                        <>
                          <Button
                            buttonType={ButtonTypes.button}
                            onClick={() => {
                              const fileData = new FormData();
                              fileData.append('file', image);
                              setUpdateProfile(true);
                              MyProfileService.uploadpicture(fileData).then((res: any) => {
                                MyProfileService.updateProfile({
                                  profilePicture: res.data.data.url,
                                }).then(() => {
                                  getProfileInfo();
                                  setShowAlert({
                                    ...showAlert,
                                    show: true,
                                    message: 'Profile picture changed successfully!!',
                                  });
                                  setUpdateProfile(false);
                                  setDisabledUpdateButton(true);
                                });
                              });
                            }}
                            disabled={image === null || disabledUpdateButton}
                          >
                            Update
                          </Button>
                        </>
                      )}
                  </div>
                  <Button
                    className={scss.buttoNot}
                    onClick={() => {
                      setShowModal(true);
                      setShowUpdateInfo(true);
                    }}
                  >
                    Edit Profile
                  </Button>
                </div>
              </div>
              <ChangePassword
                setShowAlert={setShowAlert}
                showAlert={showAlert}
              />
            </div>
          </>
        )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  userProfile: state.updateProfile,
});
const mapDispatchToProps = (dispatch: any) => ({
  getProfileInfo: () => dispatch(getUserUpdatedProfile()),
});

const MyProfileComponent = compose<React.ElementType>(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(MyProfile);

export default MyProfileComponent;
