import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldCheckbox,
  FieldTextInput,
  ValidationError,
} from 'components';
import { Notification } from 'services';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  error: string;
  setError: any;
  setOverlay: any;
}

const AddNotificationSMSForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    loader,
    error,
    setError,
    setOverlay,
  } = props;
  const history = useHistory();
  const [localError, setlocalError] = React.useState('');
  const addNotificationSchema = yup.object().shape({
    description: yup.string()
      .max(120, 'Please enter characters less than 120 characters for description')
      .required('Please fill all required fields'),
    android: yup
      .boolean()
      .default(false),
    ios: yup
      .boolean()
      .default(false),
    web: yup
      .boolean()
      .default(false),
  }, []);

  const formProps = useForm({
    resolver: yupResolver(addNotificationSchema),
  });
  const getPlatForm = (platformcode: any) => {
    switch (platformcode) {
      case 'Android':
        return '1';
      case 'IOS':
        return '2';
      case 'Web':
        return '3';
      case 'All':
        return '4';
      default:
        return null;
    }
  };
  const handleSubmit = (data: any) => {
    const platform = [];
    if (data.android) platform.push(getPlatForm('Android'));
    if (data.ios) platform.push(getPlatForm('IOS'));
    if (data.web) platform.push(getPlatForm('Web'));
    setOverlay(true);
    if (data.android || data.ios || data.web) {
      setlocalError('');
      Notification.addNotification({
        message: data.description,
        // image: '',
        sendBy: 'sms',
        status: 'sent',
        platforms: platform,
      }).then(() => {
        setOverlay(true);
        history.push('/notification');
      }).catch((err) => {
        setOverlay(false);
        setError(err.response.data.message);
      });
    } else {
      setOverlay(false);
      setlocalError('Please select atleast one platform.');
    }
  };

  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    const platform = [];
    if (data.android) platform.push(getPlatForm('Android'));
    if (data.ios) platform.push(getPlatForm('IOS'));
    if (data.web) platform.push(getPlatForm('Web'));
    setOverlay(true);
    Notification.addNotification({
      message: data.description,
      // image: '',
      sendBy: 'sms',
      status: 'draft',
      platforms: platform,
    }).then(() => {
      setOverlay(true);
      history.push('/notification');
    }).catch((err: any) => {
      setOverlay(false);
      setError(err.response.data.message);
    });
  };

  return (
    <div className={classNames(commonStyles.cardBg, commonStyles.addDetailsPage)}>
      <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
        <div>
          <FieldTextInput
            id="description"
            name="description"
            formName="addDomes"
            className="textInput"
            type="textarea"
            formProps={formProps}
            rootClassName={commonStyles.passwordInput}
            label={intl.formatMessage({ id: 'AddNotificationSms.label.description' })}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FieldCheckbox
            id="android"
            name="android"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationSms.label.android' })}
            defaultValue={false}
          />
          <FieldCheckbox
            id="ios"
            name="ios"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationSms.label.ios' })}
            defaultValue={false}
          />
          <FieldCheckbox
            id="web"
            name="web"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationSms.label.web' })}
            defaultValue={false}
          />
        </div>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className={commonStyles.validation_error}
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
            />
          )}
        {Object.keys(formProps.formState.errors).length === 0
          && <ValidationError error={error} />}
        {Object.keys(formProps.formState.errors).length === 0
          && <ValidationError error={localError} />}
        {loader
          ? (
            <div className={commonStyles.savingLoader}>
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className={commonStyles.buttonWrapper}>
                <Button
                  buttonType={ButtonTypes.button}
                  onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  {intl.formatMessage({ id: 'AddNotificationSms.label.Save.as.draft' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  {intl.formatMessage({ id: 'AddNotificationSms.label.Submit' })}
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default injectIntl(AddNotificationSMSForm);
