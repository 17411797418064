import React from 'react';
import classNames from 'classnames';
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import scss from './FieldTimePicker.module.scss';

interface Props {
  rootClassName?: string
  className?: string
  formProps: any,
  name: string,
  id: string,
  label: string,
  placeholder?: string
  screenReaderInputMessage?: string
  defaultValue?: any;
}

const Timepicker = (props: Props) => {
  const {
    rootClassName,
    className,
    name,
    label,
    id,
    formProps: { formState: { errors }, control },
    placeholder,
    screenReaderInputMessage,
    defaultValue,
  } = props;
  const classes = classNames(rootClassName || scss.fieldRoot, className);
  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: {
            onChange, value, ref,
          },
        }) => (
          <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <div className={scss.center}>
                <TimePicker
                  placeholder={placeholder}
                  onChange={onChange}
                  inputVariant="outlined"
                  error={!!errors[id]}
                  label={label}
                  inputRef={ref}
                  value={value}
                  aria-required
                  aria-label={screenReaderInputMessage}
                />
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => {
                    onChange(null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </MuiPickersUtilsProvider>
          </>
        )}
      />
    </div>
  );
};

Timepicker.defaultProps = {
  rootClassName: null,
  className: null,
  placeholder: null,
  screenReaderInputMessage: null,
  defaultValue: null,
};

export default Timepicker;
