import classNames from 'classnames';
import { DataTable } from 'components';
import React, { useState } from 'react';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import accordion from '../Accordion/accordion.module.scss';

const RewardPointDetails = () => {
  const [selected, setSelected] = useState([]);

  const headCellsrewarddetails: any = [
    {
      id: 'sno', label: 'S.No',
    },
    {
      id: 'username', label: 'Transaction ID',
    },
    {
      id: 'avatar', label: 'Transaction made against',
    },
    {
      id: 'name', label: 'Reward Points Earned/Spent',
    },
    {
      id: 'email', label: ' Date & time',
    },
    {
      id: 'phone', label: 'Status',
    },
  ];

  return (
    <>
      <div className={classNames(commonStyles.dataTableCover, accordion.accordionTable)}>
        <DataTable
          headCells_={headCellsrewarddetails}
          rows_={[]}
          rowClick={() => null}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={() => null}
          page={0}
          rowsPerPage={10}
          loading={false}
          handleChangePage={() => null}
          handleSort={() => null}
          handleChangeRowsPerPage={() => null}
          count={0}
        />
      </div>
    </>
  );
};

export default RewardPointDetails;
