/* eslint-disable react/button-has-type */
import React from 'react';
import styled from 'styled-components';

export const ReloadWrapper = styled.div`
  button {
    pointer-events: all;
  }
`;

const Reload: React.FC = () => (
  <ReloadWrapper>
    <h3>There&apos;s a new version of this app!</h3>
    <button onClick={() => window.location.reload()}>Reload</button>
  </ReloadWrapper>
);

export default Reload;
