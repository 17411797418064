import api from '../api';

export class SubDomesManagementService {
  static async getSubDomesList(payload: any) {
    const {
      page,
      rowsPerPage,
      status,
      search,
      isSortByCategoryName,
      isSortByParentCategoryName,
      isSortByCreated,
    } = payload;

    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${parseInt(isSortByCategoryName, 10)}` : ''}${isSortByParentCategoryName !== undefined && isSortByParentCategoryName !== '' ? `&isSortByParentCategoryName=${parseInt(isSortByParentCategoryName, 10)}` : ''}`;
    return api.getApiCall(`/api/v1/admins/sub-categories?${apiEndPoint}`);
  }

  static async deleteDomes(payload: any) {
    return api.deleteApiCall('/api/v1/admins/sub-categories', {
      categoryId: payload,
    });
  }

  static async blockDomes(payload: any) {
    return api.putApiCall('/api/v1/admins/sub-categories/activate-deactivate', {
      categoryId: payload.id,
      status: payload.status,
    });
  }

  static async uploadpicture(payload: any) {
    return api.postApiCall('/api/v1/admins/upload-files', payload);
  }

  static async addDomes(payload: any) {
    return api.postApiCall('/api/v1/admins/sub-categories', payload);
  }

  static async getDetails(payload: any) {
    return api.getApiCall(`/api/v1/admins/sub-categories/${payload}`);
  }

  static async editDomes(payload: any) {
    return api.putApiCall('/api/v1/admins/sub-categories', payload);
  }

  static async exportUserListToMail(payload: any) {
    const {
      status,
      search,
      isSortByCategoryName,
      isSortByParentCategoryName,
      isSortByCreated,
      type,
    } = payload;

    const apiEndPoint = `${status !== '' && status !== undefined ? `&status=${status}` : ''}${search !== '' && search !== undefined ? `&search=${search}` : ''}${isSortByCreated !== undefined && isSortByCreated !== '' ? `&isSortByCreated=${isSortByCreated}` : ''}${isSortByCategoryName !== undefined && isSortByCategoryName !== '' ? `&isSortByCategoryName=${parseInt(isSortByCategoryName, 10)}` : ''}${isSortByParentCategoryName !== undefined && isSortByParentCategoryName !== '' ? `&isSortByParentCategoryName=${parseInt(isSortByParentCategoryName, 10)}` : ''}&type=${type}`;
    return api.getApiCall(`/api/v1/admins/sub-categories/exports?${apiEndPoint}`);
  }

  static async saveDomesToDraft(payload: any) {
    return api.postApiCall('/api/v1/admins/sub-categories', { ...payload, status: 'pending' });
  }

  static async saveDomesPutToDraft(payload: any) {
    return api.putApiCall('/api/v1/admins/sub-categories', { ...payload, status: 'pending' });
  }
}
