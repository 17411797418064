import React from 'react';
import classNames from 'classnames';

import css from './dotsLoader.module.scss';

const Loader = () => (
  <div className={`${css.container} ${css.loaderContainer}`}>
    <h3 className={css.loader_main}>
      Loading
      <span className={classNames(css.loader, css.threeDots)} />
    </h3>
  </div>
);

export default Loader;
