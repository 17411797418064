import React from 'react';
import { connect } from 'react-redux';
import { getDateAndTime } from 'utils/dateMethods';
import {
  ToastBar,
} from 'components';
import { DomesManagementService } from 'services';
import { getCoinActivity } from 'actions';
import { AvatcoinFilter } from 'forms';
import { exportToCSV, exportToExcel } from 'utils/exportToExcel';
import DataTable from '../Datatable';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  coinactivity: any;
  fetchCoinActivity: any;
}

const CoinActivityManagement: React.FC<Props> = (props: Props) => {
  const {
    coinactivity,
    fetchCoinActivity,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    search: '',
    startDate: '',
    endDate: '',
    avatcoinActivity: '',
    transactionStatus: '',
    isSortByCreatedAt: '',
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [loaderExport, setLoaderExport] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });

  React.useEffect(() => {
    fetchCoinActivity(filter);
  }, [fetchCoinActivity, filter]);
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = coinactivity.data ? coinactivity.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  const handleSubmitFilter = (data: any) => {
    if (data.search !== '' || data.startDate !== null || data.endDate !== null || data.avatcoinActivity !== '' || data.transactionStatus !== '') {
      setFilter({
        ...filter,
        search: data.search,
        startDate: data.startDate,
        endDate: data.endDate,
        avatcoinActivity: data.avatcoinActivity,
        transactionStatus: data.transactionStatus,
        page: 0,
      });
    }
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      transactionId: data.transactionId,
      username: data.sourceUser !== undefined ? data.sourceUser.username : '',
      amount: data.amount,
      payin: `${data.goldCoin} ${data.transactionType === 'credit' ? 'CR' : 'DR'}`,
      transactionDate: getDateAndTime(data.createdAt),
      status: data.sourceUserPaymentStatus,
    };
  }

  const rows: any = [];
  if (coinactivity.data !== null && coinactivity.data) {
    coinactivity.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }

  const headCells: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'transactionId', label: 'Transaction Id', sort: false,
    },
    {
      id: 'username', label: 'Username', sort: false,
    },
    {
      id: 'amount', label: 'Dollar Spent/Earned', sort: false,
    },
    {
      id: 'payin', label: 'Total Avatcoin Pay In / Pay Out', sort: false,
    },
    {
      id: 'transactionDate', label: 'Transaction Date and Time', sort: true,
    },
    {
      id: 'status', label: 'Transaction Status', sort: false,
    },
  ];

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };
  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'transactionDate') {
      setFilter({
        ...filter,
        page: 0,
        isSortByCreatedAt: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleExport = (exportType: string) => {
    setLoaderExport(true);
    if (exportType === 'XLSX') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Name: ele.name,
            Description: ele.description.props.title,
            Rank: ele.rank,
            Status: ele.status,
            CreatedAt: ele.createdAt,
            Icon: ele.icon.props.src,
          });
        });
        exportToExcel(selectedRowDataModified, 'DomesManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'Excel successfully downloaded.',
        });
      } else {
        DomesManagementService.exportUserListToMail({ ...filter, type: 'xlsx' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'Excel has been sent on mail.',
          });
        });
      }
    } else if (exportType === 'CSV') {
      if (selectedRows.length > 0) {
        const selectedRowDataModified: any = [];
        selectedRows.forEach((ele: any) => {
          selectedRowDataModified.push({
            Sno: ele.sno,
            Name: ele.name,
            Description: ele.description.props.title,
            Rank: ele.rank,
            Status: ele.status,
            CreatedAt: ele.createdAt,
            Icon: ele.icon.props.src,
          });
        });
        exportToCSV(selectedRowDataModified, 'DomesManagementData');
        setLoaderExport(false);
        setNotification({
          ...notification,
          show: true,
          message: 'CSV successfully downloaded.',
        });
      } else {
        DomesManagementService.exportUserListToMail({ ...filter, type: 'csv' }).then(() => {
          setLoaderExport(false);
          setNotification({
            ...notification,
            show: true,
            message: 'CSV has been sent on mail.',
          });
        });
      }
    }
  };

  const handleResetSorts = () => {
    setFilter({
      ...filter,
      search: '',
      startDate: '',
      endDate: '',
      avatcoinActivity: '',
      transactionStatus: '',
      isSortByCreatedAt: '',
    });
  };

  return (
    <>
      {notification.show && (
        <ToastBar
          message={notification.message}
          handleCloseMethod={() => setNotification({
            ...notification,
            show: false,
            message: '',
          })}
        />
      )}
      <div>
        <AvatcoinFilter
          handleResetSorts={handleResetSorts}
          handleFormSubmit={handleSubmitFilter}
          handleExport={handleExport}
          loaderExport={loaderExport}
        />
        <div className={commonStyles.dataTableCover}>
          <DataTable
            headCells_={headCells}
            rows_={rows}
            rowClick={handleClick}
            selected={selected}
            setSelected={setSelected}
            setSelectedRows={setSelectedRows}
            page={filter.page}
            rowsPerPage={filter.rowsPerPage}
            loading={coinactivity.loadingInProgress}
            handleChangePage={handleChangePage}
            handleSort={handleSort}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { coinactivity } = state;
  return {
    coinactivity,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchCoinActivity: (payload: any) => dispatch(getCoinActivity(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoinActivityManagement);
