import React from 'react';

interface Props {
  children: any,
  rules: any[]
}

const SuperAdminAccessControl: React.FC<Props> = (props: Props) => {
  const { children, rules } = props;
  if (rules.includes(localStorage.getItem('adminType'))) {
    return children;
  }
  return null;
};

export default SuperAdminAccessControl;
