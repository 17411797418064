import React from 'react';
import { connect } from 'react-redux';
import { eventDetails } from 'actions';
import classNames from 'classnames';
import DataTable from '../Datatable';
import avatus from '../../assets/svg/image-placeholder.png';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import accordion from '../Accordion/accordion.module.scss';

interface Props {
  id: any;
  eventdetails: any;
  fetchEventDetails: any;
}

const EventDetails: React.FC<Props> = (props: Props) => {
  const {
    eventdetails,
    fetchEventDetails,
    id,
  } = props;
  const [filter, setFilter] = React.useState<any>({
    page: 0,
    rowsPerPage: 10,
    id,
    isSortByUsername: 0,
  });
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);

  React.useEffect(() => {
    fetchEventDetails(filter);
  }, [fetchEventDetails, filter]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };
  const count = eventdetails.data ? eventdetails.data['Pagination-Count'] : 0;
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, page: 0, rowsPerPage: parseInt(event.target.value, 10) });
  };

  function createData(data: any, index: number): any {
    const idS = data._id;
    return {
      sno: filter.page * filter.rowsPerPage + index + 1,
      id: idS,
      username: data.user.username,
      reason: <img src={avatus} alt="avatus" />,
      reporteddateandtime: data.bio,
    };
  }

  const rows: any = [];
  if (eventdetails.data !== null && eventdetails.data) {
    eventdetails.data.data.forEach((ele: any, index: number) => {
      rows.push(createData(ele, index));
    });
  }
  const headCellsPostsCreated: any = [
    {
      id: 'sno', label: 'S.No', sort: false,
    },
    {
      id: 'username', label: 'Reporter Username', sort: true,
    },
    {
      id: 'reason', label: 'Reason', sort: false,
    },
    {
      id: 'reporteddateandtime', label: ' Reported Date & Time', sort: false,
    },
  ];

  const handleSort = (order: any, orderBy: any) => {
    if (orderBy === 'username') {
      setFilter({
        ...filter,
        page: 0,
        isSortByUsername: order === 'asc' ? '1' : '-1',
      });
    }
  };
  const handleSelectedClick = (event: React.MouseEvent<unknown>, name: any) => {
    const selectedIndex = selected.indexOf(name.id);
    let newSelected: string[] = [];
    let newSelectedRows: any = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name.id);
      newSelectedRows = newSelectedRows.concat(selectedRows, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedRows = newSelectedRows.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
      newSelectedRows = newSelectedRows.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }
    setSelectedRows(newSelectedRows);
    setSelected(newSelected);
  };

  const handleClick = (event: any, name: any) => {
    if (event.target.localName === 'input') {
      handleSelectedClick(event, name);
    }
  };

  return (
    <>
      <div className={classNames(commonStyles.dataTableCover, accordion.accordionTable)}>
        <DataTable
          headCells_={headCellsPostsCreated}
          rows_={rows}
          rowClick={handleClick}
          selected={selected}
          setSelected={setSelected}
          setSelectedRows={setSelectedRows}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          loading={eventdetails.loadingInProgress}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          count={count}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { eventdetails } = state;
  return {
    eventdetails,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchEventDetails: (payload: any) => dispatch(eventDetails(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
