import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  ManageFAQs,
  LayoutWrapperMain,
  Page,
} from 'components';
import config from '../../config.js';
import scss from './managefaqs.module.scss';

interface Props {
  scrollingDisabled: boolean,
  intl: any,
}

const ManageFAQsPageComponent:
  React.FC<Props & RouteComponentProps<any>> = (props: Props) => {
    const {
      intl,
      scrollingDisabled,
    } = props;
    const { siteTitle } = config;
    const schemaTitle = intl.formatMessage(
      { id: 'ContentManagement.page.title' },
      { siteTitle },
    );
    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutWrapperMain className={scss.layoutWrapperMain}>
          <div className={scss.content}>
            <ManageFAQs />
          </div>
        </LayoutWrapperMain>
      </Page>
    );
  };

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({
});

const ManageFAQS = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(ManageFAQsPageComponent);

export default ManageFAQS;
