import { ActionTypes } from '../constants/index';

export const fetchDebitActivity = (payload: any) => ({
  type: ActionTypes.DEBIT_ACTIVITY_LIST_REQUEST,
  payload: {
    id: payload.id,
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
    type: payload.type,
  },
});
