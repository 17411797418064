import api from '../api';

export class NotificationLiveService {
  static async getNotificationLive(payload: any) {
    const {
      id,
      page,
      rowsPerPage,
      isSortByUsername,
    } = payload;
    const apiEndPoint = `limit=${rowsPerPage}&pageNo=${page}&eventId=${id}${isSortByUsername !== '' && isSortByUsername !== 0 && isSortByUsername !== undefined ? `&isSortByUsername=${isSortByUsername}` : ''}&type=admin-event`;
    return api.getApiCall(`/api/v1/admins/notifications?${apiEndPoint}`);
  }
}
