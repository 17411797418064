import { ActionTypes } from '../constants/index';

export const domesManagement = (payload: any) => ({
  type: ActionTypes.DOMES_MGMT_LIST_REQUEST,
  payload: {
    page: payload.page + 1,
    rowsPerPage: payload.rowsPerPage,
    status: payload.status,
    search: payload.search,
    isSortByCategoryName: payload.isSortByCategoryName,
    isSortByCreated: payload.isSortByCreated,
  },
});
