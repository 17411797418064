import {
  put, call, takeEvery,
} from 'redux-saga/effects';
import { Notification } from '../services';
import { ActionTypes } from '../constants';

async function fetchAsync(func: any, arg: any) {
  try {
    const response = arg !== null ? await func(arg) : await func();
    return response;
  } catch (error: any) {
    return {
      error: true,
      message: error.response.data.message,
      status: error.response.data.statusCode,
    };
  }
}

function* loginNotification(action: any): Generator {
  try {
    const data: any = yield call(fetchAsync, Notification.getNotificationService, action.payload);
    if (data.error) {
      if (data.status === 403) {
        yield put({ type: ActionTypes.LOGOUT_WITH_ERROR_REQUEST });
      } else {
        yield put({ type: ActionTypes.NOTIFICATION_LIST_ERROR, payload: data.message });
      }
    } else {
      yield put({ type: ActionTypes.NOTIFICATION_LIST_SUCCESS, payload: data.data.data });
    }
  } catch (e: any) {
    yield put({ type: ActionTypes.NOTIFICATION_LIST_ERROR, error: e.data });
  }
}

export function* watchNotificationSaga() {
  yield takeEvery(ActionTypes.NOTIFICATION_LIST_REQUEST, loginNotification);
}
export default watchNotificationSaga;
