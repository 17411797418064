import { AnyAction } from 'redux';
import { ActionTypes } from '../constants/index';
import { ChannelReported } from '../modal';

export default function reducer(
  state: ChannelReported = new ChannelReported(),
  action: AnyAction,
) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CHL_REPORT_DETAIL_REQUEST:
      return {
        ...state,
        loadingInProgress: true,
      };
    case ActionTypes.CHL_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        loadingInProgress: false,
        data: payload,
      };
    case ActionTypes.CHL_REPORT_DETAIL_ERROR:
      return {
        ...state,
        loadingInProgress: false,
        loadingError: payload,
      };
    default:
      return state;
  }
}
