/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  applyMiddleware, createStore, compose,
} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import rootSaga from '../sagas/index';
import RootReducer from '../reducers/index';

import middleware, { sagaMiddleware } from './middleware';

//= =======================================
// reducer & PersistentReducer
//= =======================================
const reducer = persistReducer(
  {
    key: '@pp-6u!ld3r', // key is required
    storage, // storage is now required (using localStorage)
    whitelist: ['permission'], // only this list will not be persisted
    blacklist: [], // list will not be persisted
    stateReconciler: autoMergeLevel2,
  },
  RootReducer,
);

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

// ======================================================
// Store Enhancers
// ======================================================
const enhancers: any = [];

let composeEnhancers = compose;

const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
if (typeof composeWithDevToolsExtension === 'function') {
  composeEnhancers = composeWithDevToolsExtension;
}

// ======================================================
// Store Instantiation and HMR Setup
// ======================================================
const configStore = (initialState: any = {}) => {
  const store = createStore(reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers,
    ));

  sagaMiddleware.run(rootSaga);

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

export { store, persistor };
