import React from 'react';
import { connect } from 'react-redux';
import { dateFormatting } from 'utils/dateMethods';
import {
  RewardPointDetails,
  LoginDetailsUserInfo,
  Button,
  CircularProgressLoader,
  Modal,
  UserReportDetail,
  DeleteUserInfo,
  BlockUserInfo,
  UserInfoSendNotification,
  SuperAdminAccessControl,
  ToastBar,
  PERMISSION_KEYS,
  PERMISSION_TYPES,
  AccessControl,
  Overlay,
  Accordion,
  UserInfoNotification,
} from 'components';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import AdminRules from '../AdminRoles/adminRules';

import { getUserInfo } from '../../actions';
import { UserInfoService } from '../../services';
import OtpVerification from '../OtpVerification';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import deleteIcon from '../../assets/svg/delete.svg';
import blockIcon from '../../assets/svg/block.svg';
import exportIcon from '../../assets/svg/export.svg';
import userprofile from '../../assets/svg/image-placeholder.png';
import active from '../../assets/svg/active.svg';
import blocked from '../../assets/svg/blocked.svg';
import pending from '../../assets/svg/pending.svg';
import unblock from '../../assets/svg/unblock.svg';
import commonStyles from '../LayoutWrapperMain/LayoutWrapperMain.module.scss';
import scss from './userinfo.module.scss';

interface Props {
  fetchUserInfo: any;
  id: any;
  userInfo: any;
}

const UserInfo: React.FC<Props> = (props: Props) => {
  const {
    fetchUserInfo,
    id,
    userInfo,
  } = props;

  const [showOverlay, setShowOverlay] = React.useState(false);
  const [deleteUser, setDeleteUser] = React.useState(false);
  const [blockUser, setBlockUser] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [otpVerif, setOtpVerif] = React.useState(false);
  const [userVerified, setUserVerified] = React.useState(false);
  const [userData, setUserData] = React.useState<any>();
  const [invalidOtp, setInvalidOtp] = React.useState(false);
  const [sendNotification, setSendNotification] = React.useState(false);
  const [otpLoader, setOtpLoader] = React.useState(false);
  const [notification, setNotification] = React.useState({
    show: false,
    message: '',
  });
  React.useEffect(() => {
    fetchUserInfo(id);
  }, [fetchUserInfo, id]);

  const handleDeleteUser = () => {
    setDeleteUser(true);
    setShowModal(true);
  };
  const handleModalClose = () => {
    setOtpVerif(false);
    setDeleteUser(false);
    setBlockUser(false);
    setShowModal(false);
    setSendNotification(false);
  };
  const handleBlockUser = () => {
    setBlockUser(true);
    setShowModal(true);
  };

  const handleShowPersonalInfo = async () => {
    try {
      setShowOverlay(true);
      await UserInfoService.sendOTP();
      setShowOverlay(false);
      setOtpVerif(true);
      setShowModal(true);
    } catch (err: any) {
      if (err.response.status === 405) {
        setShowOverlay(false);
        setNotification({
          show: true,
          message: err.response.data.message,
        });
      }
    }
  };

  const handleOtpSubmit = async (otp: any) => {
    setInvalidOtp(false);
    setOtpLoader(true);
    setShowOverlay(true);
    UserInfoService.checkOTP(otp, id).then((res) => {
      if (res.status === 200) {
        setUserVerified(true);
        setUserData(res.data.data);
        setShowModal(false);
        setOtpVerif(false);
        setOtpLoader(false);
        setShowOverlay(false);
        handleModalClose();
      }
    }).catch(() => {
      setInvalidOtp(true);
      setShowOverlay(false);
      setOtpLoader(false);
    });
  };

  const handleSendNotification = () => {
    setShowModal(true);
    setSendNotification(true);
  };

  const handleCloseOTPResenError = () => {
    setNotification({
      show: false,
      message: '',
    });
  };

  const history = useHistory();

  return (
    <>
      {showOverlay && <Overlay />}
      {
        notification.show && (
          <ToastBar
            message={notification.message}
            handleCloseMethod={handleCloseOTPResenError}
          />
        )
      }
      <Modal
        id="userinfo-modal"
        isOpen={showModal}
        onClose={handleModalClose}
        onManageDisableScrolling={() => null}
        lightCloseButton={false}
      >
        <div className={commonStyles.modal_container}>
          {otpVerif && (
            <OtpVerification
              handleOtpSubmit={handleOtpSubmit}
              invalid={invalidOtp}
              handleResendOTP={() => UserInfoService.sendOTP()}
              showLoader={otpLoader}
              setShowOverlay={setShowOverlay}
            />
          )}
          {deleteUser && (
            <DeleteUserInfo
              fetchUserInfo={() => history.push('/usermanagement')}
              handleModalClose={handleModalClose}
              setShowOverlay={setShowOverlay}
              id={id}
            />
          )}
          {blockUser && (
            <BlockUserInfo
              id={id}
              setShowOverlay={setShowOverlay}
              fetchUserInfo={fetchUserInfo}
              handleModalClose={handleModalClose}
              userInfoStatus={userInfo.data.status}
            />
          )}
          {
            sendNotification && (
              <UserInfoSendNotification
                id={id}
                handleModalClose={handleModalClose}
                setShowOverlay={setShowOverlay}
                setNotification={setNotification}
              />
            )
          }
        </div>
      </Modal>
      <div>
        {userInfo.data === null || userInfo.loadingInProgress ? <CircularProgressLoader />
          : (
            <>
              <div className={classNames(commonStyles.cardBg, commonStyles.container)}>
                <div className={commonStyles.headerActions}>
                  <div>
                    <SuperAdminAccessControl rules={AdminRules.AdminOTPRules}>
                      <Button
                        className={commonStyles.button}
                        onClick={handleShowPersonalInfo}
                      >
                        Show Personal Information
                      </Button>
                    </SuperAdminAccessControl>
                    <Button onClick={handleSendNotification}>
                      Send Notification
                    </Button>
                  </div>
                  <div className={commonStyles.iconWrapperbox}>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img src={exportIcon} alt="Export" title="Export" />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.DELETE}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          role="presentation"
                          src={deleteIcon}
                          alt="Delete"
                          title="Delete"
                          onClick={handleDeleteUser}
                        />
                      </span>
                    </AccessControl>
                    <AccessControl
                      permissionKey={PERMISSION_KEYS.USER_MANAGEMENT}
                      accessType={PERMISSION_TYPES.EDIT}
                    >
                      <span className={commonStyles.iconWrapper}>
                        <img
                          role="presentation"
                          src={userInfo.data.status === 'blocked' ? unblock : blockIcon}
                          alt="Block"
                          title="Block"
                          onClick={handleBlockUser}
                        />
                      </span>
                    </AccessControl>
                  </div>
                </div>
                <div className={commonStyles.userInformatinc}>
                  <div className={scss.column_left}>
                    <img src={userprofile} alt="userprofile" />
                  </div>
                  <div className={scss.column_right}>
                    <div className={classNames(commonStyles.userInfoinner, scss.userInfo)}>
                      <h2>
                        {userInfo.data.username}
                      </h2>
                      {
                        userInfo.data.status === 'blocked' ? <img src={blocked} alt="blocked" /> : (
                          userInfo.data.registerationStatus === 'pending' ? <img src={pending} alt="pending" /> : <img src={active} alt="active" />
                        )
                      }
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            Name
                          </th>
                          <th>
                            Email
                          </th>
                          <th>
                            Nationality
                          </th>
                          <th>
                            Gender
                          </th>
                          <th>
                            Date of Birth
                          </th>
                          <th>
                            Phone no.
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {userVerified ? userData?.fullName : (userInfo.data.fullNameFlag ? 'Yes' : 'No')}
                          </td>
                          <td>
                            {userVerified ? userData?.email : (userInfo.data.emailFlag ? 'Yes' : 'No')}
                          </td>
                          <td>
                            {userVerified ? userData?.nationality : (userInfo.data.nationalityFlag ? 'Yes' : 'No')}
                          </td>
                          <td>
                            {userVerified ? userData?.gender : (userInfo.data.genderFlag ? 'Yes' : 'No')}
                          </td>
                          <td>
                            {userVerified ? dateFormatting(userData?.dob) : (userInfo.data.ageFlag ? 'Yes' : 'No')}
                          </td>
                          <td>
                            {userVerified ? userData?.phoneNo : (userInfo.data.phoneFlag ? 'Yes' : 'No')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className={commonStyles.postDetails}>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>Post Count</p>
                      <p className={commonStyles.count_backround}>{userInfo.data.postCount}</p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>
                        Channel Created (
                        {userInfo.data.channels.length}
                        )
                      </p>
                      <div className={commonStyles.domFlex}>
                        {userInfo.data.channels.map((ele: any) => <p className={commonStyles.count_backround}>{ele.channelName}</p>)}
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Subscribed Channels</p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>{userInfo.data.subscribedChannelCount}</p>
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Events Hosted</p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>{userInfo.data.eventCount}</p>
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Purchases Made</p>
                      <p className={commonStyles.count_backround}>{userInfo.data.purchasesMade}</p>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>Earnings</p>
                      <p className={commonStyles.count_backround}>
                        $
                        {userInfo.data.earning}
                      </p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Payments</p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>
                          $
                          {userInfo.data.payments}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Registered On </p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>{dateFormatting(userInfo.data.createdAt)}</p>
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Campaigns Created (0)</p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>NA</p>
                      </div>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Campaigns Invested In</p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>{userInfo.data.campaignCount}</p>
                      </div>
                    </div>
                  </div>
                  <div className={commonStyles.column}>
                    <div>
                      <p className={commonStyles.heading_styles}>Followers</p>
                      <p className={commonStyles.count_backround}>{userInfo.data.followersCount}</p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Following</p>
                      <p className={commonStyles.count_backround}>{userInfo.data.followingCount}</p>
                    </div>
                    <div>
                      <p className={commonStyles.heading_styles}>Services Created</p>
                      <div className={commonStyles.domFlex}>
                        <p className={commonStyles.count_backround}>0</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={commonStyles.domBox}>
                  <p className={commonStyles.heading_styles}>
                    Domes
                    (
                    {userInfo.data.categories.length}
                    )
                  </p>
                  <div className={commonStyles.domFlex}>
                    {userInfo.data.categories.map((ele: any, index: any) => <p key={index} className={commonStyles.count_backround}>{ele.name}</p>)}
                  </div>
                </div>
              </div>
              <Accordion heading="Notification Details">
                <UserInfoNotification id={id} />
              </Accordion>
              <Accordion heading="Transaction Details">
                <RewardPointDetails />
              </Accordion>
              <Accordion heading="Login Details">
                <LoginDetailsUserInfo id={id} />
              </Accordion>
              <Accordion heading="User Report Detail">
                <UserReportDetail id={id} />
              </Accordion>
            </>
          )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { userinfo } = state;
  return {
    userInfo: userinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchUserInfo: (payload: any) => dispatch(getUserInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
