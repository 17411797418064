import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgressLoader,
  FieldCheckbox,
  FieldFileInput,
  FieldTextInput,
  ValidationError,
} from 'components';
import { Notification } from 'services';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import information from '../../assets/svg/info.svg';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

interface Props {
  intl: any;
  loader: any;
  error: string;
  setOverlay: any;
  setError: any;
}

const AddNotificationPushForm: React.FC<Props> = (props: Props) => {
  const {
    intl,
    loader,
    error,
    setError,
    setOverlay,
  } = props;
  const FILE_SIZE = 10 * 1024;
  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/png',
  ];
  const [notificationError, setNotificationError] = React.useState('');
  const history = useHistory();
  const handleSaveAsDraft = (e: any, data: any) => {
    e.preventDefault();
    if (data.title === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.description === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.selectedIcon === undefined) {
      setNotificationError('Please fill all required fields');
    } else if (data.android !== true && data.ios !== true && data.web !== true) {
      setNotificationError('Please select a platform');
    } else {
      setNotificationError('');
      const platform: any = [];
      if (data.web) {
        platform.push('3');
      }
      if (data.android) {
        platform.push('1');
      }
      if (data.ios) {
        platform.push('2');
      }
      const form1 = new FormData();
      form1.append('file', data.selectedIcon[0]);
      setOverlay(true);
      Notification.uploadpicture(form1).then((res: any) => {
        Notification.addNotification({
          title: data.title,
          message: data.description,
          image: res.data.data.url,
          sendBy: 'push',
          status: 'draft',
          platforms: platform,
        }).then(() => {
          history.push('/notification');
          setOverlay(false);
        }).catch((err) => {
          setOverlay(false);
          setError(err.response.data.message);
        });
      });
    }
  };
  const handleSubmit = (data: any) => {
    const platform: any = [];
    if (data.web) {
      platform.push('3');
    }
    if (data.android) {
      platform.push('1');
    }
    if (data.ios) {
      platform.push('2');
    }
    const form1 = new FormData();
    form1.append('file', data.selectedIcon[0]);
    if (data.title === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.description === '') {
      setNotificationError('Please fill all required fields');
    } else if (data.selectedIcon === undefined) {
      setNotificationError('Please fill all required fields');
    } else if (data.android !== true && data.ios !== true && data.web !== true) {
      setNotificationError('Please select a platform');
    } else {
      setOverlay(true);
      Notification.uploadpicture(form1).then((res: any) => {
        Notification.addNotification({
          title: data.title,
          message: data.description,
          image: res.data.data.url,
          sendBy: 'push',
          status: 'sent',
          platforms: platform,
        }).then(() => {
          setOverlay(false);
          history.push('/notification');
        }).catch((err) => {
          setOverlay(false);
          setError(err.response.data.message);
        });
      });
    }
  };

  const addNotificationSchema = yup.object().shape({
    title: yup.string()
      .max(120, 'Please enter title less than 120 characters for description')
      .required('Please fill all required fields'),
    description: yup.string()
      .max(120, 'Please enter characters less than 120 characters for description')
      .required('Please fill all required fields'),
    android: yup
      .boolean()
      .default(false),
    ios: yup
      .boolean()
      .default(false),
    web: yup
      .boolean()
      .default(false),
    selectedIcon: yup
      .mixed()
      .required('Please fill all required fields')
      .test(
        'fileSize',
        'Selected Icon File too large',
        (value) => value && value[0].size <= FILE_SIZE,
      )
      .test(
        'fileFormat',
        'Unsupported Format for Selected icon.(jpeg/png is required)',
        (value) => value && SUPPORTED_FORMATS.includes(value[0].type),
      ),
  }, [['android', 'ios']]);

  const formProps = useForm({
    resolver: yupResolver(addNotificationSchema),
  });

  return (
    <div className={classNames(commonStyles.cardBg, commonStyles.addDetailsPage)}>
      <form className={commonStyles.form} onSubmit={formProps.handleSubmit(handleSubmit)}>
        <FieldTextInput
          id="title"
          name="title"
          formName="addNotification"
          className="textInput"
          type="text"
          formProps={formProps}
          rootClassName={commonStyles.passwordInput}
          label={intl.formatMessage({ id: 'AddNotificationPush.label.title' })}
        />
        <div>
          <FieldTextInput
            id="description"
            name="description"
            formName="addDomes"
            className="textInput"
            type="textarea"
            formProps={formProps}
            rootClassName={commonStyles.passwordInput}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.description' })}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <FieldCheckbox
            id="android"
            name="android"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.android' })}
            defaultValue={false}
          />
          <FieldCheckbox
            id="ios"
            name="ios"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.ios' })}
            defaultValue={false}
          />
          <FieldCheckbox
            id="web"
            name="web"
            formProps={formProps}
            label={intl.formatMessage({ id: 'AddNotificationPush.label.web' })}
            defaultValue={false}
          />
        </div>
        <h2 className={commonStyles.sign_text}>Upload Image</h2>
        <div className={commonStyles.profileWrapperimg}>
          <div className={commonStyles.selection_box}>
            <FieldFileInput
              id="selectedIcon"
              name="selectedIcon"
              className="textInput"
              formProps={formProps}
              rootClassName={commonStyles.input_upload}
            />
          </div>
        </div>
        <p className={commonStyles.informationUpload}>
          <img src={information} alt="information" />
          {intl.formatMessage({ id: 'AddNotificationPush.label.info' })}
        </p>
        {Object.keys(formProps.formState.errors).length > 0
          && (
            <ValidationError
              className={commonStyles.validation_error}
              error={formProps.formState.errors[Object.keys(formProps.formState.errors)[0]].message}
            />
          )}
        {Object.keys(formProps.formState.errors).length === 0
          && <ValidationError error={error} />}
        {Object.keys(formProps.formState.errors).length === 0
          && notificationError.length > 0
          && <ValidationError error={notificationError} />}
        {loader
          ? (
            <div className={commonStyles.savingLoader}>
              <CircularProgressLoader />
            </div>
          ) : (
            <>
              <div className={commonStyles.buttonWrapper}>
                <Button
                  buttonType={ButtonTypes.button}
                  onClick={(e: any) => handleSaveAsDraft(e, formProps.getValues())}
                >
                  {intl.formatMessage({ id: 'AddNotificationPush.label.Save.as.draft' })}
                </Button>
                <Button
                  buttonType={ButtonTypes.submit}
                >
                  {intl.formatMessage({ id: 'AddNotificationPush.label.Submit' })}
                </Button>
              </div>
            </>
          )}
      </form>
    </div>
  );
};

export default injectIntl(AddNotificationPushForm);
