const searchApis = {
  userManagement: 'api/v1/admins/users/usernames?search=',
  channleManagement: 'api/v1/admins/channels/suggestions?search=',
  domesManagement: 'api/v1/admins/categories/search?search=',
  subDomesManagement: 'api/v1/admins/sub-categories/search?search=',
  rolesManagement: '/api/v1/admins/roles/suggestions?search=',
  subAdminManagement: '/api/v1/admins/suggestions?search=',
  notificationManagement: '/api/v1/admins/notifications/suggestion?search=',
  livestreamingManagement: '/api/v1/admins/events/suggestions?search=',
};

export default searchApis;
