import React from 'react';
import { useForm } from 'react-hook-form';
import { injectIntl } from 'react-intl';
import searchBarApis from 'utils/searchBarApis';
import { range } from 'lodash';
import {
  Button,
  FieldSelect,
  ValidationError,
  FieldSearchBar,
} from '../../components';
import commonStyles from '../../components/LayoutWrapperMain/LayoutWrapperMain.module.scss';

interface Props {
  handleFormSubmit: any,
  handleResetSorts: any,
  intl: any,
  error: any,
}

enum ButtonTypes {
  'button',
  'submit',
  'reset',
  undefined
}

const FilterManagementFilterForm: React.FC<Props> = (props: Props) => {
  const {
    handleFormSubmit,
    handleResetSorts,
    intl,
    error,
  } = props;
  const formProps = useForm();

  const handleGetStart = (start: number) => (
    <>
      {
        range(start).map(() => (
          <svg enableBackground="new 0 0 32 32" height="10" viewBox="0 0 32 32" width="10" xmlns="http://www.w3.org/2000/svg">
            <g id="star">
              <path d="m29.911 13.75-6.229 6.072 1.471 8.576c.064.375-.09.754-.398.978-.174.127-.381.191-.588.191-.159 0-.319-.038-.465-.115l-7.702-4.049-7.701 4.048c-.336.178-.745.149-1.053-.076-.308-.224-.462-.603-.398-.978l1.471-8.576-6.23-6.071c-.272-.266-.371-.664-.253-1.025s.431-.626.808-.681l8.609-1.25 3.85-7.802c.337-.683 1.457-.683 1.794 0l3.85 7.802 8.609 1.25c.377.055.69.319.808.681s.019.758-.253 1.025z" />
            </g>
          </svg>
        ))
      }
    </>
  );

  const starRating = [
    { key: '1', label: handleGetStart(1) },
    { key: '2', label: handleGetStart(2) },
    { key: '3', label: handleGetStart(3) },
    { key: '4', label: handleGetStart(4) },
    { key: '5', label: handleGetStart(5) },
  ];
  const handleReset = () => {
    formProps.reset({
      search: '',
      rating: '',
    });
    handleResetSorts();
  };
  return (
    <form className={commonStyles.filterForm} onSubmit={formProps.handleSubmit(handleFormSubmit)}>
      <div className={commonStyles.filter_container}>
        <div className={commonStyles.filterSearch}>
          <div>
            <div className={commonStyles.userType}>
              <FieldSelect
                id="rating"
                name="rating"
                variant={false}
                label={intl.formatMessage({ id: 'FeedbackManagement.label.rating' })}
                formProps={formProps}
                options={starRating}
              />
            </div>
            <div className={commonStyles.searchField}>
              <FieldSearchBar
                searchURL={searchBarApis.userManagement}
                formProps={formProps}
                name="search"
                label={intl.formatMessage({ id: 'FeedbackManagement.label.username' })}
                id="search"
              />
            </div>
          </div>
        </div>
        <div className={commonStyles.filterOptions}>
          <div className={commonStyles.buttonWrapper}>
            <Button buttonType={ButtonTypes.submit} className={commonStyles.button}>
              {intl.formatMessage({ id: 'FeedbackManagement.label.apply' })}
            </Button>
            <Button onClick={handleReset} className={commonStyles.button}>
              {intl.formatMessage({ id: 'FeedbackManagement.label.reset' })}
            </Button>
          </div>
        </div>
        {error && (
          <ValidationError
            className={commonStyles.validation_error}
            error={error}
          />
        )}
      </div>
    </form>
  );
};

export default injectIntl(FilterManagementFilterForm);
