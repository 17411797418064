import {
  put, call, takeEvery,
} from 'redux-saga/effects';
import { UserInfoService } from '../services';
import { ActionTypes } from '../constants';

async function fetchAsync(func: any, args: any) {
  const response = await func(args);
  return response;
}

function* userInfo(action: any): Generator {
  try {
    const data:any = yield call(fetchAsync, UserInfoService.getUserInfo, { data: action.payload });
    yield put({ type: ActionTypes.USERINFO_SUCCESS, payload: data.data.data });
  } catch (e: any) {
    yield put({ type: ActionTypes.USERINFO_ERROR, error: e.data });
  }
}

export function* watchUserInfo() {
  yield takeEvery(ActionTypes.USERINFO_REQUEST, userInfo);
}
export default watchUserInfo;
